<template>
  <v-row>
    <v-dialog max-width="750" v-model="component.productDialog" persistent>
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-card-title> Pesquisar Produtos</v-card-title>
            <v-spacer></v-spacer>
            <v-icon class="mr-2 mb-6" @click="() => {
              component.setProductDialog();
            }
              ">mdi-close</v-icon>
          </v-row>
          <v-text-field outlined label="Pesquisar" v-model="searchInput" @keyup.enter="setAutocomplete()" append-icon="mdi-magnify"></v-text-field>
          <v-data-table :headers="headers" :items="results" hide-default-footer no-data-text="Sem registros"
            no-results-text="Nenhum produto encontrado" :footer-props="{
              'items-per-page-options': [5, 10],
              'items-per-page-text': 'Items por página',
            }">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green darken-3" class="ml-3" @click="() => {
                add_product(item);
              }
                ">mdi-plus-circle</v-icon>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-pagination color="success" v-model="current" :length="lastPage" :total-visible="10" :disabled="paginationDelay"></v-pagination>
        </v-container>
      </v-card>
    </v-dialog>
    <amountDialog :component="this" v-if="amountProductDialog"/>
  </v-row>
</template>
  
<script>
import { headers } from "./script";
import { GET_ALL_PRODUCT_SALES, GET_SEARCH_PRODUCT } from "../../../../store/actions/product";
import amountDialog from "../AmountProductDialog/index.vue"
import { mapGetters } from "vuex";
export default {
  components: {
    amountDialog,
  },
  props: { component: Object },
  data: () => ({
    paginationDelay: Boolean(),
    search: String(),
    searchInput: String(),
    headers,
    results: Array(),
    lastPage: Number(),
    current: Number(),
    product: Object(),
    amountProductDialog: Boolean(),
    amount: 1,
  }),
  methods: {
    add_product(item) {
      this.product = item
      this.setAmountProductDialog()
    },
    setAmountProduct() {
      this.product.product_amount = parseFloat(this.amount)
      this.product.product_budget_value = this.product.sale_price * this.product.product_amount
      this.product.value_descount = 0
      this.component.loadProduct(this.product)
      this.component.setProductDialog()
    },
    async getAllProduct(page = 1) {

      await this.$store.dispatch(GET_ALL_PRODUCT_SALES, { page: page, limit: 10 });
    },
    async product_filter(page = 1) {
      if(this.search.length >= 2) {
       let payload = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
          enterprise_id: this.component.getEnterprises.data[0].id
        }
        await this.$store.dispatch(GET_SEARCH_PRODUCT, payload)
      }
    },
    setAutocomplete() {
        this.search = this.searchInput
    },
    setAmountProductDialog() {
      this.amountProductDialog = !this.amountProductDialog
    }
  },
  watch: {
    getProductSale: function (val) {
      this.results = val.data;
      this.current = val.current_page;
      this.lastPage = val.last_page;
      this.paginationDelay = false
    },
    getSeachProduct: function (val) {
      this.results = val.data;
      this.current = val.current_page;
      this.lastPage = val.last_page;
      this.paginationDelay = false
    },
    current: async function (val) {
      this.paginationDelay = true
      if(this.search.length != 0) {
        await this.product_filter(val)
      } else {
        await this.getAllProduct(val);
      }
    },
    search: function () {
      this.product_filter()
    },
    searchInput: function(val) {
      if(val.length == 0) {
        this.search = ""
        this.getAllProduct()
      }
    }
  },
  computed: {
    ...mapGetters(["getProduct","getSeachProduct", "getProductSale"]),
  },
  async created() {
    await this.getAllProduct();
  },
};
</script>
  
<style></style>