const ISSUE_OF_INVOICE_NFCE = 'ISSUE_OF_INVOICE_NFCE'
const GET_SEQUENCE_NFCE = 'GET_SEQUENCE_NFCE'
const CANCEL_NFCE = "CANCEL_NFCE"
const GET_NFCE_WITH_KEY = "GET_NFCE_WITH_KEY"
const REPRINT_NFCE_WITH_KEY = "REPRINT_NFCE_WITH_KEY" 
const REPROCESS_NFCE_WITH_KEY = "REPROCESS_NFCE_WITH_KEY"
const REPROCESS_NFCE_BEFORE_ERROR_DATA = "REPROCESS_NFCE_BEFORE_ERROR_DATA"
const GET_NFCE_WITH_KEY_RESULT = "GET_NFCE_WITH_KEY_RESULT"
const PROCESS_NFCE_CONTIGENCY = "PROCESS_NFCE_CONTIGENCY"
const PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY = "PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY"

module.exports = {
    ISSUE_OF_INVOICE_NFCE,
    GET_SEQUENCE_NFCE,
    CANCEL_NFCE,
    GET_NFCE_WITH_KEY,
    REPRINT_NFCE_WITH_KEY,
    REPROCESS_NFCE_WITH_KEY,
    REPROCESS_NFCE_BEFORE_ERROR_DATA,
    GET_NFCE_WITH_KEY_RESULT,
    PROCESS_NFCE_CONTIGENCY,
    PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY
}