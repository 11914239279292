var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"transition":"fab-transition","persistent":"","max-width":"350"},model:{value:(_vm.component.dialog_bleed),callback:function ($$v) {_vm.$set(_vm.component, "dialog_bleed", $$v)},expression:"component.dialog_bleed"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.words.title)+" ")]),_c('v-divider'),_c('v-container',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Tipo operação de caixa","readonly":""},model:{value:(_vm.cashier_operation_types_description),callback:function ($$v) {_vm.cashier_operation_types_description=$$v},expression:"cashier_operation_types_description"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","tile":"","label":_vm.words.input_label,"placeholder":"0.00","prefix":"R$","rules":[
                () => !!_vm.bleed.value_sangria || 'Campo Obrigatório',
                () =>
                  (!!_vm.bleed.value_sangria &&
                  _vm.bleed.value_sangria > 0) ||
                  'Valor de sangria deve ser maior que 0',
              ]},model:{value:(_vm.bleed.value_sangria),callback:function ($$v) {_vm.$set(_vm.bleed, "value_sangria", $$v)},expression:"bleed.value_sangria"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"error mr-1",attrs:{"width":"150"},on:{"click":() => (_vm.suggest_print ? _vm.submit_bleed(null) : _vm.cancel_dialog())}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cancel")]),_vm._v(" "+_vm._s(_vm.words.cancel))],1),_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.valid,"width":"150"},on:{"click":() =>
                  _vm.current_operation == 'CONFIRM'
                    ? _vm.suggestPrint()
                    : _vm.submit_bleed(1)}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.words.confirm))],1)],1)],1),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.words.btn))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }