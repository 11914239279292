<template>
  <v-row>
    <v-dialog max-width="550" v-model="this.component.clientDialog" persistent>
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-card-title> Pesquisar Clientes </v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              class="mr-2 mb-6"
              @click="
                () => {
                  component.setClientDialog();
                }
              "
              >mdi-close</v-icon
            >
          </v-row>
          <v-switch
            color="primary"
            label="Pessoa Jurídica"
            v-model="isFormalPeople"
          ></v-switch>
          <v-text-field
            outlined
            v-model="search"
            label="Pesquisar"
            append-icon="mdi-magnify"
            @keyup.enter="searchClient()"
          ></v-text-field>
          <v-data-table
            :headers="atual.header"
            :items="atual.getter"
            no-data-text="Sem registros"
            hide-default-footer
            no-results-text="Nenhum cliente encontrado"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, -1],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="green darken-3"
                class="ml-3"
                @click="add_client(item)"
                >mdi-plus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-pagination
            v-model="current"
            :length="lastPage"
            color="success"
            :total-visible="10"
            :disabled="paginationDelay"
          ></v-pagination>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { headers } from "./script";
import { GET_ALL_CLIENT, GET_AUTOCOMPLETE_CLIENT } from "../../../../../store/actions/client";
import { mapGetters } from "vuex";
export default {
  props: { component: Object() },
  data: () => ({
    search: String(),
    lastPage: Number(),
    current: Number(),
    isFormalPeople: Boolean(),
    paginationDelay: Boolean(),
    pagIsLegal: Boolean(),
    atual: {
      header: Array(),
      getter: Array(),
    },
    headers,
    enterprise: Object(),
  }),
  methods: {
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header = this.headers[
        isLegal ? type.isLegal : type.isPhisical
      ];

      this.atual.getter = isLegal
        ? this.getLegalClients
        : this.getPhysicalClients;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },
    add_client(item) {
      this.component.loadClient(item);
      this.component.setClientDialog();
    },
    async GetPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        page: page,
        limit: 10,
        type_people: "PhysicalPeople",
        paginate: true
      });
    },
    async GetLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        page: page,
        limit: 10,
        type_people: "LegalPeople",
        paginate: true
      });
    },
    async searchClient() {
      if(this.search.length >= 2) {
        if(this.isFormalPeople) {
          this.AutocompleteClientLegal(this.search);
        } else {
          this.AutocompleteClientPhysical(this.search);
        }
      }
    },
    async AutocompleteClientPhysical(searchVal) {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "PhysicalPeople",
          page: 1,
          limit: 10,
          paginate: true,
          enterprise_id: this.enterprise.id,
          search: searchVal,
        });
    },
    async AutocompleteClientLegal(searchVal) {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "LegalPeople",
          enterprise_id: this.enterprise.id,
          search: searchVal,
          page: 1,
          limit: 10,
          paginate: true,
        });
    },
  },
  computed: {
    ...mapGetters([
      "getPhysicalClients",
      "getLegalClients",
      "getPaginationClient",
      "getEnterprises",
    ]),
  },
  watch: {
    getPaginationClient: function (val) {
      this.current = val.current_page;
      this.lastPage = val.last_page;
      this.paginationDelay = false
    },



    getPhysicalClients: function () {
      if (this.getPhysicalClients.length === 11) {
        this.GetPhysical();
      }
    },
    getLegalClients: function () {
      if (this.getLegalClients.length === 11) {
        this.GetLegal();
      }
    },
    current: function (val) {
      this.paginationDelay = true
      if (this.pagIsLegal) {
        this.GetLegal(val);
      } else {
        this.GetPhysical(val);
      }
    },
    isFormalPeople: function (val) {
      this.pagIsLegal = val;
      if (val) {
        this.GetLegal();
      } else {
        this.GetPhysical();
      }
      this.isLegal(val);
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetLegal();
        this.GetPhysical();
      }
    }
  },
  async created() {
    await this.GetLegal();
    await this.GetPhysical();
    this.enterprise = this.getEnterprises.data[0]
  },
  mounted() {
    this.isLegal();
  },
};
</script>
