<template>
  <v-card :height="height">
    <v-card-title>
      <v-icon class="mr-2">mdi-basket</v-icon>Produtos
    </v-card-title>
    <v-switch
      class="ml-2"
      color="primary"
      label="Visualizar imagens"
      v-model="view_image"
    ></v-switch>
    <v-text-field
      class="ma-2"
      dense
      :label="labelCode"
      v-model="code_bar"
      outlined
      @keyup.enter="enterCode()"
    >
      <template v-slot:append>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon @click="activeBarcode()" v-on="on" :color="color_barcode">
              mdi-barcode-scan
            </v-icon>
          </template>
          {{ text_barcode }}
        </v-tooltip>
      </template>

      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              @click="active_idProduct()"
              v-on="on"
              :color="color_numeric"
            >
              mdi-numeric
            </v-icon>
          </template>
          {{ text_numeric }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              class="ml-2"
              @click="searchProduct()"
              v-on="on"
              :color="color_numeric"
            >
              mdi-magnify
            </v-icon>
          </template>
          {{ text_search }}
        </v-tooltip>
      </template>
    </v-text-field>
    <v-card flat v-if="!view_image">
      <v-data-table
        dense
        :headers="headers"
        height="44vh"
        :items="productList"
        :no-data-text="data_text"
        hide-default-footer
        :footer-props="{
          'items-per-page-options': [5, 10],
          'items-per-page-text': 'Items por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            color="blue darken-3"
            @click="
              () => {
                activatorView(item);
              }
            "
            >mdi-magnify</v-icon
          >
          <v-icon
            small
            color="green darken-3"
            class="ml-2"
            @click="
              () => {
                activatorAdd(item);
              }
            "
            >mdi-plus-circle</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
    <div v-else style="width: 100%">
      <v-card outlined height="40vh">
        <v-container class="scroll">
          <v-row justify="center">
            <v-col
              v-for="item in productList"
              :key="item.description_item"
              cols="12"
              md="6"
              sm="12"
              lg="4"
            >
              <div class="cardDiv" @click="activatorAdd(item)">
                <img class="imagem" :src="`${url}${item.photo}`" /><img />
              </div>
              <div class="descriptCard">
                <p class="subtitle">
                  {{ item.description_pdv }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <v-divider></v-divider>
    <v-pagination
      color="success"
      v-model="current"
      :length="lastPage"
      :total-visible="7"
      :disabled="paginationDelay"
    ></v-pagination>

    <SearchProduct
      v-if="dialog"
      :component="this"
    />
    <InfoProduct
      v-if="dialogInfo"
      :component="this"
      :itemProduct="element"
      :readonly="readonly"
    />
    <AddCard v-if="dialog_add" :component="this" />
    <AlertError
      v-if="dialog_alert"
      :component="this"
      :title_error="title_error"
      :listErrors="subtitle_error"
    />
    <LoadDate v-if="dialog_date" :title="title" :component="this" />
  </v-card>
</template>

<script>
import { headers } from "./script";
import { computed } from "vue";
import { useDisplay } from "vuetify";
import SearchProduct from "../../Tables/TableSearchProduct";
import LoadDate from "../../AddDialog/Load_Date"
import InfoProduct from "../../InfoProduct";
import AddCard from "../../AddDialog/Add_Cart";
import AlertError from "../../AddDialog/Modal_Error/";
import { ADD_CART, UPDATE_CART } from "../../../../store/actions/cart";
import {
  GET_ALL_PRODUCT_BY_STATUS,
  GET_BY_BARCODE,
  GET_BY_PRODUCT,
} from "../../../../store/actions/product";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import { GET_CALC_TRIBUTE } from "../../../../store/actions/sales";
import { mapGetters } from "vuex";

export default {
  props: { 
    component: Object(),  
  },
  setup() {
    const { name } = useDisplay();
    const height = computed(() => {
      switch (name.value) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
        case "xxl":
          return 1200;
      }

      return undefined;
    });

    return { height };
  },
  components: { InfoProduct, SearchProduct, AddCard, AlertError,LoadDate },
  data: () => ({
    paginationDelay: Boolean(),
    lastPage: Number(0),
    statusOpenCashier: Boolean(),
    title:String(),
    readonly: Boolean(),
    valid: false,
    view_image: false,
    dialog: false,
    dialog_date: false,
    dialogInfo: false,
    dialog_add: false,
    statusBar_code: String(),
    amount: 1,
    productCode: Object(),
    current: Number(),
    total: Number(),
    color_numeric: "gray",
    color_barcode: "gray",
    active_barcode: Boolean(),
    active_numeric: Boolean(),
    text_barcode: "Ativar Modo Manual",
    text_numeric: "Ativar modo cód de Produto",
    labelCode: "Código de Barra",
    data_text: "Sem produtos disponíveis",
    text_search: "Consultar por Cód de barra",
    info_sale: { amount: Number(), value_subtotal_product: Number() },
    update_amount: Object(),
    search: String(),
    code_bar: String(),
    product: Object(),
    element: Object(),
    dialog_alert: Boolean(),
    title_error: String(),
    subtitle_error: String(),
    barcodeLoad: Boolean(),
    url: process.env.VUE_APP_URL_DEFAULT,
    productList: Array(),
    itens_sales: {
      product: Object(),
      description: String(),
      code_item: Number(),
      amount: Number(),
      product_id: Number(),
      value_sale: Number(),
      value_descont_product: Number(),
      value_subtotal_product: Number(),
      value_base_icms_product: Number(), //alterado no back end
      value_accounting_product: Number(), //alterado no back end

      aliquot_icms_product: Number(), //alterado no back end
      value_icms_product: Number(),
      value_free_icms_product: Number(),
      value_icms_outher_product: Number(),
      value_base_icms_st_product: Number(),
      value_icms_st_product: Number(),
      aliquot_ipi_product: Number(),
      value_base_ipi_product: Number(),
      value_ipi_product: Number(),
      value_ipi_free_product: Number(),
      value_ipi_outher_product: Number(),
      aliquota_pis: Number(),
      value_base_pis_product: Number(),
      value_pis_product: Number(),
      value_base_pis_st_product: Number(),
      value_other_pis_st_product: Number(),
      value_other_pis_product: Number(),
      value_free_pis_product: Number(),
      value_pis_st_product: Number(), //adicionado no back end
      aliquota_cofins: Number(),
      value_base_cofins_product: Number(),
      value_cofins_product: Number(), //adicionado no back end
      value_other_cofins_product: Number(),
      value_free_cofins_product: Number(),
      value_base_cofins_st_product: Number(),
      value_cofins_st_product: Number(),
      valueUnityBusinessProduct: Number(),
      valueUnityTaxProduct: Number(),

      cfop_id: Number(),
      cst_ipi: Number(),
      csosn_id: Number(),
      sale_id: Number(),
      cst_pis_id: Number(),
      cst_cofins_id: Number(),
      csosnProduct: String(),
      sourceMerchandise: String(),
    },

    headers,
  }),

  methods: {
    activeDialog() {
      this.dialog_add = !this.dialog_add;
    },
    activeView() {
      this.dialog = !this.dialog;
    },
    activeViewInfo() {
      this.dialogInfo = !this.dialogInfo;
    },
    searchProduct() {
      this.readonly = false;
      this.element = Object();
      this.activeView();
    },
    async searchProductGetBarCode(code) {
      let product = Object();
      await this.$store.dispatch(GET_BY_BARCODE, code).then((response) => {
        let { data } = response.data;
        product = { ...data };
      });

      return product;
    },
    activatorView(item) {
      let auxItem = { ...item };
      // auxItem.csosn_code = auxItem.csosn === null ? "0" : auxItem.csosn.code;
      // auxItem.cst_code = auxItem.cst === null ? "0" : auxItem.cst.code;
      this.element = { ...auxItem };
      this.readonly = true;
      this.activeViewInfo();
    },
    activeBarcode() {
      if (this.color_barcode === "gray") {
        this.color_barcode = "success";
        this.text_barcode = "Desativar Modo Manual";
        this.active_barcode = true;
        if (this.color_numeric === "success") {
          this.active_idProduct();
        }
      } else {
        this.color_barcode = "gray";
        this.active_barcode = false;
        this.text_barcode = "Ativar Modo Manual";
      }
    },
    active_idProduct() {
      if (this.color_numeric === "gray") {
        this.labelCode = "Código do Produto";
        this.color_numeric = "success";
        this.text_numeric = "Desativar Modo Cód de Produto";
        this.active_numeric = true;
        // if (this.color_barcode === "success") {
        // }
      } else {
        // this.getProducts();
        this.activeBarcode();
        this.labelCode = "Código de Barra";
        this.color_numeric = "gray";
        this.active_numeric = false;
        this.text_numeric = "Ativar Modo Cód de Produto";
      }
    },
    enterCode() {
      if (this.active_numeric) {
        this.search_IdProduct();
      } else {
        this.addMultiProduct();
      }
    },
    async addProductSearchDescription(id) {
      this.title = "Buscando Produto"
      this.dialog_date = !this.dialog_date;
      let payload = {
        id: id, 
        enterprise_id :this.getEnterprises.data[0].id
      }
      await this.$store.dispatch(GET_BY_PRODUCT, payload).then((response) => {
        let productReturnResponse = response.data[0];
        this.product = productReturnResponse;

        if (this.product.sale_price === null) {
          alert("Produto sem preço de venda");
          this.dialog_date = false;
        } else {
          this.AddDialogProductCar();
          this.code_bar = String();
          this.dialog_date = false;
        }
      }).catch((error)=> {
        this.dialog_date = false
      })
      this.dialog_date = false;
    },
    async search_IdProduct() {
      let id = this.code_bar;
      this.title = "Buscando Produto"
      this.dialog_date = !this.dialog_date;
      let payload = {
        id: id, 
        enterprise_id :this.getEnterprises.data[0].id
      }
      await this.$store.dispatch(GET_BY_PRODUCT, payload).then((response) => {
        let productReturnResponse = response.data[0];
        if (productReturnResponse !== undefined) {
          this.product = productReturnResponse;
          this.dialog_date = false
          if (this.product.sale_price === null) {
            alert("Produto sem preço de venda");
          } else if(this.product.status != "Ativo") {
            alert("Produto não está ativo");
            this.dialog_date = false;
          } else {
            this.AddDialogProductCar();
            this.code_bar = String();
            this.dialog_date = false
          }
        } else {
          this.dialog_date = false
          this.statusBar_code = "red";
          this.title_error = "Erro ao buscar o código interno!";
          this.subtitle_error = [{ text: "Verifique o código interno!" }];
          this.dialog_alert = false;
        }
      }).catch((error)=> {
        this.dialog_date = false
      })
      this.dialog_date = false
    },

    //checa se ter produto no carrinho caso tenha devolve o item para recalculo
    CheckAddItensCart(idProduct) {
      let valueproduct = false;
      let itensCart = this.getCart;
      valueproduct = itensCart.find((itens) => itens.product_id === idProduct);
      if (valueproduct) {
        return valueproduct;
      }
      return false;
    },
    async calculeTributeProductCart() {
      let productTribute = await this.$store.dispatch(GET_CALC_TRIBUTE, {
        product: this.itens_sales,
        enterprise: this.getEnterprises.data[0],
      });
      if (productTribute !== null) {
        this.itens_sales.value_base_icms_product =
          productTribute.value_base_icms_product;
        this.itens_sales.value_accounting_product =
          productTribute.value_accounting_product;
        this.itens_sales.value_icms_product = productTribute.value_icms_product;
        this.itens_sales.aliquot_ipi_product =
          productTribute.aliquot_ipi_product;
        this.itens_sales.value_cofins_product =
          productTribute.value_cofins_product;
        this.itens_sales.aliquot_icms_product =
          productTribute.aliquot_icms_product;
        this.itens_sales.value_base_icms_st_product =
          productTribute.value_base_icms_st_product;
        this.itens_sales.value_icms_st_product =
          productTribute.value_icms_st_product;
        this.itens_sales.value_ipi_product = productTribute.value_ipi_product;
        this.itens_sales.value_free_cofins_product =
          productTribute.value_free_cofins_product;
        this.itens_sales.value_other_pis_product =
          productTribute.value_other_pis_product;
        this.itens_sales.value_pis_st_product =
          productTribute.value_pis_st_product;
        this.itens_sales.value_free_icms_product =
          productTribute.value_free_icms_product;
        this.itens_sales.value_icms_outher_product =
          productTribute.value_icms_outher_product;
        this.itens_sales.value_base_ipi_product =
          productTribute.value_base_ipi_product;
        this.itens_sales.aliquota_pis = productTribute.aliquota_pis;
        this.itens_sales.value_base_pis_product =
          productTribute.value_base_pis_product;
        this.itens_sales.value_pis_product = productTribute.value_pis_product;
        this.itens_sales.value_base_cofins_product =
          productTribute.value_base_cofins_product;
        this.itens_sales.value_base_pis_st_product =
          productTribute.value_base_pis_st_product;
        this.itens_sales.value_base_cofins_st_product =
          productTribute.value_base_cofins_st_product;
        this.itens_sales.value_cofins_st_product =
          productTribute.value_cofins_st_product;
        this.itens_sales.value_other_pis_st_product =
          productTribute.value_other_pis_st_product;
        this.itens_sales.value_other_cofins_product =
          productTribute.value_other_cofins_product;
        this.itens_sales.value_free_pis_product =
          productTribute.value_free_pis_product;
        this.itens_sales.aliquota_cofins = productTribute.aliquota_cofins;
        this.itens_sales.value_ipi_free_product =
          productTribute.value_ipi_free_product;
        this.itens_sales.value_ipi_outher_product =
          productTribute.value_ipi_outher_product;
        this.itens_sales.cfop_id = productTribute.cfop_id;

        this.itens_sales.cst_ipi = productTribute.cst_ipi;
        this.itens_sales.csosn_ipi = productTribute.csosn_ipi;
        this.itens_sales.cst_pis_id = productTribute.cst_pis_id;
        this.itens_sales.cst_cofins_id = productTribute.cst_cofins_id;
      }
    },
    async AddDialogProductCar(amount = 0) {
      if (this.product.value_sale != 0) {
        let productCar = this.CheckAddItensCart(this.product.id);
        //caso tenha esse produto adicionado no carrinho
        if (productCar != false) {
          this.itens_sales.product = this.product;
          this.itens_sales.product_id = this.product.id;
          this.itens_sales.cst_id = this.product.cst_id;
          this.itens_sales.aliquot_icms_product = this.product.aliquot.aliquot;
          this.itens_sales.cfop_id = this.product.cfop_id;
          this.itens_sales.cst_ipi = this.product.cst_ipi;
          this.itens_sales.csosn_id = this.product.csosn_id;
          this.itens_sales.cst_pis_id = this.product.cst_pis_id;
          this.itens_sales.cst_cofins_id = this.product.cst_cofins_id;
          this.itens_sales.amount =
            parseFloat(this.amount) + parseFloat(productCar.amount);
          this.itens_sales.value_sale = this.product.sale_price;
          this.itens_sales.value_descont_product = 0;
          this.itens_sales.value_subtotal_product =
            parseFloat(this.itens_sales.value_sale) *
            parseInt(this.itens_sales.amount);

          //campos nfce
          this.itens_sales.description = this.product.description_item;
          this.itens_sales.nomenclatureValueCustomsProduct = "";
          this.itens_sales.eanProduct = this.product.bar_code;
          this.itens_sales.ncmProduct = this.product.ncm.code;
          this.itens_sales.codeCstProdut = this.product.cst.code;
          this.itens_sales.cfopProduct = this.product.cfop.code;
          this.itens_sales.unitBusinessProduct = this.product.unit.sigla;
          this.itens_sales.unityTaxProduct = this.product.unit.sigla;
          this.itens_sales.sourceMerchandise = this.product.source_product;
          this.itens_sales.valueOfApproximateTax = 0;
          this.itens_sales.methodDeterminingBaseIcms =
            this.product.calculation_base_mode;
          this.itens_sales.relevantScaleIndicatorProduct = "";
          this.itens_sales.codeManufacturerProduct = "";
          this.itens_sales.recipientProduct = "";
          this.itens_sales.extIpiProduct = "";
          this.itens_sales.valueCostOfFreight = 0;
          this.itens_sales.valueTotalGrossProduct = 0;
          this.itens_sales.valueTotalGrossProduct = "";
          this.itens_sales.valueBaseRelatedCombatPoverty = 0;
          this.itens_sales.percentageRelatedCombatPoverty = 0;
          this.itens_sales.valueRelatedCombatPoverty = 0;
          this.itens_sales.indicatesWhetherProduct = 1;
          this.itens_sales.codeSituationTaxableCofins =
            this.product.cst_confis.code;
          this.itens_sales.valueUnityBusinessProduct = this.product.sale_price;
          this.itens_sales.valueUnityTaxProduct = this.product.sale_price;
          this.itens_sales.codeSituationTaxablePis = this.product.cst_pis.code;
          this.itens_sales.csosnProduct = this.product.csons.code;
          this.itens_sales.aliquot_ipi_product = 0;
          this.itens_sales.aliquota_cofins = 0;
          this.itens_sales.aliquota_pis = 0;
          this.itens_sales.cst_ipi = 0;
          this.itens_sales.value_accounting_product = 0;

          this.itens_sales.value_base_icms_product = 0;
          this.itens_sales.value_base_icms_st_product = 0;
          this.itens_sales.value_free_icms_product = 0;
          this.itens_sales.value_icms_outher_product = 0;
          this.itens_sales.value_icms_product = 0;
          this.itens_sales.value_icms_st_product = 0;
          this.itens_sales.value_other_pis_st_product = 0;




          await this.calculeTributeProductCart();
          this.dialog_add = false;
          this.$store.dispatch(UPDATE_CART, this.itens_sales);

          this.itens_sales = {};
        } else {
          this.itens_sales.product = this.product;
          this.itens_sales.aliquot_icms_product = this.product.aliquot.aliquot;
          this.itens_sales.product_id = this.product.id;
          this.itens_sales.cst_id = this.product.cst_id;
          this.itens_sales.description = this.product.description_item;
          this.itens_sales.amount = amount > 0 ? amount : this.amount;
          this.itens_sales.value_sale = this.product.sale_price;
          this.itens_sales.cfop_id = this.product.cfop_id;
          this.itens_sales.cst_ipi = this.product.cst_ipi;
          this.itens_sales.csosn_id = this.product.csosn_id;
          this.itens_sales.cst_pis_id = this.product.cst_pis_id;
          this.itens_sales.cst_cofins_id = this.product.cst_cofins_id;
          this.itens_sales.value_descont_product = 0;
          this.itens_sales.value_subtotal_product =
            parseFloat(this.itens_sales.value_sale) *
            parseInt(this.itens_sales.amount);

          //campos nfce
          this.itens_sales.eanProduct = this.product.bar_code;
          this.itens_sales.ncmProduct = this.product.ncm.code;
          this.itens_sales.nomenclatureValueCustomsProduct = "";
          this.itens_sales.codeCstProdut = this.product.cst.code;
          this.itens_sales.relevantScaleIndicatorProduct = "";
          this.itens_sales.codeManufacturerProduct = "";
          this.itens_sales.recipientProduct = "";
          this.itens_sales.extIpiProduct = "";

          this.itens_sales.cfopProduct = this.product.cfop.code;
          this.itens_sales.unitBusinessProduct = this.product.unit.sigla;
          this.itens_sales.valueUnityBusinessProduct = this.product.sale_price;
          this.itens_sales.valueCostOfFreight = 0;
          this.itens_sales.unityTaxProduct = this.product.unit.sigla;
          this.itens_sales.indicatesWhetherProduct = 1;
          this.itens_sales.valueTotalGrossProduct = 0;
          this.itens_sales.valueTotalGrossProduct = "";

          this.itens_sales.sourceMerchandise = this.product.source_product;
          this.itens_sales.valueOfApproximateTax = 0;
          this.itens_sales.methodDeterminingBaseIcms =
            this.product.calculation_base_mode;
          this.itens_sales.valueBaseRelatedCombatPoverty = 0;
          this.itens_sales.percentageRelatedCombatPoverty = 0;
          this.itens_sales.valueRelatedCombatPoverty = 0;
          this.itens_sales.codeSituationTaxableCofins =
            this.product.cst_confis.code;
          this.itens_sales.codeSituationTaxablePis = this.product.cst_pis.code;
          this.itens_sales.csosnProduct = this.product.csons.code;
          this.itens_sales.sourceMerchandise = this.product.source_product;

          this.itens_sales.aliquot_ipi_product = 0;
          this.itens_sales.aliquota_cofins = 0;
          this.itens_sales.aliquota_pis = 0;
          this.itens_sales.cst_ipi = 0;
          this.itens_sales.value_accounting_product = 0;

          this.itens_sales.value_base_icms_product = 0;
          this.itens_sales.value_base_icms_st_product = 0;
          this.itens_sales.value_free_icms_product = 0;
          this.itens_sales.value_icms_outher_product = 0;
          this.itens_sales.value_icms_product = 0;
          this.itens_sales.value_icms_st_product = 0;
          this.itens_sales.value_other_pis_st_product = 0;

          this.dialog_add = false;
          await this.calculeTributeProductCart();
          this.$store.dispatch(ADD_CART, this.itens_sales);

          this.itens_sales = {};
        }
      } else {
        alert("Produto sem preço de venda");
      }
    },
    //ativa e copia item selecinado para variavel produto
    activatorAdd(item) {
      this.product = { ...item };
      if (this.product.sale_price === null) {
        alert("Produto sem preço de venda");
      } else {
        this.activeDialog();
      }
    },

    async addMultiProduct() {
      let code = Object();
      let string = this.code_bar;
      let index = string.indexOf("*");
      this.amount = 1;
      this.title = "Buscando Produto"
      this.dialog_date = !this.dialog_date;
      if (index !== -1) {
        this.amount = string.slice(0, index);
        let barcode = string.slice(index);
        code.barcode = barcode.replace("*", "");
      } else {
        code.barcode = string;
      }
      let enterprise_id = this.getEnterprises.data[0].id
      await this.$store
        .dispatch(GET_BY_BARCODE, { code, enterprise_id })
        .then((response) => {
          let data = response.data.data[0];
          if (data !== undefined) {
            this.statusBar_code = "primary";
            this.product = data[0];
            if (this.product.sale_price === null) {
              alert("Produto sem preço de venda");
              this.dialog_date = false;
            } else {
              this.AddDialogProductCar();
              this.code_bar = String();
              this.dialog_date = false;
            }
          } else {
            this.dialog_date = false;
            this.statusBar_code = "red";
            this.title_error = "Erro ao buscar o código de barras";
            this.subtitle_error = [{ text: "Verifique o código de barras!" }];
            
            this.dialog_alert = true;
          }
          this.dialog_date = false;
        }).catch((error)=> {
        this.dialog_date = false
      })
    },
    getAllProducts(page = 1) {
      this.$store.dispatch(GET_ALL_PRODUCT_BY_STATUS, { page: page, limit: 10 });
    },

    async getEnterprise(page = 1) {
      await this.$store.dispatch(GET_ALL_ENTERPRISE, { page: page, limit: 10 });
    },
  },
  computed: {
    ...mapGetters([
      "getProductsBarcode",
      "getBarcode",
      "getProductByStatus",
      "getCart",
      "getEnterprises",
      "getProductCode",
    ]),
  },
  watch: {
    current: async function (val) {
      this.paginationDelay = true
      await this.getAllProducts(val);
    },
    getProductByStatus: function (val) {
      this.productList = val.data;
      this.lastPage = val.last_page;
      this.current = val.current_page;
      this.paginationDelay = false
    },
    productCode: async function (val) {
      this.productCode = val;
    },

    // getCart: async function(){
    //   await this.calculeTributeProductCart();
    // }

    // getProduct: function (val) {
    //   if (val.data === undefined) {
    //     this.productList = Object.values(val);
    //     this.total = 1;
    //     this.current = 1;
    //   } else {
    //     this.productList = val.data;
    //     this.total = val.lastPage;
    //     this.current = val.currentPage;
    //   }
    // },
    // current: function (val) {
    //   this.getProducts && this.getProducts(val);
    // },
  },
  async created() {
    await this.getAllProducts();
    await this.getEnterprises();
  },
};
</script>
<style scoped>
.scroll {
  max-height: 100%;
  overflow: auto;
}

.subtitle {
  color: #286a7d;
  font-weight: bold;
  font-size: 12px;
  padding: 3%;
}

.imagem {
  height: 100%;
  width: 100%;
}

.cardDiv {
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.19), 2px 0px 3px rgba(0, 0, 0, 0.23);
  height: 150px;
  width: 150px;
  overflow: hidden;
}

.descriptCard {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.19), 2px 2px 3px rgba(0, 0, 0, 0.23);
  border-top: rgb(167, 167, 167) 1px solid;
  width: 150px;
  height: 30px;
}
</style>
