<template>
  <div>
    <v-data-table :headers="headers" :items="results" :search="search" no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado" hide-default-footer @click:row="handleClick">
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-4" class="mr-2" v-bind="attrs" v-on="on" @click="() => {
      selectItemDesativeContigency(item);
    }
      ">mdi-cancel</v-icon>
          </template>
          <span>Finalizar Contigencia</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider class="mt-6"></v-divider>
    <v-pagination v-model="current" :length="total" color="success" :total-visible="10"></v-pagination>
    <AlertDesativationContingency :component="this" />
  </div>
</template>
<script>
import {
  DESATIVE_CONTIGENCY,
  GET_ALL_CONTIGENCY,
  GET_SEQUENCE_INITIAL_CONTIGENCY,
} from "../../../store/actions/contigency";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import AlertDesativationContingency from "../CancelContigency/index.vue"
import { mapGetters } from 'vuex';
export default {
  components: {
    AlertDesativationContingency
  },
  props: {
    component: Object(),
    dialogCancelContingency: Boolean()
  },
  data: () => ({
    current: Number(),
    results: Array(),
    total: Number(),
    current: Number(),
    contigency_finished_sequence: String(),
    dialogCancelContingency: Object(),
    itemPayloadContingecy: Object(),
    enterprises: Object(),
    headers: [
      { text: "status", value: "status" },
      { text: "Tipo de contingência", value: "typeContigency" },
      { text: "Data de inicio", value: "date_and_hours_contigency" },
      { text: "Sequência inicial", value: "contigency_initial_sequence" },
      { text: "Justificativa Contingência", value: "justification_on_contigency" },
      { text: "Data da finalização", value: "date_and_hours_finished_contigency" },
      { text: "Sequência Final", value: "contigency_finished_sequence" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    async GetAllEnterprise(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    async getNumberSequenceAtiveContingency() {
      let payload = {
        enterprise_id: this.enterprises[0].id,
        typeNoteModel: "NFC-e"
      }
      await this.$store.dispatch(GET_SEQUENCE_INITIAL_CONTIGENCY, payload).then((result) => {
        let notemodelSequence = { ...result.response.data[0] }
        this.contigency_finished_sequence = String(notemodelSequence.sequence)

      })
    },

    async GetAllContigency(page = 1) {
      await this.$store.dispatch(GET_ALL_CONTIGENCY, {
        page: page,
        limit: 10,
        paginate: true,
        cnpj_emitter: this.enterprises[0].cnpj
      });
    },
    selectItemDesativeContigency(item) {
      this.itemPayloadContingecy = item
      this.dialogCancelContingency = true
    },

    async desativationContigency(item) {
      await this.getNumberSequenceAtiveContingency()
      if (this.itemPayloadContingecy.status === "active") {
        if (this.itemPayloadContingecy.date_and_hours_finished_contigency === null) {
          let payload = {
            status: 'finished',
            enterprise_id: this.itemPayloadContingecy.enterprise_id,
            date_and_hours_finished_contigency: this.mounterFinishDateAndHoursContigency(),
            contigency_finished_sequence: this.contigency_finished_sequence
          }
          this.$store.dispatch(DESATIVE_CONTIGENCY, payload)
          this.dialogCancelContingency = false
          this.GetAllContigency()

          let processNfceMultipleInContigency = {
            enterprise_id: this.itemPayloadContingecy.enterprise_id,
            sequence_initial_process: itemPayloadContingecy.contigency_sequence_initial,
            sequence_finish_process: this.contigency_finished_sequence
          }
          // this.$store.dispatch(PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY, processNfceMultipleInContigency).then((response) => {
            
          // })

        }
      } else {
        alert("Contingencia já finalizada")
      }



    },
    mounterFinishDateAndHoursContigency() {
      let dateSale = new Date();
      let year = dateSale.getFullYear();
      let day = dateSale.getDate();
      let month = dateSale.getMonth() + 1;
      let hours = dateSale.getHours();
      let minutes = dateSale.getMinutes();
      let seconds = dateSale.getSeconds();

      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }


      if (hours < 10) {
        hours = `0${hours}`;
      } else {
        hours = hours.toString();
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      } else {
        minutes = minutes.toString();
      }


      if (seconds < 10) {
        seconds = `0${seconds}`;
      } else {
        seconds = seconds.toString();
      }


      let dateTimeFormat = (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)



      return dateTimeFormat

    },

  },
  computed: {
    ...mapGetters(["getContigency", "getEnterprises"]),
  },
  async created() {
    await this.GetAllEnterprise();
    await this.GetAllContigency();
  },
  watch: {
    getContigency: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    getEnterprises: function (val) {
      if (val.data) {
        this.enterprises = Object.values(val.data);
      } else {
        this.enterprises = Object.values(val);
      }
    },
    current: function (val) {
      this.GetAllContigency(val);
    },
  },
}
</script>