<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="300"
      v-model="component.amountProductDialog"
    >
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row justify="center">
            <v-card-title>Qual a quantidade?</v-card-title>
          </v-row>
          <v-form v-model="valid">
            <v-text-field
              outlined
              label="Quantidade"
              v-model="component.amount"
              :rules="[
                  () => !!component.amount || 'Campo Obrigatorio',
                  () =>
                    (!!component.amount && component.amount > 0) ||
                    'A quantidade não pode ser igual a 0',
                ]"
            >
            </v-text-field>
          </v-form>
          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                class="red darken-4 white--text"
                @click="
                  () => {
                    component.setAmountProductDialog();
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!valid"
                depressed
                class="success"
                @click="
                  () => {
                    component.setAmountProduct();
                  }
                "
              >
                Adicionar
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: true
  }),
  methods: {
  },
};
</script>

<style>
</style>