<template>
    <v-layout row justify-center>
      <v-dialog
        v-model="component.errorDialog"
        :retain-focus="false"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline"
            >Erro de validação</v-card-title
          >
          <v-card-text>{{info}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success ml-4"
              @click="
                () => {
                  component.setErrorMessageDialog();
                }
              "
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </template>
    
    <script>
  export default {
    props: {
      info: String(),
      component: Object,
    },
  };
  </script>
    
    <style>
  </style>