<template>
  <v-row>
    <v-dialog
      persistent
      transition="scale-transition"
      max-width="300"
      v-model="component.dialog_descont"
    >
    <v-form
        v-model="form"
    >
      <v-card class="mx-auto" outlined>
  
        <v-container>
          <v-row justify="center">
            <v-card-title>Valor Desconto?</v-card-title>
          </v-row>
          <v-select
            label="Tipo Desconto"
            :items="type_descont"
            v-model="component.type_descont"
            required
            item-text="name"
            item-value="value"
            outlined
          ></v-select>

          <v-text-field v-if="component.type_descont =='PERCENTUAL'" outlined 
          v-model="component.valueDescont" label="Percentual"
          :rules="[
                   () => !!component.valueDescont || 'Campo Obrigatorio',
                   () => (component.valueDescont >= 1 && component.valueDescont <=100)|| 'Valor desconto inválido'
          ]"
          type="number" >
          </v-text-field>

          <v-text-field v-else outlined 
          v-model="component.valueDescont" label="Valor Dinheiro"
          :rules="[
                   () => !!component.valueDescont || 'Campo Obrigatorio',
                   () => (component.valueDescont < component.productToUpdate.product_budget_value) || 'Valor desconto inválido',
                   () => (component.valueDescont > 0) || 'Valor desconto inválido',
                  ]"
          
          type="number" >
          </v-text-field>

          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                rounded
                class="red darken-4 white--text"
                @click="
                  () => {
                    component.setDescountDialog();
                  }
                "
              >
                Cancelar
              </v-btn>
              <v-btn
               :disabled="!form"
                rounded
                depressed
                class="success"
                @click="
                  () => {
                    component.setDescountProduct();
                  }
                "
              >
                Confirmar
              </v-btn>
         
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
    </v-dialog>

  
  </v-row>
  
</template>

<script>
export default {
  props: {
    component: Object,
  },
  data: () => ({
    quantity: "",
    form: false,
    
    type_descont: [
    { name: "PERCENTUAL", value: "PERCENTUAL" },
    { name: "DINHEIRO", value: "DINHEIRO" },
      
    ],
  }),
};
</script>
