<template>
  <v-row justify="center">
  <v-dialog
    persistent
    fullscreen
    v-model="component.budgetDialog"
    hide-overlay
    transition="dialog-bottom-transition"
  >
  <v-card>
    <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="
            () => {
              component.setBudgetDialog();
            }
          ">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Gerar Orçamento </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
        <v-container style="height: 30px;"></v-container>
    <v-container>
    <v-col>
      <v-form ref="form" v-model="formValid">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Status"
              outlined
              v-model="status"
              readonly
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Data"
              outlined
              v-model="date"
              type="date"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Cliente"
              append-icon="mdi-magnify"
              outlined
              v-model="client.name"
              :rules="rules"
              @blur="getByIdClient(client.name)"
              @click:append="() => {
                setClientDialog();
              }
                "
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Vendedor"
              append-icon="mdi-magnify"
              outlined
              v-model="seller.name"
              :rules="rules"
              @blur="getByIdSeller(seller.name)"
              @click:append="() => {
                setSellerDialog();
              }
                "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Informações"
              outlined
              v-model="information"
            />
          </v-col>
        </v-row>
      </v-form>
        <v-divider />
        <v-row>
          <v-col class="d-flex justify-center" style="margin-bottom: 10px; margin-top: 30px;">
            <v-text-field
              label="Produto"
              outlined
              v-model="product_id_code"
              @keyup.enter="searcProductByCodeOrId()"
              append-icon="mdi-magnify"
              @click:append="() => {
                setProductDialog()
              }
                "
            />
          </v-col>
          <v-col class="d-flex justify-center" style="margin-bottom: 10px; margin-top: 30px;">
            <v-text-field
              label="Quantidade"
              outlined
              v-model="amountToAdd"
              type="number"
              :rules="[
                  () => !!amountToAdd || 'Campo Obrigatorio',
                  () =>
                    (!!amountToAdd && amountToAdd > 0) ||
                    'A quantidade não pode ser igual a 0',
                ]"
            />
          </v-col>
          <v-col class="d-flex justify-center" style="margin-bottom: 10px; margin-top: 30px;">
            <v-text-field
              label="Valor"
              outlined
              v-model="productToAdd.sale_price"
              prefix="R$"
              readonly
            />
          </v-col>
          <v-col class="d-flex justify-center" style="margin-bottom: 10px; margin-top: 30px;">
            <v-text-field
              label="Sub Total"
              outlined
              v-model="value"
              prefix="R$"
              readonly
            />
          </v-col>
          <v-col style="margin-top: 38px;">
            <v-btn
              class="ml-1 success"
              fab
              small
              @click="setProductToLoad()"
              :disabled="value <= 0"
            >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <Table :component="this" />
        <v-divider />
        <v-container style="height: 30px;" ></v-container>
        <v-row>
          <v-col style="font-size: 20px; font-weight: bold;" >
            Valor dos Produtos:
          </v-col>
          <v-col style="font-size: 20px; font-weight: bold;" >
            Descontos:
          </v-col>
          <v-col style="font-size: 20px; font-weight: bold;" >
            Valor do Orçamento:
          </v-col>
        </v-row>
        <v-row>
          <v-col style="font-size: 20px; font-weight: bold;" >
            R$ {{valor_products_total}}
          </v-col>
          <v-col style="font-size: 20px; font-weight: bold;" >
            R$ {{value_descont_total}}
          </v-col>
          <v-col style="font-size: 20px; font-weight: bold;" >
            R$ {{value_total_note}}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end" style="margin-top: 10px;">
            <v-btn class="mr-1 error" @click="component.setBudgetDialog()">Cancelar</v-btn>
            <v-btn :disabled="!enableBtn" class="ml-1 success" @click="setPaymentDialog()"
              >Gerar Orçamento</v-btn
            >
          </v-col>
        </v-row>
    </v-col>
  </v-container>
        </v-row>
    </v-card>
  </v-dialog>
  <ClientDialog :component="this" v-if="clientDialog" />
  <SellerDialog :component="this" v-if="sellerDialog" />
  <ProductTable :component="this" v-if="productDialog" />
  <amountDialog :component="this" v-if="amountProductDialog" />
  <descountDialog :component="this" v-if="dialog_descont" />
  <permissionDialog :component="this" v-if="descountPermissionDialog" />
  <paymentFormDialog :component="this" v-if="paymentDialog" />
</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Table from "./table/index.vue";
import ClientDialog from "./SearchPeopleTables/TableSearchClient/index.vue"
import SellerDialog from "./SearchPeopleTables/TableSearchSeller/index.vue"
import ProductTable from "./SearchProductTable/index.vue"
import amountDialog from "./AmountProductDialog/index.vue"
import descountDialog from "./Descont_Product/index.vue"
import permissionDialog from "./Password_Dialog/index.vue"
import paymentFormDialog from "./Finish_Budget/index.vue"

import { GET_BY_ID } from "../../../store/actions/client";
import { GET_BY_ID_SELLER } from "../../../store/actions/seller";

import { ADD_TABLE_PRODUCTS } from "../../../store/actions/budget"
import { RESET_TABLE_PRODUCTS } from "../../../store/actions/budget"
import { UPDATE_AMOUNT_PRODUCTS } from "../../../store/actions/budget"
import { GIVE_DESCOUNT_PRODUCTS } from "../../../store/actions/budget"
import { LOAD_PRODUCTS_TABLE_TO_UPDATE } from "../../../store/actions/budget"
import { REMOVE_PRODUCT } from "../../../store/actions/budget"
import { CREATE_BUDGET } from "../../../store/actions/budget"
import { UPDATE_BUDGET } from "../../../store/actions/budget"
import { GET_BY_BARCODE } from "../../../store/actions/product"
import { GET_BY_PRODUCT } from "../../../store/actions/product"
import { DISCOUNT_PERMISSION, SET_DISCOUNT_PERMISSION } from "../../../store/actions/cart"
import { GET_SEQUENCE_NFCE } from "../../../store/actions/nfce";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../store/actions/exchange_control"

import GeneratePdf from "../PDF/generatePDF"

export default {
  components: {
    Table,
    ClientDialog,
    SellerDialog,
    ProductTable,
    amountDialog,
    descountDialog,
    permissionDialog,
    paymentFormDialog,
  },
  props: {
      component: Object,
    },

    data: () => ({
      rules: [(v) => !!v || "Campo obrigatório!"],
      formValid: Boolean(),
      enableBtn: Boolean(),
      //form
      id: Number(),
      status: 'Em andamento',
      budgetCode: Number(),
      date: Date(),
      formOfPayments: Array(),
      information: String(),
      seller: {
        name: String(),
        seller_id: Number()
      },
      client: {
        name: String(),
        client_id: Number(),
      },

      value_descont_total: Number(),
      valor_products_total: Number(),
      value_total_note: Number(),
      
      //setDialogs
      sellerDialog: Boolean(),
      clientDialog: Boolean(),
      productDialog: Boolean(),
      amountProductDialog: Boolean(),
      dialog_descont: Boolean(),
      descountPermissionDialog: Boolean(),
      paymentDialog: Boolean(),
      //aux
      productToUpdate: Object(),
      amount: Number(),
      product_id_code: String(),
      amountToAdd: 1,
      value: Number(),
      productToAdd: Object(),
      dontCalulateValue: Boolean(),
      productsList: Array(),
      valueDescont: Number(),
      type_descont: String(),
      totalDescount: Number(),
      email: String(),
      password: String(),
      isLoading: Boolean(),
      updateBudgetData: Object(),
      taxOperationId: Number(),
      productsToUpdate: Array(),
      paymentFormsToUpdate: Array(),
  }),

    methods: {
      GeneratePdf,
      async submit() {
       if(this.component.budgetToUpdate.id) {
        this.updateBudget()
       } else {
        this.createBudget()
       }
      },

      async createBudget() {
        await this.$store.dispatch(GET_SEQUENCE_NFCE, {
            enterprise_id: this.getEnterprises.data[0].id,
            typeNoteModel: "ORC",
          })
          .then((response) => {
            let payload = {
              status: this.status,
              number_budget: (response.data[0].sequence + 1).toString(),
              date_budget: this.date,
              value_descont_total: this.value_descont_total,
              valor_products_total: this.valor_products_total,
              value_total_note: this.value_total_note,
              client_id: this.client.client_id,
              tax_operation_types_id: this.taxOperationId,
              enterprise_id: this.getEnterprises.data[0].id,
              seller_id: this.seller.seller_id,
              note_models_id: response.data[0].id,
              budget_items: Array(),
              budgetPaymentMethod: this.formOfPayments,
              anotations: this.information,

              // Variáveis para o Rodrigo setar corretamente
              value_account_total: this.value_total_note,
              value_icms_total: this.value_total_note,
              value_icms_free_total: 0,
            }
            let budget_item = Object()
            for(let i=0; i<this.productsList.length; i++) {
              budget_item = {}
              budget_item.code_item = (i + 1).toString()
              budget_item.description_item = this.productsList[i].description_item
              budget_item.amount = this.productsList[i].product_amount
              budget_item.value_budget_product = this.productsList[i].product_budget_value
              budget_item.discount_amount = this.productsList[i].value_descount
              budget_item.value_subtotal_product = this.productsList[i].product_budget_value
              budget_item.value_accounting_product = this.productsList[i].sale_price // Fazer validação para sale_price
              budget_item.cfop_id = this.productsList[i].cfop_id
              budget_item.cst_id = this.productsList[i].cst_id
              budget_item.csosn_id = this.productsList[i].csosn_id
              budget_item.cst_ipi_id = this.productsList[i].cst_ipi_id
              budget_item.cst_cofins_id = this.productsList[i].cst_cofins_id
              budget_item.product_id = this.productsList[i].id
    
              // icms a ser calculado:
              budget_item.value_base_icms_product = 0
              budget_item.value_icms_product = 0
              budget_item.value_icms_free_product = 0
              budget_item.value_icms_outher_product = 0
              
              payload.budget_items.push(budget_item)
            }
    
            this.$store.dispatch(CREATE_BUDGET, payload).then(()=>{
              this.GeneratePdf(this.getEnterprises.data[0], payload, this.client.name, this.seller.name)
              this.component.setBudgetDialog()
            })
          })
      },

      async updateBudget() {
          let payload = {
            id: this.id,
            status: this.status,
            date_budget: this.date,
            note_models_id: this.updateBudgetData.note_models_id,
            value_descont_total: this.value_descont_total,
            valor_products_total: this.valor_products_total,
            value_total_note: this.value_total_note,
            client_id: this.client.client_id,
            tax_operation_types_id: this.taxOperationId,
            enterprise_id: this.getEnterprises.data[0].id,
            seller_id: this.seller.seller_id,
            budget_items: Array(),
            budgetPaymentMethod: Array(),
            anotations: this.information,
            
            // Variáveis para o Rodrigo setar corretamente
            value_account_total: this.value_total_note,
            value_icms_total: this.value_total_note,
            value_icms_free_total: 0,
          }

          for(let i=0; i<this.productsList.length; i++) {
            if(this.productsList[i].type_of_operation == "UPDATE") {
              this.productsToUpdate.push(this.productsList[i])
            }
          }
          let budget_item = Object()
          
          for(let i=0; i<this.productsToUpdate.length; i++) {
            budget_item = {}
            budget_item.id = this.productsToUpdate[i].budget_id
            budget_item.code_item = (i + 1).toString()
            budget_item.description_item = this.productsToUpdate[i].description_item
            budget_item.amount = this.productsToUpdate[i].product_amount
            budget_item.value_budget_product = this.productsToUpdate[i].product_budget_value
            budget_item.discount_amount = this.productsToUpdate[i].value_descount
            budget_item.value_subtotal_product = this.productsToUpdate[i].product_budget_value
            budget_item.value_accounting_product = this.productsToUpdate[i].sale_price
            budget_item.cfop_id = this.productsToUpdate[i].cfop_id
            budget_item.cst_id = this.productsToUpdate[i].cst_id
            budget_item.csosn_id = this.productsToUpdate[i].csosn_id
            budget_item.cst_ipi_id = this.productsToUpdate[i].cst_ipi_id
            budget_item.cst_cofins_id = this.productsToUpdate[i].cst_cofins_id
            budget_item.product_id = this.productsToUpdate[i].id
            budget_item.type_of_operation = this.productsToUpdate[i].type_of_operation
            
            // icms a ser calculado:
            budget_item.value_base_icms_product = 0
            budget_item.value_icms_product = 0
            budget_item.value_icms_free_product = 0
            budget_item.value_icms_outher_product = 0
            
            payload.budget_items.push(budget_item)
          }

          for(let i=0; i<this.formOfPayments.length; i++) {
            if(this.formOfPayments[i].type_of_operation == "UPDATE") {
              this.paymentFormsToUpdate.push(this.formOfPayments[i])
            }
          }
          payload.budgetPaymentMethod = this.paymentFormsToUpdate

          

          
          this.$store.dispatch(UPDATE_BUDGET, payload).then(()=>{
            this.component.setBudgetDialog()
          })
        },
        
        getByIdClient(client_id) {
        if(client_id.length > 0) {
          try {
            this.$store.dispatch(GET_BY_ID,
            {
              id: client_id,
              enterprise_id: this.getEnterprises.data[0].id
            });
          } catch (e) {
            console.error(e);
          }
        }
      },

      getByIdSeller(seller_id) {
        if(seller_id.length > 0) {
          try {
            this.$store.dispatch(GET_BY_ID_SELLER, {
              id: seller_id,
            });
          } catch (e) {
          }
        }
      },

      searcProductByCodeOrId() {
        if(this.product_id_code.length > 0) {
          if(this.product_id_code.length == 13) {
            const payload = {
              enterprise_id: this.getEnterprises.data[0].id,
              barcode: this.product_id_code
            }
            this.$store.dispatch(GET_BY_BARCODE, payload) // Verificar funcionamento do barcode
          } else {
            this.$store.dispatch(GET_BY_PRODUCT, {
              id : this.product_id_code, enterprise_id: this.getEnterprises.data[0].id
            } )
          }
        }
      },

      loadInfoProduct(product) {
        this.productToAdd = product[0]
        this.product_id_code = this.productToAdd.description_item
        this.value = this.productToAdd.sale_price * this.amountToAdd
      },

      setProductToLoad() {
        this.dontCalulateValue = true
        this.productToAdd.product_amount = parseFloat(this.amountToAdd)
        this.productToAdd.product_budget_value = this.value
        this.productToAdd.value_descount = 0
        this.loadProduct(this.productToAdd)
        this.value = 0
        this.amountToAdd = 1
        this.productToAdd = {}
        this.product_id_code = ""

      },

      loadClient(client) {
        if(client.type_people == "PhysicalPeople") {
          this.client.name = client.physicalpeople.name
        } else {
          this.client.name = client.legalpeople.fantasyName
        }
        this.client.client_id = client.id
      },

      loadSeller(seller) {
        this.seller.name = seller.physicalpeople.name
        this.seller.seller_id = seller.id
      },

      loadProduct(product) {
        this.$store.dispatch(ADD_TABLE_PRODUCTS, product)
      },

      setItemToUpdate(product) {
        this.productToUpdate = product
        this.amount = product.product_amount
        this.setAmountProductDialog()
      },

      setAmountProduct() {
        this.productToUpdate.product_amount = parseFloat(this.amount)
        this.productToUpdate.product_budget_value = (this.productToUpdate.sale_price * this.productToUpdate.product_amount) - this.productToUpdate.value_descount
        this.$store.dispatch(UPDATE_AMOUNT_PRODUCTS, this.productToUpdate)
        this.productToUpdate = {}
        this.setAmountProductDialog()
      },

      updateDescount(product) {
        this.productToUpdate = product
        this.setDescountDialog()
      },

      setDescountProduct() {
        let productPrice = this.productToUpdate.sale_price * this.productToUpdate.product_amount
        let maxDescountValue = (productPrice / 100) * this["auth/getUser"].user.cashier_operation.limit_descont_percentual
        let maxDescountPercentualValue = Number()
        if(this.productToUpdate.value_descount != 0) {
          maxDescountPercentualValue = (this.productToUpdate.value_descount * 100) / productPrice 
        }
        switch (this.type_descont) {
          case "PERCENTUAL":
            if(this.valueDescont <= (this["auth/getUser"].user.cashier_operation.limit_descont_percentual - maxDescountPercentualValue)) {
                this.giveDiscount()
              } else {
              this.totalDescount = parseFloat(this.valueDescont) + maxDescountPercentualValue
              this.setPermissionDiscountDialog()
            }

            break;
            case "DINHEIRO":
              if((parseFloat(this.valueDescont) + parseFloat(this.productToUpdate.value_descount)) <= maxDescountValue) {
                this.giveDiscount()
              } else {
                this.totalDescount = parseFloat(this.valueDescont) + parseFloat(this.productToUpdate.value_descount)
                this.totalDescount = (this.totalDescount * 100) / productPrice
                this.totalDescount = this.totalDescount.toFixed(2)
                this.setPermissionDiscountDialog()
              }
            break;
        }
      },

      giveDiscount() {
        if(this.type_descont == "PERCENTUAL") {
            var desconto = (parseFloat(this.productToUpdate.product_budget_value) * parseFloat(this.valueDescont)) / 100;
            this.productToUpdate.product_budget_value = parseFloat(this.productToUpdate.product_budget_value).toFixed(2) - desconto.toFixed(2);
            this.productToUpdate.value_descount += desconto;

        } else if(this.type_descont == "DINHEIRO") {
            let convertionPercentualValue = (this.valueDescont / this.productToUpdate.product_budget_value)
            var desconto = (parseFloat(this.productToUpdate.product_budget_value) * parseFloat(convertionPercentualValue))
            this.productToUpdate.product_budget_value = parseFloat(this.productToUpdate.product_budget_value).toFixed(2) - desconto.toFixed(2);
            this.productToUpdate.value_descount += desconto;
        }

        this.$store.dispatch(GIVE_DESCOUNT_PRODUCTS, this.productToUpdate)
        this.setDescountDialog()
        this.valueDescont = Number();
        this.productToUpdate = {}
      },

      permissionDiscountLogin() {
        this.isLoading = true
        const payload = {
          email: this.email,
          password: this.password,
          discount: this.totalDescount,
          enterprise_id: this.getEnterprises.data[0].id,
        }
        this.$store.dispatch(DISCOUNT_PERMISSION, payload)
      },

      setItemToRemove(product) {
        this.$store.dispatch(REMOVE_PRODUCT, product)
      },
      
      setClientDialog() {
        this.clientDialog = !this.clientDialog
      },

      setSellerDialog() {
        this.sellerDialog = !this.sellerDialog
      },

      setProductDialog() {
        this.productDialog = !this.productDialog
      },

      setAmountProductDialog() {
        this.amountProductDialog = !this.amountProductDialog
      },

      setDescountDialog() {
        this.dialog_descont = !this.dialog_descont
      },

      setPermissionDiscountDialog() {
        this.descountPermissionDialog = !this.descountPermissionDialog
      },
      
      setPaymentDialog() {
        this.paymentDialog = !this.paymentDialog
      },

      setTodayDate() {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');

        this.date = `${yyyy}-${mm}-${dd}`;
      },

      GeatAllTaxOperationType() {
        const payload = {
          paginate: false,
          page: null,
          limit: null
        }
        this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, payload).then((response) => {
          for(let i=0; i<response.length; i++) {
            if(response[i].code == 5102) {
              this.taxOperationId = response[i].id
            }
          }
        });
      }
    },
  computed: {
    ...mapGetters([
      "getResult",
      "getResultSeller",
      "getEnterprises",
      "getProductCode",
      "auth/getUser",
      "getDiscountPermission",
      "getSequenceNfce",
      "getProductsUpdateControl",
    ]),
  },
  async created() {
    if(this.component.budgetToUpdate.id) {
      this.updateBudgetData = this.component.budgetToUpdate
    } else {
      this.setTodayDate()
    }
    this.GeatAllTaxOperationType()
  },
  watch: {
    getResult: function (val) {
      this.loadClient(val);
    },

    getResultSeller: function (val) {
      this.loadSeller(val);
    },

    getProductCode: function(val) {
      this.loadInfoProduct(val)
    },
    
    amountToAdd: function(val) {
      if(!this.dontCalulateValue) {
        this.value = this.productToAdd.sale_price * val
      } else {
        this.dontCalulateValue = false
      }
    },

    productsList: function(products) {
      let totalDescount = Number()
      let totalProducts = Number()
      let totalBudget = Number()
      if(products.length == 0) {
        this.value_descont_total = 0
        this.valor_products_total = 0
        this.value_total_note = 0
      } else {
        for(let i=0; i<products.length; i++) {
          totalDescount += products[i].value_descount
          totalProducts += products[i].sale_price * products[i].product_amount
          totalBudget += products[i].product_budget_value
        }
        this.value_descont_total = totalDescount.toFixed(2)
        this.valor_products_total = totalProducts.toFixed(2)
        this.value_total_note = totalBudget.toFixed(2)
      }
    },

    getProductsUpdateControl: function(productsToUpdate) {
      this.productsToUpdate = productsToUpdate
    },

    getDiscountPermission: function(val) {
      if(val == false) {
        this.setPermissionDiscountDialog()
      } else if(val == true) {
        this.setPermissionDiscountDialog()
        this.giveDiscount()
      }
      this.$store.dispatch( SET_DISCOUNT_PERMISSION )
    },

    formValid: function(val) {
      this.enableBtn = val && this.value_total_note
    },

    value_total_note: function(val) {
      this.enableBtn = val && this.formValid
    },

    updateBudgetData: function(budget) {
      this.id = budget.id
      this.date = budget.date_budget
      this.client.name = budget.client.name
      this.client.client_id = budget.client.id
      this.seller.name = budget.seller.people.physicalpeople.name
      this.seller.seller_id = budget.seller.id
      this.information = budget.anotations

      this.$store.dispatch(LOAD_PRODUCTS_TABLE_TO_UPDATE, budget.budget_item)
    },

  },

  beforeDestroy() {
    this.$store.dispatch(RESET_TABLE_PRODUCTS)
  }
};
</script>

<style>
</style>
