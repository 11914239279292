<template>
  <v-dialog v-model="component.dialog_load" max-width="270" height="300">
    <v-card height="200">
      <!-- <v-card-title class="text-center">{{ title }}</v-card-title> -->
      <v-card-title v-if="!activeTime" class="text-center">{{ title }}</v-card-title>
      <v-card-title v-else class="text-center">{{title + time  }}</v-card-title>
    
      <div class="body">
        <div class="spinner"></div>
      </div>
      <v-card-subtitle class="mt-8 text-center">{{ status }}</v-card-subtitle>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    component: Object,
    status: String,
    title: String,
    activeTime: Boolean,
    time: Number,
  },
  data: () => ({
    auxLoad: Number(),
    change: Function(),

  }),
};
</script>

<style>
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
}
.spinner {
  margin-top: 15px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>