let identifyNFe = {
    keyAccess: "",
    status: "",
    codeUfEmmiter: "",
    descriptionNatureOperation: "",
    codModelSupervisior: "",
    identifyOperationDestination: "",
    seriesDocumentSupervisior: "",
    numberNfe: "",
    issuanceDateNfe: "",
    issuanceDepartureDate: "",
    operationTypeNfe: "",
    codeCountyOperation: "",
    paperFormart: "",
    typeIssueNfe: "",
    typeKeyAcessVerifier: "",
    environmentalIdentification: "",
    issuePurpose: "",
    endConsumerOperation: "",
    buyerPresenceEstablishment: "",
    indicatorIntermediaryEstablishment: "",
    noteIssuingProcess: "",
    versionOfIssueProcess: "",
    dateAndTimeEntryContingency: "",
    contingencyEntryJustification: "",
    referencedAccessKey: "",
    qrCodeNf: "",
    urlChaveNf: "",
    sinatureNf: "",
    valueBaseTotalCalculeIcms: "",
    valueTotalCalculeIcms: "",
    valueTotalProduct: "",
    valueTotalNF: "",
    valueApproximateTax: "",
    paymentMethodIndicator: "",
    meansOfPayment: "",
    valuePaymentAmount: "",
    typeOfIntegration: "",
    cnpjInstitutionPayment: "",
    valueThing: "",
}
let contigencyIsActive = ""

async function execute(sales) {
    try {    
     contigencyIsActive = localStorage.getItem("contigencyIsActive")
     if (contigencyIsActive == null){
       return  mounterIdentifyNormal(sales)
     }else if (Boolean(contigencyIsActive) == true) {
        let contigencyPayload =  JSON.parse(localStorage.getItem("contigency_data"))
       return  mounterIdentifyContigency(sales, contigencyPayload.date_and_hours_contigency,contigencyPayload.justification_on_contigency)
     }
    } catch (error) {
        return error
    }
}


// 

async function mounterIdentifyNormal(sales) {
    identifyNFe.keyAccess = sales.access_key
    identifyNFe.status = sales.status
    identifyNFe.codeUfEmmiter = String(sales.enterprise.address.my_city.state.id) // sales.client.physicalpeople === null ? String(sales.client.address[0].city.state.id) : String(sales.client.address[0].city.state.id)
    identifyNFe.descriptionNatureOperation = "VENDA CONSUMIDOR",
        identifyNFe.codModelSupervisior = 65,
        identifyNFe.identifyOperationDestination = 1,
        identifyNFe.seriesDocumentSupervisior = sales.series,
        identifyNFe.numberNfe = String(sales.number_sale)
    let DateFormart = await formaterDateSales(sales.data_sale)
    identifyNFe.issuanceDateNfe = DateFormart,
        identifyNFe.issuanceDepartureDate = DateFormart,
        identifyNFe.operationTypeNfe = 1,
        identifyNFe.codeCountyOperation = String(sales.enterprise.address.city_id) //"3205200",
    identifyNFe.paperFormart = "4",
        identifyNFe.typeIssueNfe = "1",
        identifyNFe.typeKeyAcessVerifier = String(1),
        identifyNFe.environmentalIdentification = String(sales.enterprise.note_issuing_environment),
        identifyNFe.issuePurpose = 1,
        identifyNFe.endConsumerOperation = 1,
        identifyNFe.buyerPresenceEstablishment = 1,
        identifyNFe.indicatorIntermediaryEstablishment = "0",
        identifyNFe.noteIssuingProcess = "0",
        identifyNFe.versionOfIssueProcess = "4.00",
        identifyNFe.dateAndTimeEntryContingency = "",
        identifyNFe.contingencyEntryJustification = "",
        identifyNFe.referencedAccessKey = "",
        identifyNFe.qrCodeNf = "",
        identifyNFe.urlChaveNf = "",
        identifyNFe.sinatureNf = "",
        identifyNFe.valueBaseTotalCalculeIcms = parseFloat(sales.base_cal_icms_total)
    identifyNFe.valueTotalCalculeIcms = parseFloat(sales.value_icms_total)
    identifyNFe.valueTotalProduct = parseFloat(sales.value_total_products)
    identifyNFe.valueTotalNF = parseFloat(sales.value_total_note)
    identifyNFe.valueApproximateTax = sales.valueApproximateTax
    identifyNFe.paymentMethodIndicator = sales.paymentMethodIndicator,
        identifyNFe.meansOfPayment = sales.form_of_payments[0].code,
        identifyNFe.valuePaymentAmount = parseFloat(sales.value_total_note)
    identifyNFe.typeOfIntegration = 2,
        identifyNFe.cnpjInstitutionPayment = "",
        identifyNFe.valueThing = 0
    identifyNFe.infoAdditionalTax = "informacoes para o fisco",
        identifyNFe.infoSupplementaryTax = "    Vendedor : " + sales.seller.physicalpeople.name
    return identifyNFe
}


async function mounterIdentifyContigency(sales, dateAndTimeEntryContingency, contingencyEntryJustification) {
    identifyNFe.keyAccess = sales.access_key
    identifyNFe.status = sales.status
    identifyNFe.codeUfEmmiter = String(sales.enterprise.address.my_city.state.id) // sales.client.physicalpeople === null ? String(sales.client.address[0].city.state.id) : String(sales.client.address[0].city.state.id)
    identifyNFe.descriptionNatureOperation = "VENDA CONSUMIDOR",
        identifyNFe.codModelSupervisior = 65,
        identifyNFe.identifyOperationDestination = 1,
        identifyNFe.seriesDocumentSupervisior = sales.series,
        identifyNFe.numberNfe = String(sales.number_sale)
    let DateFormart = await formaterDateSales(sales.data_sale)
    identifyNFe.issuanceDateNfe = DateFormart,
        identifyNFe.issuanceDepartureDate = DateFormart,
        identifyNFe.operationTypeNfe = 1,
        identifyNFe.codeCountyOperation = String(sales.enterprise.address.city_id) //"3205200",
    identifyNFe.paperFormart = "4",
        identifyNFe.typeIssueNfe = "9",
        identifyNFe.typeKeyAcessVerifier = String(1),
        identifyNFe.environmentalIdentification = String(sales.enterprise.note_issuing_environment),
        identifyNFe.issuePurpose = 1,
        identifyNFe.endConsumerOperation = 1,
        identifyNFe.buyerPresenceEstablishment = 1,
        identifyNFe.indicatorIntermediaryEstablishment = "0",
        identifyNFe.noteIssuingProcess = "0",
        identifyNFe.versionOfIssueProcess = "4.00",
        identifyNFe.dateAndTimeEntryContingency = dateAndTimeEntryContingency,
        identifyNFe.contingencyEntryJustification = contingencyEntryJustification,
        identifyNFe.referencedAccessKey = "",
        identifyNFe.qrCodeNf = "",
        identifyNFe.urlChaveNf = "",
        identifyNFe.sinatureNf = "",
        identifyNFe.valueBaseTotalCalculeIcms = parseFloat(sales.base_cal_icms_total)
    identifyNFe.valueTotalCalculeIcms = parseFloat(sales.value_icms_total)
    identifyNFe.valueTotalProduct = parseFloat(sales.value_total_products)
    identifyNFe.valueTotalNF = parseFloat(sales.value_total_note)
    identifyNFe.valueApproximateTax = sales.valueApproximateTax
    identifyNFe.paymentMethodIndicator = sales.paymentMethodIndicator,
        identifyNFe.meansOfPayment = sales.form_of_payments[0].code,
        identifyNFe.valuePaymentAmount = parseFloat(sales.value_total_note)
    identifyNFe.typeOfIntegration = 2,
        identifyNFe.cnpjInstitutionPayment = "",
        identifyNFe.valueThing = 0
    identifyNFe.infoAdditionalTax = "informacoes para o fisco",
        identifyNFe.infoSupplementaryTax = "    Vendedor : " + sales.seller.physicalpeople.name
    return identifyNFe
}



async function formaterDateSales(dateSale) {
    let year = dateSale.getFullYear();
    let day = dateSale.getDate();
    let month = dateSale.getMonth() + 1;
    let hours = dateSale.getHours();
    let minutes = dateSale.getMinutes();
    let seconds = dateSale.getSeconds();

    if (day < 10) {
        day = `0${day}`;
    } else {
        day = day.toString();
    }
    if (month < 10) {
        month = `0${month}`;
    } else {
        month = month.toString();
    }


    if (hours < 10) {
        hours = `0${hours}`;
    } else {
        hours = hours.toString();
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    } else {
        minutes = minutes.toString();
    }


    if (seconds < 10) {
        seconds = `0${seconds}`;
    } else {
        seconds = seconds.toString();
    }


    let dateTimeFormat = (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)



    return dateTimeFormat
}




module.exports = {
    execute
}

