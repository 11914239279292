<template>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field disabled outlined dense label="Natureza da operação" :value="nfe.descriptionNatureOperation" />
        </v-col>
        <v-col>
          <v-text-field disabled outlined dense label="Data da emissão nfe" :value="nfe.issuanceDateNfe" />
        </v-col>
  
  
      </v-row>
      <v-row>
        <v-col>
          <v-text-field disabled outlined dense label="Chave NFe gerada" :value="nfe.key" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field disabled outlined dense label="Numero da nfe" :value="nfe.numberNfe"></v-text-field>
        </v-col>
  
        <v-col>
          <v-text-field disabled outlined dense label="Serie" :value="nfe.seriesDocumentSupervisior"></v-text-field>
        </v-col>
  
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    components: {},
    props: {
      nfe: Object(),
    },
    data() {
      return {};
    },
  };
  </script>
  
  <style></style>