let IssueNfceApplication = require("../application/Service/IssueNfce/IssueNfceApplication");
let ReprocessNfceApplication = require("../application/Service/IssueNfce/ReprocessNfceApplication")
let ReprocessBeforeErrorDataApplication = require("../application/Service/IssueNfce/ReprocessBeforeErrorDataApplication")
const CONTIGENCY_IS_ACTIVE = true ;
const SALES_NORMAL_PROCESS = null

const {
  ISSUE_OF_INVOICE_NFCE,
  CANCEL_NFCE,
  GET_NFCE_WITH_KEY,
  REPRINT_NFCE_WITH_KEY,
  REPROCESS_NFCE_WITH_KEY,
  REPROCESS_NFCE_BEFORE_ERROR_DATA,
  GET_NFCE_WITH_KEY_RESULT,
  PROCESS_NFCE_CONTIGENCY,
  PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY
} = require("./actions/nfce");
import { SPLICE_ERRO } from "./actions/alert_api";
import { INFO_ERROR } from "./actions/infoError";
// import axiosautomacao from "../apigestao"
import axios from "axios";
import axiosfiscal from "../apifiscal";


const moduleNfce = {
  state: () => ({
    nfce: Object(),
    nfceCancel: Object(),
    infoNfce: Object(),
    reprintnfce: Object(),
    reprocessnfce: Object(),
    reprocessnfceDataError: Object(),
    sendNfceContigency:Object(),
    processMultipleNfceContingecy: Object()
  }),

  mutations: {
    ISSUE_OF_INVOICE_NFCE: (state, nfce) => {
      state.nfce = nfce;
    },
    CANCEL_NFCE: (state, payload) => {
      state.nfceCancel = payload;
    },
    GET_NFCE_WITH_KEY: (state, payload) => {
      state.infoNfce = payload
    },
    GET_NFCE_WITH_KEY_RESULT: (state, payload) => {
      state.infoNfce = payload
    },
    REPRINT_NFCE_WITH_KEY: (state, payload) => {
      state.reprintnfce = payload
    },
    REPROCESS_NFCE_WITH_KEY: (state, payload) => {
      state.reprocessnfce = payload
    },
    REPROCESS_NFCE_BEFORE_ERROR_DATA: (state, payload) => {
      state.reprocessnfceDataError = payload
    }, 
    PROCESS_NFCE_CONTIGENCY : (state, payload)=> {
      state.sendNfceContigency = payload
    },
    PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY: (state, payload)=> {
      state.processMultipleNfceContingecy = payload
    }
  },
  actions: {
    async ISSUE_OF_INVOICE_NFCE({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      let nfce = await IssueNfceApplication.issueNfce(payload);
      let contigencyIsActive = localStorage.getItem("contigencyIsActive")
      if (Boolean(contigencyIsActive) == CONTIGENCY_IS_ACTIVE) {
        return new Promise((resolve, reject) => {
          axiosfiscal.post("/noteissuenfce/nfcecontigency", {
            destinationNFe: nfce.destinationNFe,
            emitterNFe: nfce.emitterNFe,
            identifyNFe: nfce.identifyNFe,
            productsNFe: nfce.productsNFe,
            transportInfo: nfce.transportInfo,
          }, {
            headers: {
              'Authorization': 'Bearer ' + token,
  
            }
          })
            .then((response) => {
              resolve({ response });
              commit(ISSUE_OF_INVOICE_NFCE, response.data);
              if (response.data.nfce.status == "Rejeitada") {
                commit(INFO_ERROR, {
                  dialog: true,
                  title: "Erro ao emitir NFce - NFce Rejeitada",
                  menssage: "Verificar em Notas emitidas "
                })
  
              }
            })
            .catch((error) => {
              reject({ error })
              commit(INFO_ERROR, {
                dialog: true,
                title: error.response.data[0],
                menssage: error.response.data.menssagem
              })
            });
        });

      }else if(contigencyIsActive == SALES_NORMAL_PROCESS) {

        return new Promise((resolve, reject) => {
          axiosfiscal.post("/noteissuenfce/nfce", {
            destinationNFe: nfce.destinationNFe,
            emitterNFe: nfce.emitterNFe,
            identifyNFe: nfce.identifyNFe,
            productsNFe: nfce.productsNFe,
            transportInfo: nfce.transportInfo,
          }, {
            headers: {
              'Authorization': 'Bearer ' + token,
  
            }
          })
            .then((response) => {
              resolve({ response });
              commit(ISSUE_OF_INVOICE_NFCE, response.data);
              if (response.data.nfce.status == "Rejeitada") {
                commit(INFO_ERROR, {
                  dialog: true,
                  title: "Erro ao emitir NFce - NFce Rejeitada",
                  menssage: "Verificar em Notas emitidas "
                })
  
              }
            })
            .catch((error) => {
              reject({ error })
              commit(INFO_ERROR, {
                dialog: true,
                title: error.response.data[0],
                menssage: error.response.data.menssagem
              })
            });
        });
        
      }
      
    },
    async PROCESS_NFCE_CONTIGENCY ({commit}, payload ){
      let token = localStorage.getItem("token_fiscal");
      let contigencyIsActiveProcess = localStorage.getItem("contigencyIsActive")
      if (contigencyIsActiveProcess == SALES_NORMAL_PROCESS) {
        return new Promise ((resolve)=>{
          axiosfiscal.post("/noteissuenfce/sendNfceContigency", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          }).then((response)=>{
            resolve({ response });
            commit(PROCESS_NFCE_CONTIGENCY, response.data);
            if (response.data.nfce.status == "Rejeitada") {
              commit(INFO_ERROR, {
                dialog: true,
                title: "Erro ao emitir NFce - NFce Rejeitada",
                menssage: "Verificar em Notas emitidas / Informações Nfce "
              })
  
            }
          }).catch((error) => {
            commit(INFO_ERROR, {
              dialog: true,
              title: error.response.data[0],
              menssage: error.response.data.menssagem
            })
          });
        })
      }else {
        commit(INFO_ERROR, {
          dialog: true,
          title: "Não  e possivel enviar NFce",
          menssage: "Ainda esta ativado o processamento NFce em contingência"
        })
      }
      

    } ,
    async REPROCESS_NFCE_BEFORE_ERROR_DATA({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      let nfce = await ReprocessBeforeErrorDataApplication.execute(payload);
      return new Promise((resolve) => {
        axiosfiscal.post("/noteissuenfce/nfce", {
          destinationNFe: nfce.destinationNFe,
          emitterNFe: nfce.emitterNFe,
          identifyNFe: nfce.identifyNFe,
          productsNFe: nfce.productsNFe,
          transportInfo: nfce.transportInfo,
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then((response) => {
            resolve({ response });
            commit(REPROCESS_NFCE_BEFORE_ERROR_DATA, response.data);
            if (response.data.nfce.status == "Rejeitada") {
              commit(INFO_ERROR, {
                dialog: true,
                title: "Erro ao emitir NFce - NFce Rejeitada",
                menssage: "Verificar em Notas emitidas / Informações Nfce "
              })

            }
          })
          .catch((error) => {
            commit(INFO_ERROR, {
              dialog: true,
              title: error.response.data[0],
              menssage: error.response.data.menssagem
            })
          });
      });

    },
    REPRINT_NFCE_WITH_KEY({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      return new Promise((resolve) => {
        axiosfiscal.post("/noteissuenfce/nfcereprintdanfe", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          resolve({ response })
          commit(REPRINT_NFCE_WITH_KEY, response.data)
        })

      })

    },
    GET_NFCE_WITH_KEY({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      axiosfiscal.post("/noteissuenfce/getNfceConsultKey", payload, {
        headers: {
          'Authorization': 'Bearer ' + token,

        }
      }).then((response) => {
        commit(GET_NFCE_WITH_KEY, response.data.nfes[0]);
      })

    },
    GET_NFCE_WITH_KEY_RESULT({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      return new Promise((resolve) => {
        axiosfiscal.post("/noteissuenfce/getNfceConsultKey", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          resolve({ response })
          commit(GET_NFCE_WITH_KEY_RESULT, response.data)
        })

      })

    },
    CANCEL_NFCE({ commit }, payload) {
      return new Promise((resolve) => {
        let token = localStorage.getItem("token_fiscal");
        axiosfiscal.post("/noteissuenfce/cancelnfce",
          payload
          , {
            headers: {
              'Authorization': 'Bearer ' + token,

            }
          })
          .then((response) => {
            resolve({ response });
            commit(CANCEL_NFCE, response.data);
          })
          .catch((r) => {
            console.log(r);
            let erros = r.response.data.errors;
            for (let erro in erros) {
              erros[erro].forEach((err) =>
                commit(SPLICE_ERRO, {
                  alert: err,
                  type: "E",
                })
              );
            }
          });
      });
    },
    GET_SEQUENCE_NFCE({ commit }, payload) {
      commit;
      return new Promise((resolve, reject) => {
        axios.post("noteModel/getSequenceTypeNfe", {
          enterprise_id: payload.enterprise_id,
          typeNoteModel: payload.typeNoteModel,
        })
          .then(({ data }) => {
            resolve({ data });
          }).catch((error) => {
            reject({ error })
            commit(INFO_ERROR, {
              dialog: true,
              title: "Erro ao obter a sequência de NFCe",
              menssage: "Sem comunicação com retaguarda"
            })
          })
      });
    },
    async PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      return new Promise ((resolve)=>{
        axiosfiscal.post("/noteissuenfce/sendNfceContigency", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }).then((response)=>{
          resolve({ response });
          commit(PROCESS_MULTIPLE_NFCES_IN_CONTINGENCY, response.data);
          if (response.data.nfce.status == "Rejeitada") {
            commit(INFO_ERROR, {
              dialog: true,
              title: "Erro ao emitir NFce - NFce Rejeitada",
              menssage: "Verificar em Notas emitidas / Informações Nfce "
            })

          }
        }).catch((error) => {
          commit(INFO_ERROR, {
            dialog: true,
            title: error.response.data[0],
            menssage: error.response.data.menssagem
          })
        });
      })
    
    

    },
    async REPROCESS_NFCE_WITH_KEY({ commit }, payload) {
      commit;
      let nfceReprocess = await ReprocessNfceApplication.execute(payload)
      return new Promise((resolve) => {
        let token = localStorage.getItem("token_fiscal");
        axiosfiscal.post("/noteissuenfce/reprocesssingNfce", {
          cnpjEmitter: nfceReprocess.emitterNFe.cnpjEmitter,
          keyNfe: nfceReprocess.identifyNFe.keyAccess,
          destinationNFe: nfceReprocess.destinationNFe,
          emitterNFe: nfceReprocess.emitterNFe,
          identifyNFe: nfceReprocess.identifyNFe,
          productsNFe: nfceReprocess.productsNFe,
          transportInfo: nfceReprocess.transportInfo,
        }, {
          headers: {
            'Authorization': 'Bearer ' + token,

          }
        }).then((response) => {
          resolve({ response });
          commit(REPROCESS_NFCE_WITH_KEY, response.data);
          if (response.data.nfce.status == "Rejeitada") {
            commit(INFO_ERROR, {
              dialog: true,
              title: "Erro ao emitir NFce",
              menssage: "NFce Rejeitada - Verificar em Notas emitidas / Informações Nfce "
            })

          }
        }).catch((error) => {
          commit(INFO_ERROR, {
            dialog: true,
            title: error.response.data[0],
            menssage: error.response.data.menssagem
          })
        });
      })
    }


  },
  getters: {
    getNfce: (state) => ({
      ...state.nfce,
    }),
    getSequenceNfce: (state) => ({
      ...state.sequence_nfce,
    }),
    getStatusNFce: (state) => ({
      ...state.infoNfce,
    }),
    rePrintNfce: (state) => ({
      ...state.reprintnfce,
    }),
    reProccessNfce: (state) => ({
      ...state.reprocessnfce,
    }),
    sendProcessContingecyNfce: (state) => ({
      ...state.sendNfceContigency,
    })

  },
};

export default moduleNfce;





// commit(SPLICE_ERRO, {
//   alert: "Erro ao Emitir NFce - NFce Rejeitada - Verificar em Notas emitidas",
//   type: "E",
// })



