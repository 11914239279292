/* eslint-disable no-unused-vars */
import axios from "axios";
import axiosfiscal from "../apifiscal";
import { SPLICE_SUCESSO, SPLICE_ERRO } from "./actions/alert_api";

const {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  DESTROY_ENTERPRISE,
  ITEM_TO_UPDATE_ENTERPRISE,
  ENTERPRISE_SELECTED,
  CHECK_CERTIFICATE,
} = require("./actions/enterprise");

const moduleEnterprise = {
  state: () => ({
    enterprises: Object(),
    itemToUpdateEnterprise: Object(),
    enterpriseSelected: Object(),
  }),

  mutations: {
    ITEM_TO_UPDATE_ENTERPRISE: function(state, payload){      

      state.itemToUpdateEnterprise = payload

    },
    ENTERPRISE_SELECTED: function(state, payload){
      state.enterpriseSelected = payload
    },
    GET_ALL_ENTERPRISE: (state, enterprises) => {
      state.enterprises = enterprises[0];
    },

    ADD_ENTERPRISE: (state, newEnterprises) => {
      state.enterprises.data.push(newEnterprises.data);
    },

    UPDATE_ENTERPRISE(state, upateEnterprise) {
      let result = state.enterprises.data.find(
        (r) => r.id === upateEnterprise.data.id
      );
      for (let key in upateEnterprise.data) {
        result[key] = upateEnterprise.data[key];
      }
    },

    DESTROY_ENTERPRISE: (state, id) => {
      let auxState = [...state.enterprises.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.enterprises.data = [...auxState];
    },
  },
  actions: {

    ITEM_TO_UPDATE_ENTERPRISE: function(context, payload){      

      context.commit(ITEM_TO_UPDATE_ENTERPRISE, payload)

    },
    ENTERPRISE_SELECTED: function(context, payload){
      context.commit(ENTERPRISE_SELECTED, payload)
      
    },
    async GET_ALL_ENTERPRISE({ commit }, data) {
      let payload = { ...data }      
      await axios.post("enterprise/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate}).then((response) => {
        commit(GET_ALL_ENTERPRISE, response.data[0]);
      });
    },

    ADD_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/create", payload)
        .then((response) => {
  
          commit(ADD_ENTERPRISE, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data.errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },

    UPDATE_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/update", payload)
        .then((response) => {
          commit(UPDATE_ENTERPRISE, response.data);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        })
        .catch((r) => {
          commit(SPLICE_ERRO, {
            alert: r,
            type: "E",
          });
        });
    },

    DESTROY_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/delete", {
          id: payload.id,
          company_group_id: payload.company_group_id,
        })
        .then((response) => {
          commit(DESTROY_ENTERPRISE, payload.id);
          commit(SPLICE_SUCESSO, {
            alert: response.data.msg,
            type: "S",
          });
        });
    },

    async CHECK_CERTIFICATE({ commit }, payload) {
      let token = localStorage.getItem("token_fiscal");
      try {
        const response = await axiosfiscal.post("enterprise/checkcertificate", payload, {
          headers: {
            'Authorization': 'Bearer ' + token,
  
          }
        })
        return response.data.message.days_left
    } catch (error) {

    }
    }
  },

  getters: {
    getItemToUpdateEnterprise: (state) => state.itemToUpdateEnterprise,
    getSelectedEnterprise: (state) => state.enterpriseSelected,
    getEnterprises: (state) => ({
      ...state.enterprises,
    }),
  },
};

export default moduleEnterprise;
