var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-dialog',{attrs:{"max-width":"750","persistent":""},model:{value:(_vm.component.productDialog),callback:function ($$v) {_vm.$set(_vm.component, "productDialog", $$v)},expression:"component.productDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-container',[_c('v-row',[_c('v-card-title',[_vm._v(" Pesquisar Produtos")]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2 mb-6",on:{"click":() => {
            _vm.component.setProductDialog();
          }}},[_vm._v("mdi-close")])],1),_c('v-text-field',{attrs:{"outlined":"","label":"Pesquisar","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setAutocomplete()}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","no-data-text":"Sem registros","no-results-text":"Nenhum produto encontrado","footer-props":{
            'items-per-page-options': [5, 10],
            'items-per-page-text': 'Items por página',
          }},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"ml-3",attrs:{"color":"green darken-3"},on:{"click":() => {
              _vm.add_product(item);
            }}},[_vm._v("mdi-plus-circle")])]}}],null,true)}),_c('v-divider'),_c('v-pagination',{attrs:{"color":"success","length":_vm.lastPage,"total-visible":10,"disabled":_vm.paginationDelay},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)],1)],1),(_vm.amountProductDialog)?_c('amountDialog',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }