import axiosautomacao from "../apigestao"
const {
  ADD_TABLE_PRODUCTS,
  REMOVE_PRODUCT,
  RESET_TABLE_PRODUCTS,
  UPDATE_AMOUNT_PRODUCTS,
  GIVE_DESCOUNT_PRODUCTS,
  LOAD_PRODUCTS_TABLE_TO_UPDATE,

  CREATE_BUDGET,
  GET_ALL_BUDGET,
  UPDATE_BUDGET,
  CANCEL_BUDGET,
  AUTOCOMPLETE_BUDGET,
  SEARCH_DATE_BUDGET,
  TRANSFORM_BUDGET_IN_SALES
} = require("./actions/budget");
const { SPLICE_ERRO } = require("./actions/alert_api");
const moduleCart = {
  state: () => ({
    productsTable: [],
    budgetsTable: [],
    productsUpdateControl: [],
    budgetTransform :[],
  }),

  mutations: {
    ADD_TABLE_PRODUCTS: (state, product) => {
        let auxstate  = state.productsTable
        let productAlreadyAdded = Boolean()
        if(state.productsTable.length > 0) {
            for(let i=0; i<state.productsTable.length; i++) {
                if(state.productsTable[i].id == product.id) {
                    productAlreadyAdded = true
                    product.product_amount += state.productsTable[i].product_amount
                    product.product_budget_value += state.productsTable[i].product_budget_value
                    auxstate.splice(i, 1, product)
                }
            }
            if(!productAlreadyAdded) {
                product.type_of_operation = "CREATE"
                product.budget_id = null
                state.productsUpdateControl.push(product)
                state.productsTable.push(product)
            }
        } else {
            product.type_of_operation = "CREATE"
            product.budget_id = null
            state.productsUpdateControl.push(product)
            state.productsTable.push(product)
        }
    },
    REMOVE_PRODUCT: (state, payload) => {
        let auxstate  = state.productsTable
        const index = auxstate.findIndex((item) => item.id === payload.id)


        let deletedItem = {...auxstate[index]}
        deletedItem.type_of_operation = "DELETE"
        if(deletedItem.budget_id == null) {
          let updateState  = state.productsUpdateControl
          const indexUpdate = updateState.findIndex((item) => item.id === deletedItem.id)
          updateState.splice(indexUpdate, 1)
        } else {
          state.productsUpdateControl.push(deletedItem)
        }
        

        auxstate.splice(index, 1)
        state.productsTable = auxstate
    },
    RESET_TABLE_PRODUCTS: (state) => {
      state.productsTable = [];
      state.productsUpdateControl = [];
    },
    UPDATE_AMOUNT_PRODUCTS: (state, payload) => {
        let auxstate  = state.productsTable
        const index = auxstate.findIndex((item) => item.id === payload.id)
        auxstate.splice(index, 1, payload)
        state.productsTable = auxstate
    },
    GIVE_DESCOUNT_PRODUCTS: (state, payload) => {
        let auxstate  = state.productsTable
        const index = auxstate.findIndex((item) => item.id === payload.id)
        auxstate.splice(index, 1, payload)
        state.productsTable = auxstate
    },
    LOAD_PRODUCTS_TABLE_TO_UPDATE: (state, payload) => {
      for(let i=0; i<payload.length; i++) {
        payload[i].product_amount = payload[i].amount
        payload[i].sale_price = payload[i].value_accounting_product
        payload[i].value_descount = payload[i].discount_amount
        payload[i].product_budget_value = payload[i].value_budget_product
        payload[i].budget_id = payload[i].id
        payload[i].id = payload[i].product_id
        payload[i].type_of_operation = "UPDATE"
      }
      state.productsTable = payload
    },

    GET_ALL_BUDGET: (state, payload) => {
        state.budgetsTable = payload
    },
    AUTOCOMPLETE_BUDGET: (state, payload) => {
        state.budgetsTable = payload
    },
    SEARCH_DATE_BUDGET: (state, payload) => {
        state.budgetsTable = payload
    },
    TRANSFORM_BUDGET_IN_SALES: (state, payload)=> {
       state.budgetTransform = payload
    }

  },

  actions: {
    ADD_TABLE_PRODUCTS({commit}, payload) {
      commit(ADD_TABLE_PRODUCTS, payload);
    },
    REMOVE_PRODUCT({commit}, payload) {
      commit(REMOVE_PRODUCT, payload);
    },
    RESET_TABLE_PRODUCTS({commit}) {
        commit(RESET_TABLE_PRODUCTS);
    },
    UPDATE_AMOUNT_PRODUCTS({commit}, payload) {
      commit(UPDATE_AMOUNT_PRODUCTS, payload);
    },
    GIVE_DESCOUNT_PRODUCTS({commit}, payload) {
      commit(GIVE_DESCOUNT_PRODUCTS, payload);
    },
    LOAD_PRODUCTS_TABLE_TO_UPDATE({commit}, payload) {
      commit(LOAD_PRODUCTS_TABLE_TO_UPDATE, payload);
    },


    CREATE_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/createBudget", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();;
            commit(CREATE_BUDGET, response);
            commit(SPLICE_ERRO, {
              alert: "Orçamento gerado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            commit(SPLICE_ERRO, {
              alert: "Erro ao gerar orçamento",
              type: "E",
            });
          });
      });
    },
    TRANSFORM_BUDGET_IN_SALES ({commit},payload){
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/transformBudgetIntoSale", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          
          .then((response) => {
            resolve({ response });
            commit(TRANSFORM_BUDGET_IN_SALES, response);
            commit(SPLICE_ERRO, {
              alert: "Orçamento Transformado em vendas com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            commit(SPLICE_ERRO, {
              alert: "Erro ao gerar orçamento",
              type: "E",
            });
          });
      });

    },
    UPDATE_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/updateBudget ", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();;
            commit(UPDATE_BUDGET, response);
            commit(SPLICE_ERRO, {
              alert: "Orçamento atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            commit(SPLICE_ERRO, {
              alert: "Erro ao atualizar orçamento",
              type: "E",
            });
          });
      });
    },
    GET_ALL_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/getAllByStatus", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            commit(GET_ALL_BUDGET, response.data);
          })
          .catch(() => {
            // commit(SPLICE_ERRO, {
            //   alert: "Erro ao carregar orçamentos",
            //   type: "E",
            // });
          });
      });
    },
    AUTOCOMPLETE_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/autocompleteByEnterprise", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();;
            commit(AUTOCOMPLETE_BUDGET, response.data);
          })
          .catch(() => {
            commit(AUTOCOMPLETE_BUDGET, []);
          });
      });
    },
    SEARCH_DATE_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/getBudgetByDateBudget", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();;
            commit(SEARCH_DATE_BUDGET, response.data);
          })
          .catch(() => {
          });
      });
    },
    CANCEL_BUDGET({ commit }, payload) {
      let token = localStorage.getItem("token_gestao");
      return new Promise((resolve, reject) => {
        axiosautomacao
          .post("budget/cancelBudget", payload, {
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          })
          .then((response) => {
            resolve();;
            commit(CANCEL_BUDGET, response.data);
          })
          .catch(() => {
            commit(SPLICE_ERRO, {
              alert: "Erro ao cancelar orçamento",
              type: "E",
            });
          });
      });
    },
  },
  getters: {
    getproductsTable: (state) => state.productsTable,
    getBudgetsTable: (state) => state.budgetsTable,
    getProductsUpdateControl: (state) => state.productsUpdateControl,

  },
};

export default moduleCart;