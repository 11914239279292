<template>
  <v-row justify="center">
    <v-dialog
      persistent
      @keydown.esc="cancel"
      v-model="component.dialog_cashier"
      max-width="350"
      transition="fab-transition"
    >
      <v-card>
        <v-card-title class="title">{{ words.title }}</v-card-title>
        <v-card-subtitle>{{ words.desc }}</v-card-subtitle>
        <v-divider></v-divider>
        <v-container>
          <v-form v-model="valid">
            <v-text-field
              outlined
              label="Tipo operação de caixa"
              v-model="cashier_operation_types_description"
              readonly
            ></v-text-field>
            <v-text-field
              outlined
              dense
              tile
              :label="words.input_label"
              v-model="openCashier.openning_value"
              placeholder="0.00"
              prefix="R$"
              :rules="[
                  () => !!openCashier.openning_value || 'Campo Obrigatório',
                  () =>
                    (!!openCashier.openning_value &&
                      openCashier.openning_value > 0) ||
                    'Valor de abertura deve ser maior que 0',
                ]"
            ></v-text-field>
          </v-form>

          <v-card-actions>
            <v-row justify="center">
              <v-btn
                v-if="component.disabledCancelOpenCashier"
                class="error mr-1"
                @click="suggest_print ? open_cashier(null) : cancel()"
              >
                <v-icon class="mr-2"> mdi-cancel</v-icon>
                {{ words.cancel }}</v-btn
              >
              <v-btn
                class="success"
                :disabled="!valid"
                @click="
                  () =>
                    current_operation == 'CONFIRM'
                      ? suggestPrint()
                      : open_cashier(1)
                "
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                {{ words.confirm }}</v-btn
              >
            </v-row>
          </v-card-actions>
          <v-card-subtitle>{{ words.btn }}</v-card-subtitle>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { OPEN_CASHIER } from "../../../store/actions/sales";
import { GET_ALL_CASHIER_OPERATION_TYPE } from "../../../store/actions/cashier_operation_type";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    words: {
      btn: "Pressione ESC para sair",
      confirm: "Confirmar",
      cancel: "Cancelar",
      title: "Abrir Caixa",
      desc: "Informe o valor de abertura do caixa.",
      input_label: "Valor de Abertura",
    },
    operation_type: {
      SUGGEST: "SUGGEST",
      CONFIRM: "CONFIRM",
    },
    cashier_operation_types_description: Object(),
    cashier_operation_types_id: Number(),
    current_operation: "CONFIRM",
    valid: Boolean(),
    openCashier: {
      dateMovement: Date(),
      numberMovement: String(),
      descriptionMovement: String(),
      typeMovement: String(),
      enterprise_id: Number(),
      openning_value: String(),
      cashier_operation_types_id: Number(),
    },
    isPritting: Boolean(),
    suggest_print: Boolean(),
    countOpenCashierBtn: 0,
  }),

  methods: {
    cancel() {
      this.component.dialog_cashier = !this.component.dialog_cashier;
    },
    suggestPrint(val) {
      if (val) this.isPritting = true;

      this.suggest_print = true;
    },
    async open_cashier(value) {
      this.countOpenCashierBtn++
      if(this.countOpenCashierBtn == 1) {
      if (value) this.isPritting = true;
      let object = { ...this.openCashier };
      object.dateMovement = this.mounterDateOpenCashier();
      let max = 100000;
      let min = 1;
      object.numberMovement = String(
        Math.floor(Math.random() * (max - min) + min)
      );
      (object.enterprise_id = this.getEnterprises.data[0].id),
        (object.descriptionMovement = "Abertura de caixa");
       object.typeMovement = "Entrada";
      (object.cashier_operation_types_id = this.cashier_operation_types_id),
        (this.component.titlePDF = "ABERTURA DE CAIXA");
          try {
            await this.$store.dispatch(OPEN_CASHIER, object);
            this.component.needPrint = this.isPritting;
            this.component.doc_operation = object.numberMovement
            this.component.open_cashie_payload = object.openning_value;
            this.cancel();
          } catch (error) {
            error;
          }
        }
    },
    checkState() {
      switch (this.current_operation) {
        case this.operation_type.SUGGEST:
          this.words.confirm = "IMPRIMIR";
          this.words.cancel = "NÃO";

          break;

        case this.operation_type.CONFIRM:
          this.words.confirm = "Confirmar";
          this.words.cancel = "Cancelar";

          break;
      }
    },
    mounterDateOpenCashier() {
      // Create a date object from a date string
      let date = new Date();

      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    processTypeOperationCashier(cashierOperationTypeList) {
      const ABERTURA_CAIXA = "Abertura de caixa";

      cashierOperationTypeList.forEach((cashierOperationType) => {
        if (
          cashierOperationType.type.toLowerCase() ===
          ABERTURA_CAIXA.toLowerCase()
        ) {
          this.cashier_operation_types_description =
            cashierOperationType.description;
          this.cashier_operation_types_id = cashierOperationType.id;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getAllCashierOperationType", "getEnterprises"]),
  },
  async created() {
    await this.$store.dispatch(GET_ALL_CASHIER_OPERATION_TYPE, {});
  },

  watch: {
    current_operation: function () {
      this.checkState();
    },
    getAllCashierOperationType: function (val) {
      this.processTypeOperationCashier(val[0]);
    },
    suggest_print: function (v) {
      if (v) {
        this.current_operation = this.operation_type.SUGGEST;
      } else {
        this.current_operation = this.operation_type.CONFIRM;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Courier New", Courier, monospace;
}
</style>
