const headers = [
    { text: 'Código', value: 'id' },
    { text: 'Número do orçamento', value: 'number_budget'},
    { text: 'Status', value: 'status' },
    { text: 'Cliente', value: 'client.name' },
    { text: 'Valor', value: 'valor_products_total' },
    { text: 'Desconto', value: 'value_descont_total' },
    { text: 'Valor total', value: 'value_total_note' },
    { text: 'Ações', value: 'actions' }
];

export { headers };