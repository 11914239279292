<template>
    <v-row>
      <v-dialog
        persistent
        transition="scale-transition"
        max-width="550"
        v-model="component.descountPermissionDialog"
      >
      <v-form
          v-model="form"
      >
        <v-card class="pa-7" style="background-color: #264867;" rounded="xl" elevation="15">
          <div style="display: flex; justify-content: end">
            <v-btn
              icon
              style="color: #faf9f9;"
              @click="component.setPermissionDiscountDialog()"
            >
              <v-icon style="height: 20px; width: 20px;">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-col>
            <v-row>
            <div style="justify-content: start;">
              <p>
                Insira suas credencias para liberação de desconto acima do permitido
              </p>
            </div>
          </v-row>
          <v-row>
            <v-text-field
                label="Gerente"
                required
                background-color="#faf9f9"
                prepend-inner-icon="mdi-email"
                class="rounded-lg"
                solo
                v-model="component.email"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                label="Senha"
                required
                :append-icon="showPassWord ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassWord ? 'text' : 'password'"
                @click:append="setShowPassword()"
                class="rounded-lg"
                prepend-inner-icon="mdi-key"
                background-color="#faf9f9"
                solo
                v-model="component.password"
            ></v-text-field>
          </v-row>
  
            <v-row class="d-flex justify-center">
            <v-btn
              color="#edf2fb"
              class="rounded-lg blue--text darken-4--text"
              large
              width="100%"
              :loading="component.isLoading"
              :disabled="component.isLoading"
              @click="component.permissionDiscountLogin()"
            >
              <v-progress-circular
                v-if="component.isLoading == true"
                indeterminate
                color="white"
              ></v-progress-circular>
              <v-card-text v-if="component.isLoading == false">
                Login
              </v-card-text>
            </v-btn>
          </v-row>
        </v-col>

        </v-card>
      </v-form>
      </v-dialog>
  
    
    </v-row>
    
  </template>
  
  <script>
  export default {
    props: {
      component: Object,
    },
    data: () => ({
      form: false,
      showPassWord: false,
    }),
    methods: {
      setShowPassword() {
        this.showPassWord = !this.showPassWord
      },
    },

    beforeDestroy() {
      this.component.isLoading = false
      this.component.email = ""
      this.component.password = ""
    }
  };
  </script>
  <style scoped>
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
    p {
      text-align: start;
      color: white; 
      width: 100%; 
      font-size: 20px; 
      font-weight: bold;
      font-family: "Roboto";
    }
  </style>
  