const ADD_SALES = "ADD_SALES";
const GET_CALC_TRIBUTE = "GET_CALC_TRIBUTE";
const UPDATE_SALES = "UPDATE_SALES";
const GET_ALL_SALES = "GET_ALL_SALES";
const DESTROY_SALES = "DESTROY_SALES";
const SET_CLIENTSALES = "SET_CLIENTSALES";
const OPEN_CASHIER = "OPEN_CASHIER";
const BLEED = "BLEED";
const SUPPLY = "SUPPLY"
const GET_CLOSE_SALE = "GET_CLOSE_SALE"
const CONFIRM_CLOSE_CASHIER = "CONFIRM_CLOSE_CASHIER"
const SET_CLIENT_IN_MEMORY = "SET_CLIENT_IN_MEMORY"
const SET_SELLER_IN_MEMORY = "SET_SELLER_IN_MEMORY"
const RESET_MEMORY = "RESET_MEMORY"
const CANCEL_SALES = "CANCEL_SALES"
const GET_BY_ID_SALES = "GET_BY_ID_SALES"
const GET_MOVEMENT_OPEN_CASHIER = "GET_MOVEMENT_OPEN_CASHIER"
const SALES_PROCESSING_MESSAGE = "SALES_PROCESSING_MESSAGE"
const SEARCH_COUPON = "SEARCH_COUPON"
const GET_ALL_SALES_WITH_FILTER = "GET_ALL_SALES_WITH_FILTER"
const AUTOCOMPLETE_SALES = "AUTOCOMPLETE_SALES"
const GET_CASHIER_CLOSE_REPORT = "GET_CASHIER_CLOSE_REPORT"
const DOWNLOAD_CREDIT = "DOWNLOAD_CREDIT"

module.exports = {
  ADD_SALES,
  GET_CALC_TRIBUTE,
  UPDATE_SALES,
  GET_ALL_SALES,
  DESTROY_SALES,
  SET_CLIENTSALES,
  OPEN_CASHIER,
  BLEED,
  SUPPLY,
  GET_CLOSE_SALE,
  CONFIRM_CLOSE_CASHIER,
  SET_SELLER_IN_MEMORY,
  RESET_MEMORY,
  SET_CLIENT_IN_MEMORY,
  CANCEL_SALES,
  GET_BY_ID_SALES,
  GET_MOVEMENT_OPEN_CASHIER,
  SALES_PROCESSING_MESSAGE,
  SEARCH_COUPON,
  GET_ALL_SALES_WITH_FILTER,
  AUTOCOMPLETE_SALES,
  GET_CASHIER_CLOSE_REPORT,
  DOWNLOAD_CREDIT,
};