<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="search" label="Pesquisar" outlined append-icon="mdi-magnify">
        </v-text-field>
      </v-card-text>
      <Table :component="this" :search="search" />
      <AddDialog v-if="dialogContigency" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Contingency/Container";
import loadScreen from "../components/load/index.vue"
import CardTitle from "../components/Contingency/CardTitle";
import Table from "../components/Contingency/Table/index.vue";
import AddDialog from "../components/Contingency/AddDialog";
import { mapGetters } from "vuex";


import { GET_ALL_ENTERPRISE } from "../store/actions/enterprise"

import AlertApi from "../components/alert_api/";

export default {
  data: () => ({
    search: "",
    code: "",
    dialogContigency: false,
    permission: [],
    enterprises: Array(),
    enterpriseSelect: Object(),
    dialogCancelContingency: Boolean(),
    select: "",
  }),
  methods: { 
   async GetAllEnterprise(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
  },
  computed: {
    ...mapGetters(["getCadastrosLinks", "getEnterprises"]),
  },
  mounted() {
    let name = document.URL.split("/").pop()
    this.permission = this.getCadastrosLinks.getPermissions(name)
  },
  async created() {
    await this.GetAllEnterprise();
  },
  watch: {
    getEnterprises(val) {
      if(val.data){
        this.enterprises = Object.values(val.data);
        this.enterpriseSelect = this.enterprises[0]
      }else{
        this.enterprises = Object.values(val);
        this.enterpriseSelect = this.enterprises[0]
      }
    },
  },

  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    AlertApi,
    loadScreen
  },
};
</script>

<style scoped></style>
