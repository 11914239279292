import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b816b1f"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=1b816b1f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=div&class=tabletFrame%20d-none%20d-sm-flex%20d-md-none"
if (typeof block0 === 'function') block0(component)
import block1 from "./index.vue?vue&type=custom&index=1&blockType=div&class=d-flex%20d-sm-none"
if (typeof block1 === 'function') block1(component)

export default component.exports