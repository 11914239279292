var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-dialog',{attrs:{"persistent":"","transition":"scale-transition","max-width":"300"},model:{value:(_vm.component.amountProductDialog),callback:function ($$v) {_vm.$set(_vm.component, "amountProductDialog", $$v)},expression:"component.amountProductDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',[_vm._v("Qual a quantidade?")])],1),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Quantidade","rules":[
                () => !!_vm.component.amount || 'Campo Obrigatorio',
                () =>
                  (!!_vm.component.amount && _vm.component.amount > 0) ||
                  'A quantidade não pode ser igual a 0',
              ]},model:{value:(_vm.component.amount),callback:function ($$v) {_vm.$set(_vm.component, "amount", $$v)},expression:"component.amount"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red darken-4 white--text",attrs:{"depressed":""},on:{"click":() => {
                  _vm.component.setAmountProductDialog();
                }}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.valid,"depressed":""},on:{"click":() => {
                  _vm.component.setAmountProduct();
                }}},[_vm._v(" Adicionar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }