const ADD_TABLE_PRODUCTS = "ADD_TABLE_PRODUCTS";
const REMOVE_PRODUCT = "REMOVE_PRODUCT";
const RESET_TABLE_PRODUCTS = "RESET_TABLE_PRODUCTS";
const UPDATE_AMOUNT_PRODUCTS = "UPDATE_AMOUNT_PRODUCTS";
const GIVE_DESCOUNT_PRODUCTS = "GIVE_DESCOUNT_PRODUCTS";
const LOAD_PRODUCTS_TABLE_TO_UPDATE = "LOAD_PRODUCTS_TABLE_TO_UPDATE";

const CREATE_BUDGET = "CREATE_BUDGET";
const GET_ALL_BUDGET = "GET_ALL_BUDGET";
const UPDATE_BUDGET = "UPDATE_BUDGET";
const CANCEL_BUDGET = "CANCEL_BUDGET";
const AUTOCOMPLETE_BUDGET = "AUTOCOMPLETE_BUDGET";
const SEARCH_DATE_BUDGET = "SEARCH_DATE_BUDGET";
const TRANSFORM_BUDGET_IN_SALES = "TRANSFORM_BUDGET_IN_SALES"



module.exports = {
    ADD_TABLE_PRODUCTS,
    REMOVE_PRODUCT,
    RESET_TABLE_PRODUCTS,
    UPDATE_AMOUNT_PRODUCTS,
    GIVE_DESCOUNT_PRODUCTS,
    LOAD_PRODUCTS_TABLE_TO_UPDATE,

    CREATE_BUDGET,
    GET_ALL_BUDGET,
    UPDATE_BUDGET,
    CANCEL_BUDGET,
    AUTOCOMPLETE_BUDGET,
    SEARCH_DATE_BUDGET,
    TRANSFORM_BUDGET_IN_SALES 
};