import axiosfiscal from "../apifiscal";

const {
    CHECK_EMITER_NFE_REFERENCES    
} = require('./actions/nfe')

const moduleNfe  = {

    state: () => ({
        getNfeReferences: Object(),
    }),
    mutations: {
        CHECK_EMITER_NFE_REFERENCES: (state, payload) => {
            state.getNfeReferences = payload
        }

    },
    actions : {
    async CHECK_EMITER_NFE_REFERENCES({commit}, payload){
        let token = localStorage.getItem("token_fiscal");
        return new Promise((resolve)=> {
            axiosfiscal.post("/noteissue/getNfeReferences", payload, {
               headers: {
                   'Authorization': 'Bearer ' + token,
               }
           }).then((response) => {
               resolve({ response })
               commit(CHECK_EMITER_NFE_REFERENCES, response.data)
           }).catch((r) => {
               reject(r)
               commit(SPLICE_ERRO, {
                   alert: "Erro ao obter a validação da nfce emitida!",
                   type: 'E'
               })
           })
        })    
    }
    },
    getters: {
        getNfeReferences : (state)=> ({
            ...state.getNfeReferences
        })
    }

}
export default moduleNfe

