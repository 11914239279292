const headers = [
    { text: 'Código', value: 'id' },
    { text: 'Descrição', value: 'description_item' },
    { text: 'Quantidade', value: 'product_amount' },
    { text: 'Preço', value: 'sale_price' },
    { text: 'Desconto', value: 'value_descount' },
    { text: 'Sub Total', value: 'product_budget_value' },
    { text: 'Ações', value: 'actions' }
];

export { headers };