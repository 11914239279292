var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","no-data-text":"Nenhum produto adicionado"},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"orange darken-3"},on:{"click":() => {
            _vm.component.updateDescount(item);
          }}},'v-icon',attrs,false),on),[_vm._v(" mdi-calculator ")])]}}],null,true)},[_c('span',[_vm._v("Desconto")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
            _vm.component.setItemToUpdate(item);
          }}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Alterar quantidade")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-4r"},on:{"click":() => {
            _vm.component.setItemToRemove(item)
          }}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Remover produto")])])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }