<template>
    <Container>
      <v-card>
        <v-card-text>
          <CardTitle :component="this"/>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field  label="Data Inicial" v-model="filterData.initialDate" outlined type="date">
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field  label="Data Final" v-model="filterData.finalDate" outlined type="date">
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select  label="Status da Nota" v-model="filterData.status" :items="statusItems" outlined>
              </v-select>
            </v-col>
              <v-btn
                class="mr-2 mt-6"
                color="success"
                style="width: 60px;"
                @click="searchByDate()"
              >
                <v-icon dark>
                  mdi-magnify
                </v-icon>
              </v-btn>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-text-field v-model="searchInput" label="Pesquisar" outlined append-icon="mdi-magnify" @keyup.enter="setAutocomplete()">
          </v-text-field>
        </v-card-text>
        <AddDialog :component="this" v-if="budgetDialog"/>
        <CancelDialog :component="this" v-if="cancelBudgetDialog"/>
        <ErrorDialog :component="this" :info="infoErrorValid" v-if="errorDialog"/>
        <Table :component="this" />
      </v-card>
      <AlertApi />
      <InfoError/>
      <!-- <loadScreen/> -->
    </Container>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import Container from "../components/Utils/Container/Container";
  import CardTitle from "../components/Budget/CardTitle";
  import Table from "../components/Budget/Table";
  import AddDialog from "../components/Budget/AddDialog/index.vue";
  import CancelDialog from "../components/Budget/CancelBudget/index.vue";
  import ErrorDialog from "../components/Budget/ErrorDialog/index.vue";
  import AlertApi from "./../components/alert_api";
  import loadScreen from "./../components/load";
  import { GET_ALL_BUDGET, CANCEL_BUDGET, AUTOCOMPLETE_BUDGET, SEARCH_DATE_BUDGET } from "../store/actions/budget"

  import GeneratePdf from "../components/Budget/PDF/generatePDF"
  export default {
    components: { Container, CardTitle, Table, AlertApi, loadScreen, AddDialog, CancelDialog, ErrorDialog },
    data: () => ({
      search: "",
      searchInput: "",
      paginationDelay: Boolean(),
      budgets: Array(),
      lastPage: Number(),
      current: Number(),
      infoErrorValid: String(),
      statusItems: ['Todos', 'Aprovado', 'Em andamento', 'Cancelado'],
      filterData: {
        initialDate: Date(),
        finalDate: Date(),
        status: 'Todos',
        enterpriseId: Number(),
      },
      budgetDialog: Boolean(),
      budgetToUpdate: Object(),
      cancelBudgetDialog: Boolean(),
      budgetToCancel: Object(),
      errorDialog: Boolean(),
      dateSearch: Boolean(),
    }),
  
    methods: {
      GeneratePdf,
      setBudgetDialog() {
        this.budgetDialog = !this.budgetDialog
        if(!this.budgetDialog) {
          this.budgetToUpdate = {}
          this.GetAllBudget(this.current)
        }
      },

      loadFilterDate() {
        var currentDate = new Date();
        var initialDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString().split('T')[0];
        var lastDate = currentDate.toISOString().split('T')[0];
        this.filterData.initialDate = initialDate;
        this.filterData.finalDate = lastDate;
    },

      setAutocomplete() {
        this.search = this.searchInput
      },

      autocompleteBudget(page = 1) {
        const payload = {
          search: this.search,
          limit: 10,
          page: page,
          paginate: true,
          status: null,
          enterprise_id: this.getEnterprises.data[0].id
        }
        this.dateSearch = false
        this.$store.dispatch(AUTOCOMPLETE_BUDGET, payload)
      },

      GetAllBudget(page = 1) {
        const payload = {
          limit: 10,
          page: page,
          paginate: true,
          status: null,
          enterprise_id: this.getEnterprises.data[0].id
        }
        this.$store.dispatch(GET_ALL_BUDGET, payload)
      },

      setBudgetToUpdate(budget) {
        const STOCK_GENERATE_ACTIVE = true 
        const GENERATE_FINANCIAL_ACTIVE = true 
        if(budget.status == "Em andamento") {
          if(budget.calculates_stock == STOCK_GENERATE_ACTIVE||  budget.budget_operation.generate_financial == GENERATE_FINANCIAL_ACTIVE ) {
           this.setErrorMessageDialog("Não e possivel editar orçamento com estoque reservado")
          }else if (budget.calculates_stock != STOCK_GENERATE_ACTIVE ||  budget.budget_operation.generate_financial != GENERATE_FINANCIAL_ACTIVE)  {
          this.budgetToUpdate = budget
          this.setBudgetDialog()
          }
        } else {
          this.setErrorMessageDialog("Esta ação só pode ser realizada em orçamentos em andamento")
        }
      },

      cancelBudget(budget) {
        if(budget.status == "Em andamento") {
          this.budgetToCancel = budget.id
          this.setCancelDialog()
        } else {
          this.setErrorMessageDialog("Esta ação só pode ser realizada em orçamentos em andamento")
        }
      },

      setCancelDialog() {
        this.cancelBudgetDialog = !this.cancelBudgetDialog
      },

      setErrorMessageDialog(infoValid) {
        this.infoErrorValid = infoValid
        this.errorDialog = !this.errorDialog
      },


      confirmCancelBudget() {
        const payload = {
          budget_id: this.budgetToCancel,
          enterprise_id: this.getEnterprises.data[0].id
        }
        this.$store.dispatch(CANCEL_BUDGET, payload).then(() => {
          this.setCancelDialog()
          this.GetAllBudget()
        })
      },
//
      printBudget(budget) {
        budget.budget_items = budget.budget_item
        budget.budgetPaymentMethod = budget.budget_payment_method
        for(let i=0; i<budget.budgetPaymentMethod.length; i++) {
          budget.budgetPaymentMethod[i].form_of_payment_description = budget.budgetPaymentMethod[i].formofpayment.description
        }
        this.GeneratePdf(this.getEnterprises.data[0], budget, budget.client.name, budget.seller.people.physicalpeople.name)
      },

      searchByDate(page = 1) {
        let status = this.filterData.status
        if(status == 'Todos') {
          status = null
        }
        const payload = {
          status: status,
          initial_date: this.filterData.initialDate,
          final_date: this.filterData.finalDate,
          enterprise_id: this.getEnterprises.data[0].id,
          page: page,
          limit: 10,
          paginate: true
        }
        this.dateSearch = true
        this.$store.dispatch(SEARCH_DATE_BUDGET, payload)
      }
    },
    computed: {
      ...mapGetters([
        "getEnterprises",
        "getBudgetsTable",
        "auth/getUser",
      ]),
    },
    created() {
      this.loadFilterDate()
    },
    watch: {
      getBudgetsTable: function(val) {
        this.budgets = val.data
        this.current = val.current_page
        this.lastPage = val.last_page
        for(let i=0; i<this.budgets.length; i++) {
          if(this.budgets[i].client.type_people == "PhysicalPeople") {
            this.budgets[i].client.name = this.budgets[i].client.people.physicalpeople.name
          } else {
            this.budgets[i].client.name = this.budgets[i].client.people.legalpeople.fantasyName
          }
        }
        this.paginationDelay = false
      },

      search: function(val) {
        if(val.length >= 2) {
          this.autocompleteBudget()
        }
      },

      searchInput: function(val) {
        if(val.length == 0) {
          this.search = ""
          this.GetAllBudget()
        }
      },

     current: function(val) {
        this.paginationDelay = true
        if(this.filterData.status == 'Todos') {
          this.GetAllBudget && this.GetAllBudget(val);
        } else {
          this.searchByDate(val)
        }        
      }
    }
  };
  </script>
  
  <style></style>
  