import jsPDF from "jspdf";
import "jspdf-autotable";


export default  function generatePdf(enterprise, budget, client, seller) {
    let user = {...this["auth/getUser"].user}

      let data = new Date();

      // TOTAL PRODUTOS
      let totalValueProducts = Object()
      let totalAmount = Number()
      let totalUnit = Number()
      let totalDesc = Number()
      let totalSubt = Number()
      for(let i=0; i<budget.budget_items.length; i++) {
          totalAmount += budget.budget_items[i].amount
          totalUnit += budget.budget_items[i].value_accounting_product
          totalDesc += budget.budget_items[i].discount_amount
          totalSubt += budget.budget_items[i].value_budget_product
      }

      totalValueProducts.description_item = "TOTAL"
      totalValueProducts.amount = totalAmount
      totalValueProducts.value_accounting_product = totalUnit.toFixed(2)
      totalValueProducts.discount_amount = totalDesc.toFixed(2)
      totalValueProducts.value_budget_product = totalSubt.toFixed(2)
      
      budget.budget_items.push(totalValueProducts)
      
      // TOTAL FORMAS DE PAGAMENTO
      let totalValueFormOfPayments = Object()
      totalValueFormOfPayments.form_of_payment_description = "TOTAL"
      totalValueFormOfPayments.value_form_payment = budget.value_total_note

      budget.budgetPaymentMethod.push(totalValueFormOfPayments)

      const date = new Date(budget.date_budget);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const formatedDate = `${day}/${month}/${year}`

      let productColumns = [
        { title: "Nome", dataKey: "description_item" },
        { title: "Qtd", dataKey: "amount" },
        { title: "Unit", dataKey: "value_accounting_product" },
        { title: "Desc", dataKey: "discount_amount" },
        { title: "Subt", dataKey: "value_budget_product" },
      ];

      let formOfPaymentColumns = [
        { title: "Forma", dataKey: "form_of_payment_description" },
        { title: "Valor", dataKey: "value_form_payment" },
        
      ];
      var doc = new jsPDF({
        unit: 'mm',
        format: [80, 40],  
      });

      doc.setLineWidth(0.1);
      doc.setFontSize(2);
      doc.text(`Usuário : ${user.name}`, 2, 1);
      doc.text(`${data.toLocaleString()}`, 2, 2);

      doc.setFontSize(3);
      doc.line(2, 3, 38, 3);
      doc.text(enterprise.name, 8, 5);
      doc.setFontSize(2);
      doc.text(`Nome Fantasia : ${enterprise.fantasyName}`, 2, 7);
      doc.text(`Rua : ${enterprise.address.street}`, 2, 8);       
      doc.text(`Numero : ${enterprise.address.number}`, 15, 8);
      doc.text(`Cidade : ${enterprise.address.city}`, 25, 8);
      doc.text(`Estado : ${enterprise.address.state}`, 2, 9);
      doc.text(`CEP : ${enterprise.address.cep}`, 15, 9);
      doc.setFontSize(2);
      doc.line(2, 10, 38, 10);
      doc.text(`Doc: ${this.doc_operation}`, 2, 11);
      doc.text(`PDV: ${this.pdv_id}`, 32, 11);
      doc.setFontSize(3);
      doc.text(`ORÇAMENTO ${budget.status.toUpperCase()}`, 13, 14);
      doc.setFontSize(3);    
  
      doc.text(`Data....................: ${formatedDate}`, 2, 16);
      doc.text(`Nº Orçamento............: ${budget.number_budget}`, 23, 16);
      doc.text(`Vendedor................: ${seller}`, 2, 20);
      doc.text(`Cliente.................: ${client}`, 23, 20);
      doc.text(`Valor.................: R$${budget.value_total_note}`, 13, 24);
      doc.setFontSize(2);
      doc.line(2, 26, 38, 26);
      doc.setFontSize(3);
      doc.text("PRODUTOS", 16, 28);
      doc.autoTable(productColumns, budget.budget_items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        margin: { top: 2, right: 1, left: 1},
        startY: 29 ,
        theme: "grid",
        styles: {
        cellWidth: "wrap",
        cellPadding: 1,
        fontSize: 1.8,
      },
      });
      const endYsales_by_payment_method = doc.lastAutoTable.finalY;
      doc.line(2, endYsales_by_payment_method + 4, 38, endYsales_by_payment_method + 4);
      doc.setFontSize(3);
      doc.text("FORMA DE PAGAMENTO", 14, endYsales_by_payment_method + 8);
      doc.autoTable(formOfPaymentColumns, budget.budgetPaymentMethod, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: [230, 230, 230],
          textColor: [54, 54, 54],
        },
        margin: { top: 2, right: 1, left: 1},
        startY: endYsales_by_payment_method + 9 ,
        theme: "grid",
        styles: {
        cellWidth: "wrap",
        cellPadding: 1,
        fontSize: 1.8,
      },
      });

      const pages = doc.internal.getNumberOfPages();

    doc.setFontSize(1); //Optional
    for (let j = 1; j < pages + 1; j++) {
      doc.setPage(j);
      doc.text(`${j} / ${pages}`, endYsales_by_payment_method + 30, 40);
    }
      doc.output("dataurlnewwindow");




  
    }