var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"350","transition":"fab-transition"},model:{value:(_vm.component.dialog_supply),callback:function ($$v) {_vm.$set(_vm.component, "dialog_supply", $$v)},expression:"component.dialog_supply"}},[_c('v-card',[_c('v-card-title',[_vm._v("Suprimento")]),_c('v-card-subtitle',[_vm._v("Informe o valor a ser adicionado ao caixa.")]),_c('v-divider'),_c('v-container',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Tipo operação de caixa","readonly":""},model:{value:(_vm.cashier_operation_types_description),callback:function ($$v) {_vm.cashier_operation_types_description=$$v},expression:"cashier_operation_types_description"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Valor de Adição","prefix":"R$","placeholder":"0.00","rules":[
                () => !!_vm.supply.value_supply || 'Campo Obrigatório',
                () =>
                  (!!_vm.supply.value_supply &&
                    _vm.supply.value_supply > 0) ||
                  'Valor de suprimento deve ser maior que 0',
              ]},model:{value:(_vm.supply.value_supply),callback:function ($$v) {_vm.$set(_vm.supply, "value_supply", $$v)},expression:"supply.value_supply"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"error mr-1",on:{"click":function($event){_vm.suggest_print ? _vm.submit_supply(null) : _vm.cancel()}}},[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(_vm._s(_vm.words.cancel))],1),_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.valid},on:{"click":function($event){_vm.current_operation == 'CONFIRM'
                  ? _vm.suggestPrint()
                  : _vm.submit_supply(1)}}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.words.confirm))],1)],1)],1),_c('v-card-subtitle',[_vm._v("Pressione ESC para sair")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }