var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.component.sellerDialog),callback:function ($$v) {_vm.$set(_vm.component, "sellerDialog", $$v)},expression:"component.sellerDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-container',[_c('v-row',[_c('v-card-title',[_vm._v(" Pesquisar Vendedores ")]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2 mb-6",on:{"click":() => {
                _vm.component.setSellerDialog();
              }}},[_vm._v("mdi-close")])],1),_c('v-text-field',{attrs:{"outlined":"","label":"Pesquisar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.results,"hide-default-footer":"","no-data-text":"Sem registros","no-results-text":"Nenhum vendedor encontrado","footer-props":{
            'items-per-page-options': [10, 20, 30, -1],
            'items-per-page-text': 'Items por página',
          }},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"ml-3",attrs:{"color":"green darken-3"},on:{"click":() => {
                  _vm.add_seller(item);
                }}},[_vm._v("mdi-plus-circle")])]}}],null,true)}),_c('v-divider'),_c('v-pagination',{attrs:{"color":"success","length":_vm.total,"total-visible":10},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }