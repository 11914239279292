<template>
  <div class="frame">
    <!-- Computer -->
    <div>
      <Card :component="this" />
    </div>
  </div>
</template>

<script>
import Card from "./component/card.vue";
import { mapActions } from "vuex";
import ENV from "../../../env"
export default {
  components: {
    Card,
  },
  data: () => ({
    regex_email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    email: String(),
    password: String(),
    isLoading: Boolean(),
    showPassWord: Boolean(),
    expandTitle: Boolean(),
    expand: Boolean(),
    userApiFiscal: ENV.getInstance().VUE_APP_USER_API_FISCAL,
    passwordApiFiscal: ENV.getInstance().VUE_APP_PASSWORD_API_FISCAL
  
  }),

  methods: {
    setShowPassword() {
      this.showPassWord = !this.showPassWord;
    },
    ...mapActions({
      login: "auth/login",
      loginGestao: "auth/loginGestao",
      loginApiFiscal: "auth/loginApifiscal",
    }),
    async submit() {
      this.loginGestao({ email: this.email, password: this.password })
        .then(() => {})
        .catch((error) => {});
      this.loginApiFiscal({ email: this.userApiFiscal, password: this.passwordApiFiscal }) //this.email //this.password
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.isLoading = true;
      await this.login({ email: this.email, password: this.password })
        .then(() => {
          this.$router.replace({ name: "Shortcuts" });
        })
        .catch(() => {
          this.isLoading = false;
        });
        this.isLoading = false;
    },
  },
  beforeMount() {
    this.expand = false;
    this.expandTitle = false;
  },
  mounted() {
    setTimeout(() => {
      this.expandTitle = true;
    }, 500);
    setTimeout(() => {
      this.expand = true;
    }, 1000);
  },
};
</script>

<style>
.frame {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>


    // <!-- tablet -->
    // <div class="tabletFrame d-none d-sm-flex d-md-none">
    //   <Card :component="this" />
    // </div>
    // <!-- Mobile -->
    // <div class="d-flex d-sm-none">
    //   <Card :component="this" />
    // </div>