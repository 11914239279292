<template>
  <div>
    <v-data-table :headers="headers" :items="component.budgets" hide-default-footer
      no-data-text="Nenhum orçamento cadastrado" no-results-text="Nenhum resultado encontrado">
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" color="green darken-3" v-bind="attrs" v-on="on" @click="() => {
      confirmConveterBudgetInSales(item);
    }
      ">
              mdi-cart-check
            </v-icon>
          </template>
          <span>Converter em venda</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" color="yellow darken-4" v-bind="attrs" v-on="on" @click="() => {
      component.setBudgetToUpdate(item);
    }
      ">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar orçamento</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue darken-4" class="mr-2" v-bind="attrs" v-on="on" @click="() => {
      component.printBudget(item);
    }
      ">mdi-printer</v-icon>
          </template>
          <span>Imprimir orçamento</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red darken-4" class="mr-2" v-bind="attrs" v-on="on" @click="() => {
      component.cancelBudget(item);
    }
      ">mdi-cancel</v-icon>
          </template>
          <span>Cancelar orçamento</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-pagination v-model="component.current" :length="component.lastPage" 
      color="success"
      :total-visible="10"
      :disabled="component.paginationDelay"
      ></v-pagination>
    <PrintNfce v-if="dialog_print_nfce" :component="this" />
    <ConfirmBudgetNFce v-if="dialog_confirm_budget" :component="this" />
  </div>
</template>

<script>
import { headers } from "./script";
import { mapGetters } from "vuex";
import ENV from "../../../env"
import PrintNfce from "../PrintNfce"
import ConfirmBudgetNFce from "../AddDialog/ConfirmBudgetInSales"
import {
  UPDATE_SALES,
} from "./../../../store/actions/sales";
import {
  GET_SEQUENCE_NFCE,
  ISSUE_OF_INVOICE_NFCE
} from "../../../store/actions/nfce";
import { GET_TAX_PRODUCT_IBPT } from "../../../store/actions/ibpt"
import { TRANSFORM_BUDGET_IN_SALES } from "../../../store/actions/budget"
import { GET_CALC_TRIBUTE } from "../../../store/actions/sales"
export default {
  props: {
    component: Object(),
  },
  components: {
    PrintNfce,
    ConfirmBudgetNFce
  },

  data: () => ({
    headers,
    results: Array(),
    budgetSelect: Object(),
    loadingConfirmBudget: false,
    dialog_confirm_budget: Boolean(),
    dialog_print_nfce: Boolean(),
    salesOfBudget: {
      status: "Em Digitacao",
      number_doc_client: "",
      data_sale: new Date,
      access_key: String(),
      number_sale: String(),
      authorization_protocol: String(),
      series: String(),
      wasProcessed: false,
      value_total_products: Number(),
      value_total_note: Number(),
      value_descount_total: Number(),
      value_account_total: Number(),
      value_icms_substituicao_total: Number(),
      base_cal_icms_total: Number(), //alterado  back ok
      base_calc_susbt_total: Number(), //alterado back ok
      value_icms_free_total: Number(),
      value_outher_tax_total: Number(),
      value_icms_total: Number(),
      note: "Venda de mercadoria", // Valor Mocado
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      base_calc_ipi_total: Number(),
      value_base_pis_total: Number(),
      value_base_pis_st_total: Number(),
      value_other_pis_total: Number(),
      value_pis_st_total: Number(),
      value_pis_total: Number(),
      value_base_cofins_st_total: Number(),
      value_base_cofins_total: Number(),
      value_other_cofins_total: Number(),
      value_cofins_st_total: Number(),
      value_cofins_total: Number(),
      valueApproximateTax: Number(),
      client_id: Number(),
      tax_operation_types_id: Number(),
      enterprise_id: Number(),
      account_plans_id: Number(),
      // pdv_id: ENV.getInstance().VUE_APP_PDV_COMPUTER_ID,
      seller_id: Number(),
      note_models_id: Number(),
      form_of_payments: Array(),
      enterprise: Object(),
      client: Object(),
      seller: Object(),
      user_id: Number(),
      budget_id: Number(),
    },
    nfcePrint: Object(),
    itensSalesOfBudget: {
      product: Object(),
      description: String(),
      code_item: Number(),
      amount: Number(),
      product_id: Number(),
      value_sale: Number(),
      value_descont_product: Number(),
      value_subtotal_product: Number(),
      value_base_icms_product: Number(), //alterado no back end
      value_accounting_product: Number(), //alterado no back end

      aliquot_icms_product: Number(), //alterado no back end
      value_icms_product: Number(),
      value_free_icms_product: Number(),
      value_icms_outher_product: Number(),
      value_base_icms_st_product: Number(),
      value_icms_st_product: Number(),
      aliquot_ipi_product: Number(),
      value_base_ipi_product: Number(),
      value_ipi_product: Number(),
      value_ipi_free_product: Number(),
      value_ipi_outher_product: Number(),
      aliquota_pis: Number(),
      value_base_pis_product: Number(),
      value_pis_product: Number(),
      value_base_pis_st_product: Number(),
      value_other_pis_st_product: Number(),
      value_other_pis_product: Number(),
      value_free_pis_product: Number(),
      value_pis_st_product: Number(), //adicionado no back end
      aliquota_cofins: Number(),
      value_base_cofins_product: Number(),
      value_cofins_product: Number(), //adicionado no back end
      value_other_cofins_product: Number(),
      value_free_cofins_product: Number(),
      value_base_cofins_st_product: Number(),
      value_cofins_st_product: Number(),
      valueUnityBusinessProduct: Number(),
      valueUnityTaxProduct: Number(),

      cfop_id: Number(),
      cst_ipi: Number(),
      csosn_id: Number(),
      sale_id: Number(),
      cst_pis_id: Number(),
      cst_cofins_id: Number(),
      csosnProduct: String(),
      sourceMerchandise: String(),
    },
    formofpaymentsOfSales: {
      code: String(),
      formOfPaymentObject: Object(),
      condition: String(),
      value_form_payment: null,
    },
    billstoReceive: Array(),

  }),
  computed: {
    ...mapGetters([
      "auth/getUser",
    ]),
  },
  methods: {
   async mounterDateSales() {
      let date = new Date();
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    confirmConveterBudgetInSales(budgetItem) {
      this.dialog_confirm_budget = true
      this.budgetSelect = budgetItem
    },
    async converterBudgetInSales() {
      let payloadSalesOfBudget = {}
      const STATUS_BUDGET_TRANSFORM_IN_SALES = "Em andamento"
      try {
        if (this.budgetSelect.status == STATUS_BUDGET_TRANSFORM_IN_SALES) {
        this.loadingConfirmBudget = true
        payloadSalesOfBudget = await this.mapBudgetToSales(this.budgetSelect)
        payloadSalesOfBudget.itens_sales = await this.mapItensBudgetToItensSales(this.budgetSelect.budget_item)
        payloadSalesOfBudget.form_of_payments = await this.mapFormOfPaymentBudgetToFormOfPaymentSales(this.budgetSelect.budget_payment_method)
        payloadSalesOfBudget.billstoReceive = await this.mapBillsToPayBudgetToSales(payloadSalesOfBudget.form_of_payments,payloadSalesOfBudget);
        await this.calcTributeItemsSalesOfBudget(payloadSalesOfBudget)
        await this.calcTotalTributeFinishSaleOfBudget(payloadSalesOfBudget, payloadSalesOfBudget.itens_sales)
        let saleCreateCallBack =   await this.createSalesOfBudget(payloadSalesOfBudget)
        await this.emitterNFceOfBudget(payloadSalesOfBudget, saleCreateCallBack)
        this.loadingConfirmBudget = false
        this.dialog_confirm_budget = false
        
      } else {
        this.loadingConfirmBudget = false
        alert("Não e possivel transformar orçamento em vendas - status invalido")
      }
        
      } catch (error) {
        this.loadingConfirmBudget = false
        this.loadingConfirmBudget = false       
      }
     
      this.loadingConfirmBudget = false
      this.component.GetAllBudget();
    },
    async createSalesOfBudget(salesOfBudget) {
      salesOfBudget.data_sale = await this.mounterDateSales()
      let saleCreateCallBack  = {}
      await this.$store
        .dispatch(TRANSFORM_BUDGET_IN_SALES, salesOfBudget).then((response) => {
          saleCreateCallBack = {...response.response.data}
        }).catch((error) => {
          console.log(error)
          alert("Não foi possivel salvar venda relacionado a orçamento")
        })
        return saleCreateCallBack

    },
    closePrintNFce(){
      this.dialog_print_nfce = false
    },
    async emitterNFceOfBudget(salesEmitterOfBudget, saleCreateCallBack) {
      salesEmitterOfBudget.data_sale = new Date()
      await this.$store
        .dispatch(ISSUE_OF_INVOICE_NFCE, salesEmitterOfBudget)
        .then((response) => {
          saleCreateCallBack.access_key = response.response.data.nfce.key;
          saleCreateCallBack.status = response.response.data.nfce.status;
          if (saleCreateCallBack.status === "Autorizada") {
            saleCreateCallBack.number_sale = response.response.data.nfce.numberNfe;
            saleCreateCallBack.authorization_protocol = response.response.data.nfce.numberProtocol;
            saleCreateCallBack.series = response.response.data.nfce.seriesDocumentSupervisior;
            this.nfcePrint = response.response.data.nfcepdf;
            this.dialog_print_nfce = true
            this.$store.dispatch(UPDATE_SALES, saleCreateCallBack);
            
          } else {
            saleCreateCallBack.number_sale = response.response.data.nfce.numberNfe;
            saleCreateCallBack.authorization_protocol = "000000000";
            saleCreateCallBack.series = response.response.data.nfce.seriesDocumentSupervisior;
            this.$store.dispatch(UPDATE_SALES, saleCreateCallBack);
          }
        }).catch((error) => {
          alert("Não foi possivel emitir NFce")
        })
    },

    async mapBudgetToSales(budget) {
      let user = { ...this["auth/getUser"].user };
      let payloadSalesOfBudgey = {}
      const TAX_OPERATION_SALES = 1
      await this.$store.dispatch(GET_SEQUENCE_NFCE, {
        enterprise_id: budget.enterprise.id,
        typeNoteModel: "NFC-e",
      }).then((response) => {
        let sequenceNFce = response.data[0];
        payloadSalesOfBudgey = { ...this.salesOfBudget }
        payloadSalesOfBudgey.data_sale = new Date() //this.mounterDateSales()
        payloadSalesOfBudgey.access_key = "0000000000"
        payloadSalesOfBudgey.enterprise = budget.enterprise
        payloadSalesOfBudgey.authorization_protocol = "0000000000"
        payloadSalesOfBudgey.number_sale = sequenceNFce.sequence.toString()
        payloadSalesOfBudgey.series = sequenceNFce.series
        payloadSalesOfBudgey.wasProcessed = false,
          payloadSalesOfBudgey.client = budget.client
        payloadSalesOfBudgey.value_total_products = budget.valor_products_total
        payloadSalesOfBudgey.value_total_note = budget.value_total_note
        payloadSalesOfBudgey.value_descount_total =  budget.value_descont_total
        payloadSalesOfBudgey.value_account_total = budget.value_account_total
        payloadSalesOfBudgey.value_icms_substituicao_total = 0
        payloadSalesOfBudgey.base_cal_icms_total = budget.value_icms_total
        payloadSalesOfBudgey.base_calc_susbt_total = 0
        payloadSalesOfBudgey.value_icms_free_total = 0
        payloadSalesOfBudgey.value_outher_tax_total = 0
        payloadSalesOfBudgey.value_icms_total = budget.value_icms_total
        payloadSalesOfBudgey.note = "Venda de mercadoria  referente orçamento " + budget.number_budget
        payloadSalesOfBudgey.value_ipi_total = 0
        payloadSalesOfBudgey.value_ipi_outher_total = 0
        payloadSalesOfBudgey.value_ipi_free_total = 0
        payloadSalesOfBudgey.base_calc_ipi_total = 0
        payloadSalesOfBudgey.value_base_pis_total = 0
        payloadSalesOfBudgey.value_base_pis_st_total = 0
        payloadSalesOfBudgey.value_other_pis_total = 0
        payloadSalesOfBudgey.value_pis_st_total = 0
        payloadSalesOfBudgey.value_pis_total = 0
        payloadSalesOfBudgey.value_base_cofins_st_total = 0
        payloadSalesOfBudgey.value_base_cofins_total = 0
        payloadSalesOfBudgey.value_other_cofins_total = 0
        payloadSalesOfBudgey.value_cofins_st_total = 0
        payloadSalesOfBudgey.value_cofins_total = 0
        payloadSalesOfBudgey.valueApproximateTax = 0
        payloadSalesOfBudgey.client_id = budget.client_id
        payloadSalesOfBudgey.client.physicalpeople  = budget.client.people.physicalpeople
        payloadSalesOfBudgey.client.legalpeople = budget.client.people.legalpeople
        payloadSalesOfBudgey.client.address = budget.client.people.address
        payloadSalesOfBudgey.client.contacts = budget.client.people.contact
        payloadSalesOfBudgey.client.type_people =budget.client.type_people
        payloadSalesOfBudgey.client.id = budget.client.id
        payloadSalesOfBudgey.client.people_id = budget.client.people_id
        payloadSalesOfBudgey.client.people = budget.client.people
        payloadSalesOfBudgey.client.client_limit_credit = budget.client.client_limit_credit
        payloadSalesOfBudgey.tax_operation_types_id = TAX_OPERATION_SALES
        payloadSalesOfBudgey.enterprise_id = budget.enterprise_id
        payloadSalesOfBudgey.account_plans_id = 1
        payloadSalesOfBudgey.pdv_id = ENV.getInstance().VUE_APP_PDV_COMPUTER_ID,
        payloadSalesOfBudgey.seller_id = budget.seller_id
        payloadSalesOfBudgey.seller = budget.seller
        payloadSalesOfBudgey.seller.physicalpeople = budget.seller.people.physicalpeople
        payloadSalesOfBudgey.note_models_id = sequenceNFce.id
        payloadSalesOfBudgey.user_id = user.id
        payloadSalesOfBudgey.budget_id = budget.id

      })
      return payloadSalesOfBudgey
    },

    async mapItensBudgetToItensSales(itensBudget) {
      let itensSalesOfBudget = []
      let codeItem = 0
      itensBudget.forEach(itemBudget => {
        let payloadItensSales = { ...this.itensSalesOfBudget }
        payloadItensSales.product = { ...itemBudget.products }
        payloadItensSales.description = itemBudget.description_item
        payloadItensSales.code_item = codeItem + 1
        payloadItensSales.amount = itemBudget.amount
        payloadItensSales.product_id = itemBudget.product_id
        payloadItensSales.value_sale = itemBudget.value_budget_product
        payloadItensSales.value_descont_product = itemBudget.discount_amount
        payloadItensSales.value_subtotal_product = itemBudget.value_subtotal_product
        payloadItensSales.value_base_icms_product = itemBudget.value_base_icms_product
        payloadItensSales.value_accounting_product = itemBudget.value_accounting_product
        payloadItensSales.aliquot_icms_product = itemBudget.products.aliquot.aliquot
        payloadItensSales.value_icms_product = itemBudget.value_icms_product
        payloadItensSales.value_free_icms_product = 0
        payloadItensSales.value_icms_outher_product = 0
        payloadItensSales.value_base_icms_st_product = 0
        payloadItensSales.value_icms_st_product = 0
        payloadItensSales.aliquot_ipi_product = 0
        payloadItensSales.value_base_ipi_product = 0
        payloadItensSales.value_ipi_product = 0
        payloadItensSales.value_ipi_free_product = 0
        payloadItensSales.value_ipi_outher_product = 0
        payloadItensSales.aliquota_pis = 0
        payloadItensSales.value_base_pis_product = 0
        payloadItensSales.value_pis_product = 0
        payloadItensSales.value_base_pis_st_product = 0
        payloadItensSales.value_other_pis_st_product = 0
        payloadItensSales.value_other_pis_product = 0
        payloadItensSales.value_free_pis_product = 0
        payloadItensSales.value_pis_st_product = 0
        payloadItensSales.aliquota_cofins = 0
        payloadItensSales.value_base_cofins_product = 0
        payloadItensSales.value_cofins_product = 0
        payloadItensSales.value_other_cofins_product = 0
        payloadItensSales.value_free_cofins_product = 0
        payloadItensSales.value_base_cofins_st_product = 0
        payloadItensSales.value_cofins_st_product = 0
        payloadItensSales.valueUnityBusinessProduct = 0
        payloadItensSales.valueUnityTaxProduct = 0
        payloadItensSales.cfop_id = itemBudget.products.cfop_id
        payloadItensSales.cst_id = itemBudget.products.cst_id
        payloadItensSales.cst_ipi = itemBudget.products.cst_ipi_id
        payloadItensSales.csosn_id = itemBudget.products.csosn_id
        payloadItensSales.cst_pis_id = itemBudget.products.cst_ipi_id
        payloadItensSales.cst_cofins_id = itemBudget.products.cst_cofins_id
        payloadItensSales.codeCstProdut = itemBudget.products.cst.code
        payloadItensSales.csosnProduct = itemBudget.csosn.code
        payloadItensSales.cfopProduct = parseInt(itemBudget.cfop.code)
        payloadItensSales.ncmProduct = itemBudget.products.ncm.code
        payloadItensSales.sourceMerchandise = itemBudget.products.source_product
        payloadItensSales.methodDeterminingBaseIcms =  itemBudget.products.calculation_base_mode
        payloadItensSales.codeSituationTaxableCofins = itemBudget.products.cst_confis.code
        payloadItensSales.codeSituationTaxablePis = itemBudget.products.cst_pis.code
        itensSalesOfBudget.push(payloadItensSales)

      });
      return itensSalesOfBudget

    },
    mapFormOfPaymentBudgetToFormOfPaymentSales(formOfPaymentsOfBudget) {
      let formOfPayments = []
      formOfPaymentsOfBudget.forEach(itemFormOfPayments => {
        let payloadFormOfPayment = { ...this.formofpaymentsOfSales }
        payloadFormOfPayment.formOfPaymentObject = itemFormOfPayments
        payloadFormOfPayment.code = itemFormOfPayments.formofpayment.code
        payloadFormOfPayment.form_of_payment_id = itemFormOfPayments.formofpayment.id
        payloadFormOfPayment.payment_term_id = itemFormOfPayments.payment_term_id
        payloadFormOfPayment.value_form_payment = itemFormOfPayments.value_form_payment
        formOfPayments.push(payloadFormOfPayment)
      });
      return formOfPayments
    },
    async calcTributeItemsSalesOfBudget(salesOfBudget) {
      salesOfBudget.itens_sales.forEach(itemSales => {
        this.$store.dispatch(GET_CALC_TRIBUTE, {
          product: itemSales,
          enterprise: salesOfBudget.enterprise,
        }).then((itensResultCalcIcms) => {
          itemSales = { ...itensResultCalcIcms }
        })
      })
    },



    async calcTotalTributeFinishSaleOfBudget(salesOfBudget, itensBudget) {
      let itens_venda = itensBudget;

      await this.calcIbptProductsItens(salesOfBudget, itens_venda);
      await this.calcIbptTotalSalesOfBudgetNfce(salesOfBudget, itens_venda)
      try {
        if (itens_venda.length !== 0) {
          salesOfBudget.value_total_products =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_subtotal_product"
            );
          salesOfBudget.value_total_note = this.calc_value_tribute_itens(
            itens_venda,
            "value_sale"
          );
          salesOfBudget.value_account_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_accounting_product"
            );
          salesOfBudget.value_descount_total =
            this.calc_value_tribute_itens(itens_venda, "value_descont_product");

          salesOfBudget.base_cal_icms_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_product"
            );
          salesOfBudget.value_icms_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_free_icms_product"
            );
          salesOfBudget.value_outher_tax_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_icms_outher_product"
            );
          salesOfBudget.base_calc_susbt_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_st_product"
            );
          salesOfBudget.value_icms_substituicao_total =
            this.calc_value_tribute_itens(itens_venda, "value_icms_st_product");
          salesOfBudget.value_ipi_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_ipi_product"
          );
          salesOfBudget.value_ipi_outher_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_outher_product"
            );
          salesOfBudget.value_ipi_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_free_product"
            );
          salesOfBudget.base_calc_ipi_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_ipi_product"
            );
          salesOfBudget.value_base_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_product"
            );
          salesOfBudget.value_base_pis_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_st_product"
            );
          salesOfBudget.value_base_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_st_product"
            );
          salesOfBudget.value_base_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_product"
            );
          salesOfBudget.value_other_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_pis_product"
            );
          salesOfBudget.value_pis_st_total =
            this.calc_value_tribute_itens(itens_venda, "value_pis_st_product");
          salesOfBudget.value_icms_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_icms_product"
          );
          salesOfBudget.value_pis_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_pis_product"
          );
          salesOfBudget.value_cofins_total =
            this.calc_value_tribute_itens(itens_venda, "value_cofins_product");

          salesOfBudget.value_other_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_cofins_product"
            );
          salesOfBudget.value_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_cofins_st_product"
            );
        }
      } catch (error) {
        alert(error);
      }
    },
    calc_value_tribute_itens(val, key) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let result = val.map((e) => parseFloat(e[key]));
      return result.reduce(reducer).toFixed(2);
    },


    async calcIbptTotalSalesOfBudgetNfce(payloadSalesInBudget, itensVenda) {
      let valueAproximateTributeTotal = 0;
      itensVenda.forEach((itemProduct) => {
        valueAproximateTributeTotal = parseFloat(valueAproximateTributeTotal) + parseFloat(itemProduct.valueOfApproximateTax)
      })
      payloadSalesInBudget.valueApproximateTax = valueAproximateTributeTotal;
    },

    async calcIbptProductsItens(salesOfBudget, itensVenda) {
      let valueAproximeTaxNational = 0;
      let valueAproximeTaxState = 0;
      let itensProductResultApiIbpt = {};
      let valueItemProductTaxAprox = 0;
      let itensSalesIbpt = this.mounterValueCalcIbpt(itensVenda);
      await this.$store
        .dispatch(GET_TAX_PRODUCT_IBPT, {
          cnpjEmitter: salesOfBudget.enterprise.cnpj,
          listProducts: itensSalesIbpt,
        })
        .then((data) => {
          itensProductResultApiIbpt = data.response.data.ibptTaxProducts;
          itensVenda.forEach((itemProduct) => {
            itensProductResultApiIbpt.forEach((itemResultApi) => {
              if (String(itemProduct.ncmProduct) === itemResultApi.code_tax) {
                valueAproximeTaxNational = itemProduct.value_sale * (itemResultApi.tax_national / 100);
                valueAproximeTaxState = itemProduct.value_sale * (itemResultApi.tax_state / 100);
                valueItemProductTaxAprox = parseFloat(valueAproximeTaxNational) + parseFloat(valueAproximeTaxState);
                itemProduct.valueOfApproximateTax = valueItemProductTaxAprox.toFixed(2)
              }
            });
          });

        })
        .catch((error) => {
          this.calcIbptProductsIfExceptionErrorApi(itensVenda);
        });
    },

    mounterValueCalcIbpt(itensVenda) {
      let listProductIbpt = [];
      let productIbpt = {};

      itensVenda.forEach((item) => {
        productIbpt = {
          code_tax: String(item.ncmProduct),
          uf_operation: "ES",
          extarif: 0,
          code_product: String(item.product_id),
          description_product: item.description,
          unit_product: item.product.unit.sigla,
          valor_product: item.value_sale,
          gtin: this.checkGetin(item.product.bar_code),
        };
        listProductIbpt.push(productIbpt);
      });
      return listProductIbpt;
    },
    checkGetin(eanProduct) {
      if (eanProduct === null || eanProduct === "") {
        return "SEM GTIN";
      }
      return eanProduct;
    },

    mapBillsToPayBudgetToSales(salesOfPayments, sales) {
      let billstoReceivesCalcule = []
      salesOfPayments.forEach(payment => {
       let calculeResult = this.calculeCashPaymentBudgetWhileSales(payment, sales)
       billstoReceivesCalcule = calculeResult.concat(billstoReceivesCalcule) 
      });
      return billstoReceivesCalcule
    },

    calculeCashPaymentBudgetWhileSales(payment, sales) {
      let BillsToReceive = [];
      const AVISTA = 0;
      let salesBillsToReceive = { ...sales };
      let paymentFormPayment = payment.formOfPaymentObject.payment_term.deadline;
      if (paymentFormPayment == AVISTA) {
        sales.paymentMethodIndicator = "0";
        BillsToReceive = this.mounterBillsToReceiveWithInCash(
          salesBillsToReceive.data_sale,
          salesBillsToReceive.number_sale,
          payment.value_form_payment,
          salesBillsToReceive.client_id,
          salesBillsToReceive.enterprise_id
        );
        return BillsToReceive;
      } else {
        let BillsToReceive = [];
        sales.paymentMethodIndicator = "1";
        BillsToReceive = this.mounterPaymentDeferredPayment(
          payment,
          salesBillsToReceive
        );
        return BillsToReceive;
      }
    },

    mounterBillsToReceiveWithInCash(
      issuanceDate,
      number_sale,
      value_total_note,
      client_id,
      enterprise_id
    ) {
      let billstoReceiveInCashArray = [];
      let auxdate = issuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let issuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];
      const STATUS_BAIXADO = "Baixado";
      const TITLE_TITULO = "DINHEIRO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_BAIXADO;
      billstoReceiveInCash.issuance_date = issuanceDateFormat;
      billstoReceiveInCash.issuance_due = issuanceDateFormat;
      billstoReceiveInCash.type_title = TITLE_TITULO;
      billstoReceiveInCash.number_title = `${number_sale}/${1}`;
      billstoReceiveInCash.value_title = value_total_note;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = 0;
      billstoReceiveInCash.title_balance = 0;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);

      billstoReceiveInCashArray.push(billstoReceiveInCash);
      return billstoReceiveInCashArray;
    },

    mounterPaymentDeferredPayment(payment, salesAttributes) {
      let deadline = payment.formOfPaymentObject.payment_term.deadline;
      let numberOfInstallmentsPaymentForm = payment.formOfPaymentObject.payment_term.amount;
      let valueTotalSales = payment.value_form_payment;
      let valuePaymentDebTotal = parseFloat(valueTotalSales).toFixed(2);
      let valueOfPortion = parseFloat(
        (valuePaymentDebTotal / numberOfInstallmentsPaymentForm).toFixed(2)
      );
      let calculeValueTotalPendingOfDivision =
        valueOfPortion * numberOfInstallmentsPaymentForm;

      let billstoReceiveArray = [];
      let portion = 1;
      let aux_deadline = deadline.split("/");
      let deadlineArray = aux_deadline.map((item) => parseInt(item));
      for (portion = 1; portion <= numberOfInstallmentsPaymentForm; portion++) {
        let billsToReceiveCalcule = this.mounterBillsToReceiveWithOnTerm(
          salesAttributes.data_sale,
          salesAttributes.number_sale,
          portion,
          deadlineArray[portion - 1],
          valueOfPortion,
          salesAttributes.enterprise_id,
          salesAttributes.client_id
        );
        billstoReceiveArray.push(billsToReceiveCalcule);
      }

      if (calculeValueTotalPendingOfDivision != valuePaymentDebTotal) {
        let remaining =
          valuePaymentDebTotal - calculeValueTotalPendingOfDivision;

        billstoReceiveArray = this.calculateDiferenceInValueBillstoReceive(
          billstoReceiveArray,
          remaining
        );
        return billstoReceiveArray;
      }

      return billstoReceiveArray;
    },
    mounterBillsToReceiveWithOnTerm(
      dateIssuanceDate,
      number_sale,
      portation,
      deadline = 0,
      valueOfPortion,
      enterprise_id,
      client_id
    ) {
      let auxdate = dateIssuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let dateIssuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];

      const STATUS_ABERTO = "ABERTO";
      const TYPE_TITLE = "RECEBIMENTO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_ABERTO;
      billstoReceiveInCash.issuance_date = dateIssuanceDateFormat;
      billstoReceiveInCash.issuance_due =
        this.generateOfMaturityBillsToReceivce(
          dateIssuanceDate,
          deadline,
          portation
        );
      billstoReceiveInCash.type_title = TYPE_TITLE;
      billstoReceiveInCash.number_title = `${number_sale}/${portation}`;
      billstoReceiveInCash.value_title = valueOfPortion;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = valueOfPortion;
      billstoReceiveInCash.title_balance = valueOfPortion;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);
      return billstoReceiveInCash;
    },

    calculateDiferenceInValueBillstoReceive(billstoReceiveArray, remaining) {
      for (let index = 0; index < billstoReceiveArray.length; index++) {
        let valueIndexLastInstallment = index + 1;
        if (valueIndexLastInstallment === billstoReceiveArray.length) {
          billstoReceiveArray[index].value_title =
            billstoReceiveArray[index].value_title + remaining;
          billstoReceiveArray[index].value_net =
            billstoReceiveArray[index].value_title;
          billstoReceiveArray[index].title_balance =
            billstoReceiveArray[index].value_title;
        }
      }
      return billstoReceiveArray;
    },

    generateOfMaturityBillsToReceivce(dateReceive, deadline) {
      let auxdate = dateReceive.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let stringFormatada =
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0] +
        "-" +
        arrDataExclusao[2];
      let day = 0;
      let month = 0;
      let dateNew = new Date(stringFormatada);
      dateNew.setDate(dateNew.getDate() + deadline);

      day = dateNew.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }

      month = dateNew.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }
      let final_date = `${dateNew.getFullYear()}-${month}-${day}`;

      return final_date;
    },


    calcIbptProductsIfExceptionErrorApi(itensVenda) {
      const nacionalTax = 7.85 / 100;
      const stateTax = 17 / 100;
      let valueTotalTaxAproxDefault = 0;
      let valueCalcNacional = 0;
      let valueStateTax = 0;
      let valueTaxProduct = 0;
      itensVenda.forEach((itemProduct) => {
        valueCalcNacional = itemProduct.value_sale * nacionalTax;
        valueStateTax = itemProduct.value_sale * stateTax;
        valueTaxProduct = parseFloat(valueCalcNacional) + parseFloat(valueStateTax)
        itemProduct.valueOfApproximateTax = valueTaxProduct.toFixed(2)
        valueTotalTaxAproxDefault = valueTotalTaxAproxDefault + valueCalcNacional + valueStateTax;
      });
      this.component.sale.valueApproximateTax = valueTotalTaxAproxDefault.toFixed(2);
    },
  },
 
};

</script>
