<template>
  <v-row>
    <v-dialog transition="scale-transition" persistent max-width="1100" v-model="component.paymentDialog">
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-icon left class="mt-2" @click="() => {
              component.setPaymentDialog();
            }
              ">mdi-close</v-icon>
          </v-row>
          <v-row class="ml-2 mb-2">
            <v-card-title> Finalização do Orçamento</v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="center" class="ml-14">
              <v-col cols="3" class="titleValue primary--text">Valor pago
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ totalPay }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="titleValue red--text">Valor restante
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ totalRemain }}</v-col>
                </v-row></v-col>
              <v-col cols="3" class="titleValue">
                Valor final
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ component.value_total_note }}</v-col>
                </v-row></v-col>
            </v-row>
          </v-card-text>
          <v-form ref="form" v-model="validRegister" class="mt-5">
            <v-row class="ml-2">
              <v-col cols="12" md="3" sm="3" xl="3" class="ml-2">
                <v-select label="Forma" :items="items_formPay" v-model="form_of_payment.form" return-object
                  item-text="description" :rules="rules" outlined></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="3" xl="3">
                <v-select label="Condição" :items="termsFilter"
                  v-model="form_of_payment.condition" return-object item-text="description" :rules="rules"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="2" xl="3">
                <v-text-field label="Valor" v-model="form_of_payment.value_form_payment" outlined :rules="rules"
                  prefix="R$"></v-text-field>
              </v-col>
              <v-col>
                <v-btn width="200" class="ml-4" color="success" :disabled="validAdd"
                  @click="AddFormOfPyment()">Registrar</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="11">
              <v-data-table hide-default-footer :headers="headers" :items="tableItems" no-data-text="Sem registros">
                <template v-slot:item.actions="{ item }">
                  <v-icon color="red darken-3" class="ml-2" @click="() => {
                    deleteItem(item);
                  }
                    ">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn block class="success" @click="component.submit()" :disabled="!validSubmit">
              Finalizar</v-btn></v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <ErrorDialog :component="this" v-if="errorDialog" />
  </v-row>
</template>

<script>
import { headers } from "./script";
import ErrorDialog from "./ErrorDialog/index.vue"

import { GET_ALL_FORM_OF_PAYMENT } from "../../../../store/actions/formofpayment";
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";


import { mapGetters } from "vuex";

export default {
  components: {
    ErrorDialog,
  },
  props: {
    component: Object,
  },
  data: () => ({
    items_formPay: Array(),
    items_payTerm: Array(),
    termsFilter: Array(),
    headers,
    form_of_payment: {
      form: Object(),
      condition: Object(),
			value_form_payment: null,
    },
    totalPay: Number(),
    totalRemain: Number(),

    validRegister: Boolean(),
    rules: [(v) => !!v || "Campo obrigatório!"],
    tableItems: Array(),
    validAdd: Boolean(true),
    validSubmit: Boolean(),
    errorDialog: Boolean(),
    formOfPaymentsToUpdate: Array(),
  }),
  methods: {
    async GetFormPay() {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, {
        page: 1,
        limit: 100,
      });
    },
    async GetPayTerms() {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, {
        page: 1,
        limit: 100,
      });
    },

    AddFormOfPyment() {
      let formAlreadyAdded = Boolean()
      for(let i=0; i<this.tableItems.length; i++) {
        if(this.tableItems[i].form_of_payment_id == this.form_of_payment.form.id) {
          formAlreadyAdded = true
        }
      }
      if(!formAlreadyAdded) {
        this.form_of_payment.type_of_operation = "CREATE"
        this.form_of_payment.id = null
        this.formOfPaymentsToUpdate.push(this.form_of_payment)
        this.tableItems.push(this.form_of_payment)
        this.totalPay = 0
        for(let i=0; i<this.tableItems.length; i++) {
          this.totalPay += parseFloat(this.tableItems[i].value_form_payment)
        }
        this.totalPay = parseFloat(this.totalPay.toFixed(2))
        this.totalRemain = this.component.value_total_note - this.totalPay
        this.totalRemain = parseFloat(this.totalRemain.toFixed(2))
      } else {
        this.setErrorMessageDialog()
      }

      this.form_of_payment = {
        form: Object(),
        condition: Object(),
			  value_form_payment: null,
      }
    },

    deleteItem(payment_form) {
      let items = this.tableItems
      const index = items.findIndex((item) => item.form_of_payment_id === payment_form.form_of_payment_id)

        let deletedItem = {...items[index]}
        deletedItem.type_of_operation = "DELETE"
        if(deletedItem.id == null) {
          
          const indexUpdate = this.formOfPaymentsToUpdate.findIndex((item) => item.form_of_payment_id === deletedItem.form_of_payment_id)
          this.formOfPaymentsToUpdate.splice(indexUpdate, 1)
        } else {
          this.formOfPaymentsToUpdate.push(deletedItem)
        }
        items.splice(index, 1)
        this.tableItems = items
        this.totalPay = 0
        for(let i=0; i<this.tableItems.length; i++) {
          this.totalPay += parseFloat(this.tableItems[i].value_form_payment)
        }
        this.totalPay = parseFloat(this.totalPay.toFixed(2))
        this.totalRemain = this.component.value_total_note - this.totalPay
        this.totalRemain = parseFloat(this.totalRemain.toFixed(2))
    },

    checkValueToAdd(value, form, condition) {
      if((parseFloat(value) > 0 && (this.totalRemain - parseFloat(value)) >= 0 ) && ((Object.keys(form).length > 0) && (Object.keys(condition).length > 0))) {
        this.validAdd = false
      } else {
        this.validAdd = true
      }
    },

    loadFormOfPymentToUpdate(formOfPayments) {
      this.tableItems = formOfPayments
      for(let i=0; i<this.tableItems.length; i++) {
        this.totalPay += parseFloat(this.tableItems[i].value_form_payment)
        this.tableItems[i].form = Object()
        this.tableItems[i].form.description = this.tableItems[i].formofpayment.description
        this.tableItems[i].form.id = this.tableItems[i].formofpayment.id
        this.tableItems[i].condition = Object()
        this.tableItems[i].condition.description = this.tableItems[i].payment_term.description
        this.tableItems[i].condition.id = this.tableItems[i].payment_term.id
        this.tableItems[i].type_of_operation = "UPDATE"
      }
      this.totalRemain = this.component.value_total_note - this.totalPay
      this.totalRemain = parseFloat(this.totalRemain.toFixed(2))
      if(this.totalRemain == 0) {
        this.validSubmit = true
      }
      if(this.totalRemain < 0) {
        for(let i=0; i<this.tableItems.length; i++) {
          this.tableItems[i].type_of_operation = "DELETE"
          this.formOfPaymentsToUpdate.push(this.tableItems[i])
        }
        this.tableItems = []
        this.totalPay = 0
        this.totalRemain = this.component.value_total_note - this.totalPay
        this.totalRemain = parseFloat(this.totalRemain.toFixed(2))
      }
    },

    setErrorMessageDialog() {
      this.errorDialog = !this.errorDialog
    }
  },

  watch: {
    getFormOfPayment: function (val) {
      this.items_formPay = Object.values(val.data);
    },

    getPaymentTerms: function (val) {
      this.items_payTerm = Object.values(val.data);
    },

    "form_of_payment.value_form_payment": function(value) {
      this.checkValueToAdd(value, this.form_of_payment.form, this.form_of_payment.condition)
      value = value.replace(/\D/g, '');
      if (value.length == 1) {
        value = value.padStart(3, '0');
      }
      value = value.slice(0, -2) + '.' + value.slice(-2);
      value = value.replace(/^0+(\d)/, '$1');
      this.form_of_payment.value_form_payment = value;
    },

    "form_of_payment.form": function (val) {
      this.termsFilter = []
      for(let i=0; i<this.items_payTerm.length; i++) {
        if(val.id == this.items_payTerm[i].form_of_payment_id) {
          this.termsFilter.push(this.items_payTerm[i])
        } 
      }
      if(this.termsFilter.length == 0) {
        this.termsFilter = this.items_payTerm
      }
      this.checkValueToAdd(this.form_of_payment.value_form_payment, val, this.form_of_payment.condition)

    },
    
    "form_of_payment.condition": function (val) {
      this.checkValueToAdd(this.form_of_payment.value_form_payment, this.form_of_payment.form, val)
    },
    
    tableItems: function (val) {
      this.component.formOfPayments = val
      for(let i=0; i<val.length; i++) {
        this.component.formOfPayments[i].form_of_payment_id = this.component.formOfPayments[i].form.id
        this.component.formOfPayments[i].form_of_payment_description = this.component.formOfPayments[i].form.description
        this.component.formOfPayments[i].payment_term_id = this.component.formOfPayments[i].condition.id
        this.component.formOfPayments[i].payment_term_description = this.component.formOfPayments[i].condition.description
        this.component.formOfPayments[i].value_form_payment = this.component.formOfPayments[i].value_form_payment
      }
    },

    totalRemain: function(val) {
      if(val == 0) {
        this.validSubmit = true
      } else {
        this.validSubmit = false
      }
    },

    formOfPaymentsToUpdate: function(val) {
      this.component.paymentFormsToUpdate = val
    }

    
  },

  computed: {
    ...mapGetters([
      "getFormOfPayment",
      "getPaymentTerms",
    ]),
  },
  async mounted() {
    await this.GetFormPay();
    await this.GetPayTerms();
    this.valueToPay = this.component.value_total_note
    this.totalRemain = this.component.value_total_note
    if(this.component.updateBudgetData.id) {
      this.loadFormOfPymentToUpdate(this.component.updateBudgetData.budget_payment_method);
    }
  },
};
</script>
<style scoped>
.valueFinal {
  font-weight: bold;
  font-size: 20px;
}

.titleValue {
  font-size: 15px;
}

.disable-events {
  pointer-events: none;
}
</style>


