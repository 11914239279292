<template>
    <div>
      <v-data-table
        :headers="headers"
        :items="results"
        hide-default-footer
        no-data-text="Nenhum produto adicionado"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-2"
            color="orange darken-3"
            v-bind="attrs"
            v-on="on"
            @click="
              () => {
                component.updateDescount(item);
              }
            "
          >
            mdi-calculator
          </v-icon>
        </template>
            <span>Desconto</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-2"
            color="yellow darken-4"
            v-bind="attrs"
            v-on="on"
            @click="
              () => {
                component.setItemToUpdate(item);
              }
            "
          >
            mdi-pencil
          </v-icon>
        </template>
            <span>Alterar quantidade</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-2"
            color="red darken-4r"
            v-bind="attrs"
            v-on="on"
            @click="
              () => {
                component.setItemToRemove(item)
              }
            "
          >
            mdi-delete
          </v-icon>
        </template>
            <span>Remover produto</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </template>
  
    <script>  
  import { headers } from "./script";
  import { mapGetters } from "vuex";
  
  export default {
    props: {
      component: Object(),
    },
  
    data: () => ({
      headers,
      results: Array(),
    }),
  
    methods: {

    },
    computed: {
      ...mapGetters([
      "getproductsTable"
      ]),
    },
    created() {
      this.results = this.getproductsTable
    },
    watch: {
      getproductsTable: function(products) {
        for(let i=0; i<products.length; i++) {
          products[i].product_budget_value = parseFloat(products[i].product_budget_value.toFixed(2))
          products[i].value_descount = parseFloat(products[i].value_descount.toFixed(2))
        }
        this.results = products
        this.component.productsList = products
      }
    },
  };
  </script>
  