var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"350","transition":"fab-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.component.dialog_cashier),callback:function ($$v) {_vm.$set(_vm.component, "dialog_cashier", $$v)},expression:"component.dialog_cashier"}},[_c('v-card',[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.words.title))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.words.desc))]),_c('v-divider'),_c('v-container',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Tipo operação de caixa","readonly":""},model:{value:(_vm.cashier_operation_types_description),callback:function ($$v) {_vm.cashier_operation_types_description=$$v},expression:"cashier_operation_types_description"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","tile":"","label":_vm.words.input_label,"placeholder":"0.00","prefix":"R$","rules":[
                () => !!_vm.openCashier.openning_value || 'Campo Obrigatório',
                () =>
                  (!!_vm.openCashier.openning_value &&
                    _vm.openCashier.openning_value > 0) ||
                  'Valor de abertura deve ser maior que 0',
              ]},model:{value:(_vm.openCashier.openning_value),callback:function ($$v) {_vm.$set(_vm.openCashier, "openning_value", $$v)},expression:"openCashier.openning_value"}})],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[(_vm.component.disabledCancelOpenCashier)?_c('v-btn',{staticClass:"error mr-1",on:{"click":function($event){_vm.suggest_print ? _vm.open_cashier(null) : _vm.cancel()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cancel")]),_vm._v(" "+_vm._s(_vm.words.cancel))],1):_vm._e(),_c('v-btn',{staticClass:"success",attrs:{"disabled":!_vm.valid},on:{"click":() =>
                  _vm.current_operation == 'CONFIRM'
                    ? _vm.suggestPrint()
                    : _vm.open_cashier(1)}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.words.confirm))],1)],1)],1),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.words.btn))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }