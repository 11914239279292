const GET_ALL_CONTIGENCY = "GET_ALL_CONTIGENCY"
const ACTIVE_CONTIGENCY = "ACTIVE_CONTIGENCY"
const CONTIGENCY_IS_ACTIVE = "CONTIGENCY_IS_ACTIVE"
const DESATIVE_CONTIGENCY = "DESATIVE_CONTIGENCY"
const GET_SEQUENCE_INITIAL_CONTIGENCY = "GET_SEQUENCE_INITIAL_CONTIGENCY"

module.exports = {
    GET_ALL_CONTIGENCY,
    ACTIVE_CONTIGENCY,
    CONTIGENCY_IS_ACTIVE,
    DESATIVE_CONTIGENCY,
    GET_SEQUENCE_INITIAL_CONTIGENCY
}