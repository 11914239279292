require('dotenv').config()

class ENV {
    /** @type {ENV} */
    _instance
    VUE_APP_PDV_COMPUTER_ID  = ''
    VUE_APP_PDV_ACCOUNT_PLAN= ''
    VUE_APP_URL_DEFAULT = ''
    VUE_APP_URL_FISCAL= ''
    VUE_APP_URL_GESTAO = ''
    VUE_APP_USER_API_FISCAL = ''
    VUE_APP_PASSWORD_API_FISCAL = ''

    

    constructor() {
        this.VUE_APP_PDV_COMPUTER_ID = process.env.VUE_APP_PDV_COMPUTER_ID,
        this.VUE_APP_PDV_ACCOUNT_PLAN = process.env.VUE_APP_PDV_ACCOUNT_PLAN,
        this.VUE_APP_URL_DEFAULT = process.env.VUE_APP_URL_DEFAULT,
        this.VUE_APP_URL_GESTAO = process.env.VUE_APP_URL_GESTAO,
        this.VUE_APP_URL_FISCAL = process.env.VUE_APP_URL_FISCAL,
        this.VUE_APP_USER_API_FISCAL = process.env.VUE_APP_USER_API_FISCAL,
        this.VUE_APP_PASSWORD_API_FISCAL = process.env.VUE_APP_PASSWORD_API_FISCAL
    }



    static getInstance() {
        let i = new ENV()
        i._instance = i

        return i
    }
}


module.exports = ENV