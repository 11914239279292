import axiosfiscal from "../apifiscal";
import axiosautomacao from "../apigestao"
const {
    GET_ALL_CONTIGENCY,
    ACTIVE_CONTIGENCY,
    CONTIGENCY_IS_ACTIVE,
    DESATIVE_CONTIGENCY,
    GET_SEQUENCE_INITIAL_CONTIGENCY
} = require('./actions/contigency')

const { SPLICE_ERRO } = require("./actions/alert_api");
const CONTIGENCY_iS_NOT_ACTIVE = 0 
const CONTIGENCY_ACTIVE = true 
const moduleContigency = {
    state: () => ({
        contigency: Object(),
        contigencyIsActive: Boolean(),
        contigencyDesative: Object(),
        sequenceInitContigency: Object()
    }),
    getters: {
        getContigency: (state) => state.contigency,
        getContigencyIsActive: (state) => state.contigencyIsActive,
        getSequenceInitContigency : (state)=> state.sequenceInitContigency,
        getSequenceDesativeContigency : (state)=> state.contigencyIsActive
    },

    mutations: {
        GET_ALL_CONTIGENCY: (state, payload) => {
            state.contigency = payload
        },
        GET_SEQUENCE_INITIAL_CONTIGENCY : (state, payload)=>{
          state.sequenceInitContigency = payload
        },
        ACTIVE_CONTIGENCY: (state, payload) => {
            state.contigency = payload
            state.contigencyIsActive = true
            localStorage.setItem("contigency_data", JSON.stringify(payload));
            localStorage.setItem("contigencyIsActive", true);
        },
        CONTIGENCY_IS_ACTIVE: (state, payload) => {
            if (payload.length  != CONTIGENCY_iS_NOT_ACTIVE ){
                state.contigencyIsActive = payload[0]
                localStorage.setItem("contigency_data", JSON.stringify(payload[0]));
                localStorage.setItem("contigencyIsActive", true);
            }
  
        },
        DESATIVE_CONTIGENCY: (state, payload) => {
            state.contigencyDesative = payload
            state.contigencyIsActive = false
            localStorage.removeItem("contigency_data");
            localStorage.removeItem("contigencyIsActive");
        }
    },
    actions: {
        GET_ALL_CONTIGENCY({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
            axiosfiscal.post("/nfceincontingency/getallcontigency", payload, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((response) => {
                commit(GET_ALL_CONTIGENCY, response.data.contigency);
            }).catch((error) => {
                commit(SPLICE_ERRO, {
                    alert: "Error em obter as contigencias",
                    type: "E",
                })
            })
        },
        ACTIVE_CONTIGENCY({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
            axiosfiscal.post("/nfceincontingency/activecontigency", payload, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((response) => {
                commit(ACTIVE_CONTIGENCY, payload);
            }).catch((error) => {
                commit(SPLICE_ERRO, {
                    alert: "Error ao ativar a contigência nfce",
                    type: "E",
                  })
            })
        },
        CONTIGENCY_IS_ACTIVE({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");

            let situationContigency  =  localStorage.getItem("contigencyIsActive");
            if (situationContigency != CONTIGENCY_ACTIVE){
                axiosfiscal.post("/nfceincontingency/contigencyisactive", payload, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then((response) => {
                    commit(CONTIGENCY_IS_ACTIVE, response.data.contigency);
                }).catch((error) => {
                    commit(SPLICE_ERRO, {
                        alert: "Error ao verificar se a contigência esta ativa",
                        type: "E",
                    })
                })
            }
        },
        DESATIVE_CONTIGENCY({ commit }, payload) {
            let token = localStorage.getItem("token_fiscal");
            axiosfiscal.post("/nfceincontingency/desativecontigency", payload, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then((response) => {
                commit(DESATIVE_CONTIGENCY, response.data.contigency);
            }).catch((error) => {
                commit(SPLICE_ERRO, {
                    alert: "Error em obter as contigencias",
                    type: "E",
                })
            })
        },
        GET_SEQUENCE_INITIAL_CONTIGENCY ({commit}, payload){
            let token = localStorage.getItem("token_gestao");
            return new Promise((resolve, reject) => {
                axiosautomacao.post("noteModel/getSequenceNfeCurrenteValue",payload, {
                    headers: {
                      'Authorization': 'Bearer ' + token,
            
                    }
                  }).then((response) => {
                    resolve({ response});
                    commit(GET_SEQUENCE_INITIAL_CONTIGENCY, response.data[0]);
                  })

            })
            

        } 
    }


}
export default moduleContigency;