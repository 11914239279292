<template>
  <v-row>
    <v-dialog transition="scale-transition" persistent max-width="1100" v-model="component.dialog_finish">
      <v-card class="mx-auto" outlined>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-icon left class="mt-2" @click="() => {
              close();
            }
              ">mdi-close</v-icon>
          </v-row>
          <v-row class="ml-2 mb-2">
            <v-card-title> Finalização de Venda</v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="center" class="ml-14">
              <v-col cols="3" class="titleValue primary--text">Valor pago
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_pay }}</v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="titleValue red--text">Valor restante
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_remain.toFixed(2) }}</v-col>
                </v-row></v-col>
              <v-col cols="3" class="titleValue">Valor Troco
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ total_change.toFixed(2) }}</v-col>
                </v-row></v-col>
              <v-col cols="3" class="titleValue">
                Valor final
                <v-row justify="center" class="valueFinal">
                  <v-col>R$ {{ component.total.toFixed(2) }}</v-col>
                </v-row></v-col>
            </v-row>
          </v-card-text>
          <v-form ref="form" v-model="validRegister" class="mt-5">
            <v-row class="ml-2">
              <v-col cols="12" md="3" sm="3" xl="3" class="ml-2">
                <v-select label="Forma" :items="items_formPay" v-model="form_of_payments.form" item-value="id"
                  item-text="description" :rules="rules" return-object outlined></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="3" xl="3">
                <v-select return-object label="Condição" :disabled="validCondition" :items="items_payTerm"
                  v-model="form_of_payments.condition" item-value="id" item-text="description" :rules="rules"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" md="2" sm="2" xl="3">
                <v-text-field label="Valor" :disabled="validValue" v-model="form_of_payments.value_form_payment" outlined :rules="rules"
                  prefix="R$"></v-text-field>
              </v-col>
              <v-col>
                <v-btn width="200" class="ml-4" color="success" :disabled="total_remain.toFixed(2) == 0"
                  @click="register()">Registrar</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="11">
              <!-- database -->
              <v-data-table hide-default-footer :headers="headers" :items="table" no-data-text="Sem registros">
                <template v-slot:item.actions="{ item }">
                  <v-icon color="red darken-3" class="ml-2" @click="() => {
                    deleteItem(item);
                  }
                    ">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-btn block :loading="isloading" class="success" @click="finish_sale()"
              :disabled="total_pay != component.total.toFixed(2) || isloading">
              Finalizar</v-btn></v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <PrintSaleDialog :component="this" v-if="dialogPermissionPrint" />
    <dialogExchangeCredit v-if="exchangeDialog" :component="this" />
    <loadSale v-if="dialog_load" :component="this" :status="status" :title="title" :activeTime="activeTime" :time="time" />
    <ModalError v-if="dialog_alert" :component="this" :title_error="titleError" :listErrors="listErrors" />
    <NsuConfig :component="this" />
    <PrintNfce v-if="dialog_nfce" :component="this" />
  </v-row>
</template>

<script>
import { headers } from "./script";
import { GET_ALL_FORM_OF_PAYMENT } from "../../../../store/actions/formofpayment";
import { GET_ALL_PAYMENT_TERM } from "../../../../store/actions/paymentterm";
import { GET_ALL_PRODUCT_BY_STATUS } from "../../../../store/actions/product";

import { mapGetters } from "vuex";
import loadSale from "../Load_Sale/";
import PrintSaleDialog from "../Comfirm_Print_Nfce/";
import dialogExchangeCredit from "../dialogExchangeCredit/";
import {
  ADD_SALES,
  CLEAN_CART,
  UPDATE_SALES,
  SALES_PROCESSING_MESSAGE,
  SEARCH_COUPON,
  DOWNLOAD_CREDIT,
} from "./../../../../store/actions/sales";
import { GET_TAX_PRODUCT_IBPT } from "../../../../store/actions/ibpt";
import {
  ISSUE_OF_INVOICE_NFCE,
  GET_SEQUENCE_NFCE,
} from "./../../../../store/actions/nfce";
import NsuConfig from "../Nsu_inf_card/index.vue";
import ModalError from "../Modal_Error/";
import PrintNfce from "../PrintNfce/index.vue";
import { ACTIVE_CONTIGENCY } from "../../../../store/actions/contigency";

const reducer = (accumulator, currentValue) => accumulator + currentValue;
export default {
  components: {
    loadSale,
    ModalError,
    NsuConfig,
    PrintNfce,
    dialogExchangeCredit,
    PrintSaleDialog 
  },
  props: {
    component: Object,
  },
  data: () => ({
    //Códigos de forma de pagamento
    CREDIT_CODE: "03",
    DEBIT_CODE: "04",
    CASH_CODE: "01",
    CREDIT_EXCHANGE: "05",
    activeTime:false,
    nsuConfigShow: Boolean(false),
    items_formPay: Array(),
    items_payTerm: Array(),
    validCondition: Boolean(),
    validValue: Boolean(),
    validRegister: Boolean(),
    headers,
    dialog_load: Boolean(),
    dialog_alert: Boolean(),
    dialog_nfce: Boolean(),
    titleError: String(),
    listErrors: Array(),
    nfcePrint: String(),
    title: String(),
    addSalesProcess: false,
    table: Array(),
    total_pay: Number(),
    total_remain: Number(),
    total_change: Number(),
    value_form_payment: Number(),
    new_pay: Object(),

    infCard: Object(),

    form_of_payments: {
      code: String(),
      form: String(),
      condition: String(),
      value_form_payment: null,
    },
    billstoReceive: Array(),
    isloading: false,
    statusNfce: ["Emitindo Nota SEFAZ", "Salvando Nota", "Imprimindo Nota"],
    rules: [(v) => !!v || "Campo obrigatório"],
    exchangeDialog: Boolean(),
    codeCupomSearch: String(),
    dialogPermissionPrint: false,
    saleSuccess: false,
    idCreditExchange: Number(),
    time: Number(),
    contador:Number(), 
  }),
  methods: {
    searchCupom() {
      const payload = {
        number_title:this.codeCupomSearch,
        enterprise_id:this.component.sale.enterprise_id,
        cliente_id: this.component.sale.client_id,
        type_title: 'CT',
      }
      this.$store.dispatch(SEARCH_COUPON,payload ).then((response) => {
        this.form_of_payments.value_form_payment = response.data.value_exchange
        this.idCreditExchange = response.data.billstopay_id
        this.exchangeDialog = false
      })
    },
    confirmInfCard() {
      const infCard = this.infCard;
      this.new_pay = { ...infCard };
      this.nsuConfigShow = !this.nsuConfigShow;
    },
    close() {
      if(this.dialogPermissionPrint) {
        this.component.dialogPermissionPrint = false
      }
      this.component.dialog_finish = !this.component.dialog_finish;
      this.table = [];
      this.total_remain = 0;
    },
    value_pay(items) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      let variable = items.map((e) => e.value_form_payment);
      this.total_pay = variable.reduce(reducer, 0).toFixed(2);


    },
    value_remain() {
      let total = this.component.total - this.total_pay;
      if (total < 0) {
        this.total_change = Math.abs(total);
        this.total_remain = 0;
      } else {
        this.total_remain = total;
        this.total_change = 0;
      }
    },
    register() {
      const DINHEIRO = "01"
      let validate = this.$refs.form.validate();
      if (validate) {
        this.new_pay = { ...this.form_of_payments };
        this.new_pay.value_form_payment = parseFloat(
          this.new_pay.value_form_payment
        );
        this.form_of_payments.form.description.replace(/\s/g, "").toLowerCase();
        if (this.new_pay.form.code != DINHEIRO) {
          if (this.new_pay.value_form_payment > this.total_remain.toFixed(2)) {
            alert("Valor registrado acima do valor restante");
          } else {
            this.table.push(this.new_pay);
            if (this.new_pay.form.code == "03" || this.new_pay.form.code == "04") {
              this.nsuConfigShow = !this.nsuConfigShow;
            }
          }
        } else if (this.new_pay.form.code == DINHEIRO) {
          this.table.push(this.new_pay);
        }
        this.new_pay = {};
      }
    },
    deleteItem(item) {
      this.table.splice(this.table.indexOf(item), 1);
    },
    calc_value_tribute_itens(val, key) {
      let result = val.map((e) => parseFloat(e[key]));
      return result.reduce(reducer).toFixed(2);
    },

    async calcIbptTotalNfce(itensVenda) {
      let valueAproximateTributeTotal = 0;
      itensVenda.forEach((itemProduct) => {
        valueAproximateTributeTotal = parseFloat(valueAproximateTributeTotal) + parseFloat(itemProduct.valueOfApproximateTax)
      })
      this.component.sale.valueApproximateTax = valueAproximateTributeTotal;
    },

    async calcIbptProductsItens(itensVenda) {
      let valueAproximeTaxNational = 0;
      let valueAproximeTaxState = 0;
      let itensProductResultApiIbpt = {};
      let valueItemProductTaxAprox = 0;
      let itensSalesIbpt = this.mounterValueCalcIbpt(itensVenda);
      await this.$store
        .dispatch(GET_TAX_PRODUCT_IBPT, {
          cnpjEmitter: this.getEnterprises.data[0].cnpj,
          listProducts: itensSalesIbpt,
        })
        .then((data) => {
          itensProductResultApiIbpt = data.response.data.ibptTaxProducts;
          itensVenda.forEach((itemProduct) => {
            itensProductResultApiIbpt.forEach((itemResultApi) => {
              if (String(itemProduct.ncmProduct) === itemResultApi.code_tax) {
                valueAproximeTaxNational = itemProduct.value_sale * (itemResultApi.tax_national / 100);
                valueAproximeTaxState = itemProduct.value_sale * (itemResultApi.tax_state / 100);
                valueItemProductTaxAprox = parseFloat(valueAproximeTaxNational) + parseFloat(valueAproximeTaxState);
                itemProduct.valueOfApproximateTax = valueItemProductTaxAprox.toFixed(2)
              }
            });
          });

        })
        .catch((error) => {
          this.calcIbptProductsIfExceptionErrorApi(itensVenda);
        });
    },
    calcIbptProductsIfExceptionErrorApi(itensVenda) {
      const nacionalTax = 7.85 / 100;
      const stateTax = 17 / 100;
      let valueTotalTaxAproxDefault = 0;
      let valueCalcNacional = 0;
      let valueStateTax = 0;
      let valueTaxProduct = 0;
      itensVenda.forEach((itemProduct) => {
        valueCalcNacional = itemProduct.value_sale * nacionalTax;
        valueStateTax = itemProduct.value_sale * stateTax;
        valueTaxProduct = parseFloat(valueCalcNacional) + parseFloat(valueStateTax)
        itemProduct.valueOfApproximateTax = valueTaxProduct.toFixed(2)
        valueTotalTaxAproxDefault = valueTotalTaxAproxDefault + valueCalcNacional + valueStateTax;
      });
      this.component.sale.valueApproximateTax = valueTotalTaxAproxDefault.toFixed(2);
    },

    mounterValueCalcIbpt(itensVenda) {
      let listProductIbpt = [];
      let productIbpt = {};

      itensVenda.forEach((item) => {
        productIbpt = {
          code_tax: String(item.ncmProduct),
          uf_operation: "ES",
          extarif: 0,
          code_product: String(item.product_id),
          description_product: item.description,
          unit_product: item.product.unit.sigla,
          valor_product: item.value_sale,
          gtin: this.checkGetin(item.product.bar_code),
        };
        listProductIbpt.push(productIbpt);
      });
      return listProductIbpt;
    },
    checkGetin(eanProduct) {
      if (eanProduct === null || eanProduct === "") {
        return "SEM GTIN";
      }
      return eanProduct;
    },

    async calc_tribute_finish_sale() {
      let itens_venda = this.getCart;
      await this.calcIbptProductsItens(itens_venda);
      await this.calcIbptTotalNfce(itens_venda)
      try {
        if (itens_venda.length !== 0) {
          this.component.sale.value_total_products =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_subtotal_product"
            );
          this.component.sale.value_total_note = this.calc_value_tribute_itens(
            itens_venda,
            "value_sale"
          );
          this.component.sale.value_account_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_accounting_product"
            );
          this.component.sale.value_descount_total =
            this.calc_value_tribute_itens(itens_venda, "value_descont_product");

          this.component.sale.base_cal_icms_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_product"
            );
          this.component.sale.value_icms_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_free_icms_product"
            );
          this.component.sale.value_outher_tax_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_icms_outher_product"
            );
          this.component.sale.base_calc_susbt_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_icms_st_product"
            );
          this.component.sale.value_icms_substituicao_total =
            this.calc_value_tribute_itens(itens_venda, "value_icms_st_product");
          this.component.sale.value_ipi_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_ipi_product"
          );
          this.component.sale.value_ipi_outher_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_outher_product"
            );
          this.component.sale.value_ipi_free_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_ipi_free_product"
            );
          this.component.sale.base_calc_ipi_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_ipi_product"
            );
          this.component.sale.value_base_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_product"
            );
          this.component.sale.value_base_pis_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_pis_st_product"
            );
          this.component.sale.value_base_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_st_product"
            );
          this.component.sale.value_base_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_base_cofins_product"
            );
          this.component.sale.value_other_pis_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_pis_product"
            );
          this.component.sale.value_pis_st_total =
            this.calc_value_tribute_itens(itens_venda, "value_pis_st_product");
          this.component.sale.value_icms_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_icms_product"
          );
          this.component.sale.value_pis_total = this.calc_value_tribute_itens(
            itens_venda,
            "value_pis_product"
          );
          this.component.sale.value_cofins_total =
            this.calc_value_tribute_itens(itens_venda, "value_cofins_product");

          this.component.sale.value_other_cofins_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_other_cofins_product"
            );
          this.component.sale.value_cofins_st_total =
            this.calc_value_tribute_itens(
              itens_venda,
              "value_cofins_st_product"
            );
        }
      } catch (error) {
        alert(error);
      }
    },
    async finish_sale() {
      this.isloading = true
      this.title = "Finalizando Venda";
      let user = { ...this["auth/getUser"].user };
      this.dialog_load = !this.dialog_load;
      await this.calc_tribute_finish_sale();
      let sale = { ...this.component.sale };
      sale.data_sale = new Date,
      sale.enterprise_id = this.getEnterprises.data[0].id;
      sale.user_id = user.id;
      this.title = "Processando venda";
      this.status = "Obtendo sequencia nfce";
      await this.$store
        .dispatch(GET_SEQUENCE_NFCE, {
          enterprise_id: sale.enterprise_id,
          typeNoteModel: "NFC-e",
        })
        .then((response) => {
          this.status = "Gravando dados da venda";
          let sequenceNFce = response.data[0];
          sale.number_sale = sequenceNFce.sequence;
          sale.series = sequenceNFce.series;
          sale.note_models_id =  sequenceNFce.id
        }).catch((error) => {
          this.status = "Erro ao obter o sequencial nfce";
          this.dialog_load = !this.dialog_load;
          this.titleError = error.code + " - " + "Error ao salvar  venda";

        }),
        (sale.form_of_payments = []);
      //formas de pagamento
      this.table.forEach((e) => {
        // finalForm = {};
        let dueDateBillstoReceive = this.calculeCashPayment(e, sale);
        this.billstoReceive.push(dueDateBillstoReceive);
        let form = Object();
        form.form_of_payment_id = e.form.id;
        form.code = e.form.code;
        form.payment_term_id = e.condition.id;
        form.value_form_payment = e.value_form_payment;
        sale.form_of_payments.push(form);
        form = {};
      });
      this.title = "Gravando Venda";
      this.status = "Gravando nota na retaguarda";
      sale.access_key = "0000000000";
      sale.authorization_protocol = "000000000";
      let itensCode = this.mounterCodeItem(this.getCart);
      sale.itens_sales = itensCode;
      sale.value_total_note = parseFloat(this.component.total).toFixed(2);
      sale.billstoReceive = { ...this.billstoReceive };
      let saleEmitterNFce = { ...sale };
      sale.data_sale = await this.mounterDateSales();
      let saleCreateCallBack = {};
      await this.$store
        .dispatch(ADD_SALES, sale)
        .then((data) => {
          saleCreateCallBack = { ...data.data };
          this.addSalesProcess = true
          this.isloading = false
        })
        .catch((error) => {
          this.addSalesProcess = false
          this.title = "Erro ao salvar vendas";
          this.status = "Verificar em Notas Emitidas";
          this.dialog_load = !this.dialog_load;
          this.component.dialog_finish = !this.component.dialog_finish;
          this.titleError = error.code + " - " + "Error ao salvar  venda";
        });
      if (this.addSalesProcess == true) {
        sale.id = saleCreateCallBack.id;
        this.title = "Emitindo NFce - ";
        this.status = "Processando nota na sefaz";
        this.initCont()
        this.activeTime = true 
        await this.$store
          .dispatch(ISSUE_OF_INVOICE_NFCE, saleEmitterNFce)
          .then((response) => {
            //this.title = "Emitindo Nfce";
            sale.access_key = response.response.data.nfce.key;
            sale.status = response.response.data.nfce.status;   
            if (sale.status === "Autorizada") {
              this.title = "NFCE processada";
              sale.number_sale = response.response.data.nfce.numberNfe;
              sale.authorization_protocol =
                response.response.data.nfce.numberProtocol;
              sale.series = response.response.data.nfce.seriesDocumentSupervisior;
              this.status = "Imprimindo Danfe";
              this.nfcePrint = response.response.data.nfcepdf;
             
              this.$store.dispatch(UPDATE_SALES, sale);
              this.saleSuccess = true

            }else if (sale.status === "Assinada"){
              this.title = "NFCE processada";
              sale.number_sale = response.response.data.nfce.numberNfe;
              sale.authorization_protocol ="000000000";
              sale.series = response.response.data.nfce.seriesDocumentSupervisior;
              this.status = "Imprimindo Danfe";
              this.nfcePrint = response.response.data.nfcepdf;
             
              this.$store.dispatch(UPDATE_SALES, sale);
              this.saleSuccess = true

            } else {
              this.title = "Erro  na Emissão NFCE";
              this.status = "Verificar em Notas Emitidas";
              sale.number_sale = response.response.data.nfce.numberNfe;
              sale.authorization_protocol = "000000000";
              sale.series = response.response.data.nfce.seriesDocumentSupervisior;
              this.$store.dispatch(UPDATE_SALES, sale);
              this.dialog_load = !this.dialog_load;
            this.component.dialog_finish = !this.component.dialog_finish;
            }
            // this.component.dialog_finish = !this.component.dialog_finish;
          }).catch((error) => {
            this.title = "Erro  na Emissão NFCE";
            this.status = "Verificar em Notas Emitidas";
            this.component.dialog_finish = !this.component.dialog_finish;
            this.$store.dispatch(CLEAN_CART);
            (this.table = []),
              this.$store.dispatch(GET_ALL_PRODUCT_BY_STATUS, {
                page: 1,
                limit: 10,
                paginete: true,
              });
            sale.form_of_payments = [];
            this.form_of_payments = {};
            sale = {};
            this.new_pay = {};
            this.dialog_load = !this.dialog_load;
            this.activeTime = false
            clearInterval(this.contador);
          })

        
        this.dialog_load = !this.dialog_load;
        
        if(this.saleSuccess) {
          this.checkDownloadExchange(sale)
          this.openPrintPermissonDialog()
        }
        this.$store.dispatch(CLEAN_CART);
        (this.table = []),
        this.$store.dispatch(GET_ALL_PRODUCT_BY_STATUS, {
          page: 1,
          limit: 10,
          paginete: true,
        });
        sale.form_of_payments = [];
        this.form_of_payments = {};
        sale = {};
        this.new_pay = {};
        this.activeTime = false
        clearInterval(this.contador);
      }

    },
    activeContingecySefaz(error, saleContigency,sale_id ){
      const CODE_ERROR_SEFAZ = 400
      const MESSAGE_ERROR_SEFAZ = "Erro ao enviar o xml assinado para sefaz"
      if (this.time > 30) {
        if(error.code === CODE_ERROR_SEFAZ){
          let contigencyObject = {
              status: "active",
              typeContigency:"OFF-LINE",
              date_and_hours_contigency: this.mounterDateAndHoursContigency(),
              justification_on_contigency: "Queda de comunicação com a sefaz",
              cnpjEmitter: this.getEnterprises.data[0].cnpj
              }
        }
        this.$store.dispatch(ACTIVE_CONTIGENCY, contigencyObject).then(()=>{
              this.emitterNfceProcessContigency(saleContigency, sale_id)
        })

      }
 

    },

    async emitterNfceProcessContigency(salesContigency, sale_id) {
    await this.$store.dispatch(GET_SEQUENCE_NFCE, {
          enterprise_id: salesContigency.enterprise_id,
          typeNoteModel: "NFC-e",
        })
        .then((response) => {
          this.status = "Processando NFce em contigencia";
          let sequenceNFce = response.data[0];
          salesContigency.number_sale = sequenceNFce.sequence;
          salesContigency.series = sequenceNFce.series;
        })
        await this.$store
          .dispatch(ISSUE_OF_INVOICE_NFCE, salesContigency)
          .then((response) => {
            this.title = "Emitindo Nfce - Em contigência";
            this.status = "Processando nota na sefaz";
            salesContigency.id = sale_id
            salesContigency.access_key = response.response.data.nfce.key;
            salesContigency.status = response.response.data.nfce.status;
            if (salesContigency.status === "Autorizada") {
              this.title = "NFCE processada";
              salesContigency.number_sale = response.response.data.nfce.numberNfe;
              salesContigency.authorization_protocol = response.response.data.nfce.numberProtocol;
              salesContigency.series = response.response.data.nfce.seriesDocumentSupervisior;
              this.status = "Imprimindo Danfe";
              this.nfcePrint = response.response.data.nfcepdf;
              this.$store.dispatch(UPDATE_SALES,  salesContigency);
              window.open(this.nfcePrint);
            } else {
              this.title = "Erro  na Emissão NFCE";
              this.status = "Verificar em Notas Emitidas";
              salesContigency.number_sale = response.response.data.nfce.numberNfe;
              salesContigency.authorization_protocol = "000000000";
              salesContigency.series = response.response.data.nfce.seriesDocumentSupervisior;
              this.$store.dispatch(UPDATE_SALES, salesContigency);
              this.dialog_load = !this.dialog_load;
            }
          })
    },



    printNfcePdf() {
      this.dialog_nfce = !this.dialog_nfce;
    },

    initCont() {
      this.time = 30
      this.contador = setInterval(() => {
        if (this.time > 0)
          this.time = this.time - 1;
        else if (this.time == 0) {
          clearInterval(this.contador);
        }
      }, 1000);
    },
    

    mounterDateAndHoursContigency(){
    let dateSale = new Date();
    let year = dateSale.getFullYear();
    let day = dateSale.getDate();
    let month = dateSale.getMonth() + 1;
    let hours = dateSale.getHours();
    let minutes = dateSale.getMinutes();
    let seconds = dateSale.getSeconds();
    if (day < 10) {
        day = `0${day}`;
    } else {
        day = day.toString();
    }
    if (month < 10) {
        month = `0${month}`;
    } else {
        month = month.toString();
    }
    if (hours < 10) {
        hours = `0${hours}`;
    } else {
        hours = hours.toString();
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    } else {
        minutes = minutes.toString();
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    } else {
        seconds = seconds.toString();
    }
    let dateTimeFormat = (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)
    return dateTimeFormat
  },

    checkDescontItensRecalculeValueSale(itens) {
      itens.forEach((itemProduct) => {
        let valueProductDescont = 0;
        if (itemProduct.value_descont_product != 0) {
          valueProductDescont =
            itemProduct.value_sale - itemProduct.value_descont_product;
          itemProduct.value_sale = valueProductDescont;
        }
      });
      return itens;
    },

    mounterCodeItem(itens) {
      let item = 0;
      itens.forEach(
        (product) => ((product.code_item = item), (item = item + 1))
      );
      item = 0;
      return itens;
    },
    mounterDateSales() {
      // Create a date object from a date string
      let date = new Date();

      // Get year, month, and day part from the date
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },

    calculeCashPayment(payment, sales) {
      let BillsToReceive = [];
      const AVISTA = 0;
      let salesBillsToReceive = { ...sales };
      let paymentFormPayment = payment.condition.deadline;
      if (paymentFormPayment == AVISTA) {
        sales.paymentMethodIndicator = "0";
        BillsToReceive = this.mounterBillsToReceiveWithInCash(
          salesBillsToReceive.data_sale,
          salesBillsToReceive.number_sale,
          payment.value_form_payment,
          salesBillsToReceive.client_id,
          salesBillsToReceive.enterprise_id
        );
        return BillsToReceive;
      } else {
        let BillsToReceive = [];
        sales.paymentMethodIndicator = "1";
        BillsToReceive = this.mounterPaymentDeferredPayment(
          payment,
          salesBillsToReceive
        );
        return BillsToReceive;
      }
    },

    mounterPaymentDeferredPayment(payment, salesAttributes) {
      let deadline = payment.condition.deadline;
      let numberOfInstallmentsPaymentForm = payment.condition.amount;
      let valueTotalSales = payment.value_form_payment;
      let valuePaymentDebTotal = parseFloat(valueTotalSales).toFixed(2);
      let valueOfPortion = parseFloat(
        (valuePaymentDebTotal / numberOfInstallmentsPaymentForm).toFixed(2)
      );
      let calculeValueTotalPendingOfDivision =
        valueOfPortion * numberOfInstallmentsPaymentForm;

      let billstoReceiveArray = [];
      let portion = 1;
      let aux_deadline = deadline.split("/");
      let deadlineArray = aux_deadline.map((item) => parseInt(item));
      for (portion = 1; portion <= numberOfInstallmentsPaymentForm; portion++) {
        let billsToReceiveCalcule = this.mounterBillsToReceiveWithOnTerm(
          salesAttributes.data_sale,
          salesAttributes.number_sale,
          portion,
          deadlineArray[portion - 1],
          valueOfPortion,
          salesAttributes.enterprise_id,
          salesAttributes.client_id
        );
        billstoReceiveArray.push(billsToReceiveCalcule);
      }

      if (calculeValueTotalPendingOfDivision != valuePaymentDebTotal) {
        let remaining =
          valuePaymentDebTotal - calculeValueTotalPendingOfDivision;

        billstoReceiveArray = this.calculateDiferenceInValueBillstoReceive(
          billstoReceiveArray,
          remaining
        );
        return billstoReceiveArray;
      }

      return billstoReceiveArray;
    },

    calculateDiferenceInValueBillstoReceive(billstoReceiveArray, remaining) {
      for (let index = 0; index < billstoReceiveArray.length; index++) {
        let valueIndexLastInstallment = index + 1;
        if (valueIndexLastInstallment === billstoReceiveArray.length) {
          billstoReceiveArray[index].value_title =
            billstoReceiveArray[index].value_title + remaining;
          billstoReceiveArray[index].value_net =
            billstoReceiveArray[index].value_title;
          billstoReceiveArray[index].title_balance =
            billstoReceiveArray[index].value_title;
        }
      }
      return billstoReceiveArray;
    },

    mounterBillsToReceiveWithOnTerm(
      dateIssuanceDate,
      number_sale,
      portation,
      deadline = 0,
      valueOfPortion,
      enterprise_id,
      client_id
    ) {
      let auxdate = dateIssuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let dateIssuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];

      const STATUS_ABERTO = "ABERTO";
      const TYPE_TITLE = "RECEBIMENTO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_ABERTO;
      billstoReceiveInCash.issuance_date = dateIssuanceDateFormat;
      billstoReceiveInCash.issuance_due =
        this.generateOfMaturityBillsToReceivce(
          dateIssuanceDate,
          deadline,
          portation
        );
      billstoReceiveInCash.type_title = TYPE_TITLE;
      billstoReceiveInCash.number_title = `${number_sale}/${portation}`;
      billstoReceiveInCash.value_title = valueOfPortion;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = valueOfPortion;
      billstoReceiveInCash.title_balance = valueOfPortion;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);
      return billstoReceiveInCash;
    },

    mounterBillsToReceiveWithInCash(
      issuanceDate,
      number_sale,
      value_total_note,
      client_id,
      enterprise_id
    ) {
      let billstoReceiveInCashArray = [];
      let auxdate = issuanceDate.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let issuanceDateFormat =
        arrDataExclusao[2] +
        "-" +
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0];
      const STATUS_BAIXADO = "BAIXADO";
      const TITLE_TITULO = "DINHEIRO";
      let billstoReceiveInCash = {};
      billstoReceiveInCash.status = STATUS_BAIXADO;
      billstoReceiveInCash.issuance_date = issuanceDateFormat;
      billstoReceiveInCash.issuance_due = issuanceDateFormat;
      billstoReceiveInCash.type_title = TITLE_TITULO;
      billstoReceiveInCash.number_title = `${number_sale}/${1}`;
      billstoReceiveInCash.value_title = value_total_note;
      billstoReceiveInCash.dejection = 0;
      billstoReceiveInCash.interest = 0;
      billstoReceiveInCash.value_net = 0;
      billstoReceiveInCash.title_balance = 0;
      billstoReceiveInCash.annotation = `Esse título e referente a venda ${number_sale}`;
      (billstoReceiveInCash.account_plans_id = 1),
        (billstoReceiveInCash.client_id = client_id),
        (billstoReceiveInCash.enterprise_id = enterprise_id),
        (billstoReceiveInCash.sale_id = null);

      billstoReceiveInCashArray.push(billstoReceiveInCash);
      return billstoReceiveInCashArray;
    },

    generateOfMaturityBillsToReceivce(dateReceive, deadline) {
      let auxdate = dateReceive.toLocaleDateString();
      let arrDataExclusao = auxdate.split("/");
      let stringFormatada =
        arrDataExclusao[1] +
        "-" +
        arrDataExclusao[0] +
        "-" +
        arrDataExclusao[2];
      let day = 0;
      let month = 0;
      let dateNew = new Date(stringFormatada);
      dateNew.setDate(dateNew.getDate() + deadline);

      day = dateNew.getDate();
      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }

      month = dateNew.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }
      let final_date = `${dateNew.getFullYear()}-${month}-${day}`;

      return final_date;
    },

    async GetFormPay() {
      await this.$store.dispatch(GET_ALL_FORM_OF_PAYMENT, {
        page: 1,
        limit: 100,
      });
    },
    async GetPayTerms() {
      await this.$store.dispatch(GET_ALL_PAYMENT_TERM, {
        page: 1,
        limit: 100,
      });
    },
    form_verify(val) {
      let string = val.toLowerCase();
      if (string === "DINHEIRO") {
        this.items_payTerm.forEach((e) => {
          let description = e.description.replace(/\s/g, "").toLowerCase();
          if (description === "AVISTA") {
            this.form_of_payments.condition = e;
            this.validCondition = true;
          }
        });
      } else {
        this.validCondition = false;
      }
    },
    setPaymentTerm(form_of_payment_id, paymentterm) {
      this.items_payTerm = [];
      paymentterm.map((element) => {
        if (element.form_of_payment_id === form_of_payment_id) {
          this.items_payTerm.push(element);
        }
      });
      paymentterm = [];
    },

    openPrintPermissonDialog() {
      this.dialogPermissionPrint = true
    },

    checkDownloadExchange(saleData) {
      for(let i=0;i<saleData.form_of_payments.length; i++) {
        if(saleData.form_of_payments[i].code == "05") {
          const today = new Date();
          const yyyy = today.getFullYear();
          const mm = String(today.getMonth() + 1).padStart(2, '0');
          const dd = String(today.getDate()).padStart(2, '0');

          const formattedDate = `${yyyy}-${mm}-${dd}`;
          const payload = {
            enterprise_id: this.component.sale.enterprise_id,
            billstopay_id: this.idCreditExchange,
            date_download_bills_to_pay: formattedDate
          }
          this.$store.dispatch(DOWNLOAD_CREDIT, payload)
        }
      }
    },
  },

  updated() {
    this.value_pay(this.table);
    this.value_remain();
  },
  watch: {
    items: function () {
      this.register(this.items);
    },
    getFormOfPayment: function (val) {
      this.items_formPay = Object.values(val.data);
    },
    getPaymentTerms: function (val) {
      this.items_payTerm = Object.values(val.data);
    },
    "form_of_payments.form": function (val) {
      this.setPaymentTerm(val.id, this.getPaymentTerms.data);
      this.form_verify(val.description);
      if(val.description == "CREDITO LOJA") {
        this.exchangeDialog = true
        this.validValue = true
        this.form_of_payments.condition = this.items_payTerm[0]
      } else {
        this.validValue = false
      }
    },

    "form_of_payments.value_form_payment": function(value) {
      value = value.replace(/\D/g, '');
      if (value.length == 1) {
        value = value.padStart(3, '0');
      }
      value = value.slice(0, -2) + '.' + value.slice(-2);
      value = value.replace(/^0+(\d)/, '$1');
      this.form_of_payments.value_form_payment = value;
        }
  },

  computed: {
    ...mapGetters([
      "getCart",
      "getClientSales",
      "getFormOfPayment",
      "getPaymentTerms",
      "getSequenceNfce",
      "getEnterprises",
      "getIsAuthFiscal",
      "auth/getUser",
    ]),
  },
  async mounted() {
    await this.GetFormPay();
    await this.GetPayTerms();
  },
};
</script>
<style scoped>
.valueFinal {
  font-weight: bold;
  font-size: 20px;
}

.titleValue {
  font-size: 15px;
}

.disable-events {
  pointer-events: none;
}
</style>


