<template>
  <div>
    <v-app-bar app color="success" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">
        <span v-if="!validContigency" class="font-weight-light">{{ title }}</span>
        <span v-else-if ="validContigency" class="font-weight-light">{{ titleContigency }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="logout" large>mdi-logout</v-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      width="300px"
      temporary
      app
      color="success"
      class="SideNavbar"
    >
      <NavAvatar />
      <NavList />
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavList from "./NavList/";
import NavAvatar from "./NavAvatar/";
import "./styles.css";
import { GET_ALL_ENTERPRISE } from "../../store/actions/enterprise";
import {CONTIGENCY_IS_ACTIVE} from "../../store/actions/contigency";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      title: String(),
      titleContigency: String (),
      validContigency: Boolean(),
      enterprise: Object() 
    };
  },
  components: {
    NavList,
    NavAvatar,
  },
  methods: {
    async GetEnterprise() {

      await this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: 1, limit: 10, paginate:true 
      });
    },
    async checkContingencyIsActive() {
      let payload = {
        status: "active",
        cnpj_emitter: this.enterprise.cnpj
      }
      await this.$store.dispatch(CONTIGENCY_IS_ACTIVE,payload)
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "Login" }));
    },
  },
  async created() {
    await this.GetEnterprise();
    this.checkContingencyIsActive()
    
  },
  computed: {
    ...mapGetters(["getEnterprises","getContigencyIsActive", "getSequenceDesativeContigency"]),
  },
  watch: {
    getEnterprises: function (val) {
      this.title = val.data[0].fantasyName;
      this.enterprise =  val.data[0]
      
    },
    getContigencyIsActive: function(val) {
       if (Boolean(val) == true){
        this.validContigency = Boolean(val)
        this.titleContigency =  this.title + " - Atenção funcionamento em contingência -  NFce"
       }
    },
    getSequenceDesativeContigency: function(val) {
       if (Boolean(val) == false){
        this.validContigency = Boolean(val)
        this.titleContigency =  this.title 
       }
    }
  },
};
</script>
