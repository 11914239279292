<template>
  <v-row>
    <v-card-title> Contingência </v-card-title>
    <v-spacer></v-spacer>
    <v-col cols="12" sm="3">
      <v-select class="mt-3" label="Empresa" outlined :items="component.enterprises"
        v-model="component.enterpriseSelect" item-value="id" item-text="fantasyName" dense flat
        height="10px"></v-select>
    </v-col>
    <v-btn class="mr-2 mt-6" color="success" :disabled="IsStatusContigency" @click="() => {
        activateDialogContigency();
      }
        ">Ativar</v-btn>
  </v-row>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    IsStatusContigency: Boolean()
  }),
  props: {
    component: Object,
  },
  methods: {
    activateDialogContigency() {
      this.component.dialogContigency = true
    },
  },
  mounted() {
    this.IsStatusContigency =  this.getContigencyIsActive
  },
  computed: {
    ...mapGetters([
      "getSequenceDesativeContigency",
      "getSequenceInitContigency",
      "getContigencyIsActive"
    ]),

  },
  watch: {
    getContigencyIsActive: function (val){
      this.IsStatusContigency  = val
    },
    getSequenceDesativeContigency: function (val) {
      this.IsStatusContigency = val 
    },
    getSequenceInitContigency: function (val) {
      this.IsStatusContigency = val 
    },

  }

};
</script>
