<template>
  <v-row justify="center">
    <v-dialog v-model="this.component.dialogContigency" max-width="800">
      <v-card>
        <v-card-title primary-title> Ativar Contingência </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="typeContigency" label="Tipo" disabled
                  :rules="[(v) => !!v || 'Campo obrigatório']" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select label="Empresa" :items="getEnterprises.data" item-value="id" return-object
                  item-text="fantasyName" v-model="enterpriseSelectActive" :rules="[(v) => !!v || 'Campo obrigatório']"
                  outlined></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="date_and_hours_contigency" label="Data e Hora da Ativação" type="datetime-local"
                  :rules="[(v) => !!v || 'Campo obrigatório']" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="contigency_initial_sequence" disabled label="Sequencia Inicial Ativação"
                  :rules="[(v) => !!v || 'Campo obrigatório']" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="8">

                <v-textarea v-model="justification_on_contigency" :rules="[(v) => !!v || 'Campo obrigatório', v => (v && v.length >= 15) || 'Justificativa tem ser acima 15 caracteres']"
                  label="Justificativa da entrada em contingência" required outlined></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" class="right">
                <v-btn color="success" class="mt-4" :disabled="!valid" block @click="activeContigency">
                  Salvar
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" class="right">
                <v-btn color="error" class="mt-4" block @click="closeDialog">
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>


import { mapGetters } from "vuex";
import { ACTIVE_CONTIGENCY } from "../../../store/actions/contigency";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import {GET_SEQUENCE_INITIAL_CONTIGENCY} from "../../../store/actions/contigency" 

export default {
  props: {
    component: Object,
  },
  data() {
    return {
      typeContigency: 'OFF-LINE',
      date_and_hours_contigency: Date(),
      justification_on_contigency: String(),
      enterpriseSelectActive: Object(),
      contigency_initial_sequence: String(),
      valid: Boolean(),
      enterprise: Object()
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset()
    },
    closeDialog() {
      this.component.dialogContigency = false
    },
    async GetAllEnterprise(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    async getNumberSequenceAtiveContingency() {
      let payload = {
        enterprise_id : this.getEnterprises.data[0].id,
        typeNoteModel: "NFC-e"
      }
      await this.$store.dispatch(GET_SEQUENCE_INITIAL_CONTIGENCY, payload).then((result)=> {
        let notemodelSequence = {...result.response.data[0]}
        this.contigency_initial_sequence =  notemodelSequence.sequence

      })
    },
    activeContigency() {
      let contigencyObject = {
        status: "active",
        typeContigency: this.typeContigency,
        date_and_hours_contigency: this.date_and_hours_contigency,
        justification_on_contigency: this.justification_on_contigency,
        contigency_initial_sequence: String(this.contigency_initial_sequence),
        cnpj_emitter: this.getEnterprises.data[0].cnpj
      }
      this.$store.dispatch(ACTIVE_CONTIGENCY, contigencyObject)
      this.$refs.form.reset()
      this.component.dialogContigency = false
    },

    mounterDateAndHoursContigency() {
      let dateSale = new Date();
      let year = dateSale.getFullYear();
      let day = dateSale.getDate();
      let month = dateSale.getMonth() + 1;
      let hours = dateSale.getHours();
      let minutes = dateSale.getMinutes();
      let seconds = dateSale.getSeconds();

      if (day < 10) {
        day = `0${day}`;
      } else {
        day = day.toString();
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month.toString();
      }


      if (hours < 10) {
        hours = `0${hours}`;
      } else {
        hours = hours.toString();
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      } else {
        minutes = minutes.toString();
      }


      if (seconds < 10) {
        seconds = `0${seconds}`;
      } else {
        seconds = seconds.toString();
      }


      let dateTimeFormat = (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds)



      return dateTimeFormat

    },


  },
  created() {
    this.GetAllEnterprise()
    this.date_and_hours_contigency = this.mounterDateAndHoursContigency()
    this.getNumberSequenceAtiveContingency()
  },


  watch: {
    getEnterprises: function (val) {
      this.enterprise = val.data[0]
      this.enterpriseSelectActive = val.data[0];
    }
  },

  computed: {
    ...mapGetters(["getEnterprises"]),
  },
}


</script>