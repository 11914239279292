<template>
  <Container>
    <v-card>
      <v-card-text>
        <CardTitle :component="this"/>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-text-field  label="Data Inicial" v-model="filterData.initialDate" outlined type="date">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field  label="Data Final" v-model="filterData.finalDate" outlined type="date">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select  label="Status da Nota" v-model="filterData.status" :items="items" outlined>
            </v-select>
          </v-col>
            <v-btn
              class="mr-2 mt-6"
              color="success"
              @click="filter = !filter"
              style="width: 60px;"
            >
              <v-icon dark>
                mdi-magnify
              </v-icon>
            </v-btn>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-text-field v-model="searchInput" label="Pesquisar" outlined append-icon="mdi-magnify" @keyup.enter="autocompleteNfce()">
        </v-text-field>
      </v-card-text>
      <CancelDialog :component="this"/>
      <PrintDialog :component="this"/>
      <Table :search="search" :component="this" :filter="filter" :filterData="filterData" />
    </v-card>
    <AlertApi />
    <InfoError/>
    <loadScreen/>
  </Container>
</template>

<script>
import Container from "../components/Notes/Container";
import InfoError from "../components/InfoError"
import CardTitle from "../components/Notes/CardTitile";
import Table from "../components/Notes/Table";
import CancelDialog from "../components/Notes/PermissionDialog/CancelNote"
import PrintDialog from "../components/Notes/PermissionDialog/PrintNote"
import AlertApi from "./../components/alert_api";
import loadScreen from "./../components/load"

export default {
  components: { Container, CardTitle, Table, CancelDialog, PrintDialog, AlertApi,loadScreen,InfoError },
  data: () => ({
    search: "",
    searchInput: "",
    cancelDialog: false,
    printDialog: false,
    items: ['Todos', 'Autorizada', 'Em Digitação','Assinada', 'Cancelada', 'Rejeitada', 'Denegada', 'Cancelada'],
    filter: Boolean(false),
    filterData: {
      initialDate: Date(),
      finalDate: Date(),
      status: 'Todos',
      enterpriseId: Number(),
    },
  
  }),

  methods: {
    loadFilterDate() {
      var currentDate = new Date();

      currentDate.setDate(1);
      var initialDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format

      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);

      var lastDate = currentDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format

      this.filterData.initialDate = initialDate
      this.filterData.finalDate = lastDate
    },
    autocompleteNfce() {
      this.search = this.searchInput
    }

  },
  created() {
    this.loadFilterDate()
  }
};
</script>

<style></style>
