<template>
    <v-row>
      <v-card-title> Orçamento </v-card-title>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="3">
        <v-select
          class="mt-3"
          label="Empresa"
          outlined
          :items="enterprises"
          v-model="component.filterData.enterpriseId"
          item-value="id"
          item-text="fantasyName"
          dense
          flat
          height="10px"
        ></v-select>
      </v-col>
      <v-btn
      class="mr-2 mt-6"
      color="success"
      @click="
        () => {
          component.setBudgetDialog();
        }
      "
      >Adicionar</v-btn
    >
    </v-row>
  </template>
  
  <script>
  import { GET_ALL_ENTERPRISE } from "./../../../store/actions/enterprise";
  import { mapGetters } from "vuex";
  export default {
    props: {
      component: Object,
    },
    data: () => ({
      enterprises: Array(),
    }),
    methods: {
      async GetAll(page = 1) {
        let value = {
          page: page,
          limit: 10,
          paginate: false,
        };
        await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      },
    },
    created() {
      this.GetAll();
    },
  
    computed: {
      ...mapGetters(["getEnterprises"]),
    },
  
    watch: {
      getEnterprises(val) {
        this.component.GetAllBudget()
        if(val.data){
          this.enterprises = Object.values(val.data);
          this.component.filterData.enterpriseId = this.enterprises[0].id;
        }else{
          this.enterprises = Object.values(val);
          this.component.filterData.enterpriseId = this.enterprises[0].id;
        }
      },
    },
  };
  </script>
  